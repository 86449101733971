import React from 'react'
import DashboardLayout from 'Components/DashboardLayout'
import useSingleRestaurantBookings from './helper'
import AdminDashboardCardShimmer from 'Utility/Shimmers/AdminDashboardCardShimmer'
import AdminDashBoardCards from 'Pages/Admin/MainDashbaord/Elements/AdminDashBoardCards'
import BookingTable from 'Utility/Tables/BookingTable'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import Dropdown from 'Components/Elements/Dropdown'
import { BOOKING_STATUSES } from 'Constants/Data/BookingStatus'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import Back from 'Components/Elements/Back'
import ConfirmBooking from 'Pages/Concierges/Bookings/ConfirmBooking'

const SingleRestaurantBookings = () => {
    const { bookingData, paginationData, loader, currentPage, setCurrentPage, activeTab, setActiveTab, bookingStats, status, setStatus,
        startDate, setStartDate, endDate, setEndDate, bookingDetail, setBookingDetail
    } = useSingleRestaurantBookings()
    return (
        <DashboardLayout active={'Restaurant'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                <Back
                    title={'Bookings'}
                />
                <h1 className='text-white text-2xl py-2'>{bookingStats?.name}</h1>
                <div className="!m-0">
                    <div className="border-b mt-2">
                        <div className="flex items-center gap-6">
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "AllBookings" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("AllBookings");
                                }}
                            >
                                <h2>Total Bookings</h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "UpcommingBookings" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("UpcommingBookings");
                                }}
                            >
                                <h2>
                                    Upcomming Bookings
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className='my-4'>
                        {loader ?
                            <AdminDashboardCardShimmer arrayLength={[0, 1]} showSvg={false} noMinHeight={true} /> :
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                <AdminDashBoardCards title={'Total Bookings'} bgColor={'bg-[#E0F5F0] cursor-pointer'} amount={bookingStats?.total_bookings} showCurrency={false} showSvg={false} noMinHeight={true} />
                                <AdminDashBoardCards title={'Our Commission'} bgColor={'bg-[#E0F5F0] cursor-pointer'} amount={bookingStats?.our_commission} showSvg={false} noMinHeight={true} />
                            </div>
                        }
                    </div>
                    <div className='flex items-center justify-end gap-2 mb-4'>
                        {activeTab == "AllBookings" &&
                            <div className='w-fit'>
                                <Dropdown
                                    title={''}
                                    placeholder={'Status'}
                                    name={'status'}
                                    onChange={(e) => { setStatus(e.target.value) }}
                                    value={status}
                                    inputClass={"min-w-[180px]"}
                                    option={[
                                        {
                                            label: `All Bookings`, value: ""
                                        },
                                        ...BOOKING_STATUSES?.map(itm => {
                                            return {
                                                value: itm?.value,
                                                label: itm?.label
                                            }
                                        })]}
                                />
                            </div>
                        }
                        <div className='w-fit'>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                maxDate={new Date()}
                                className={'!w-full'}
                            />
                        </div>
                    </div>
                    <div className="space-y-3">
                        <div className="relative sm:rounded-lg border border-lightGrey">
                            {loader
                                ? <BookingTableShimer />
                                : <>
                                    <BookingTable
                                        data={bookingData}
                                        setBookingForm={setBookingDetail}
                                    />
                                    {bookingData?.length > 0 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            pageSize={paginationData?.per_page}
                                            currentPageCount={bookingData?.length}
                                            totalCount={paginationData?.total_pages}
                                            totalDataCount={paginationData?.total}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </main>

            {bookingDetail?.number &&
                <ConfirmBooking
                    close={setBookingDetail}
                    data={bookingDetail}
                    loading={false}
                />
            }
        </DashboardLayout>
    )
}

export default SingleRestaurantBookings