import { getAdminAnnoncedReward, getAdminUpcommingReward } from 'Adapters/APIs/Admin/rewards'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const useAdminRewards = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const announced_rewards = state?.adminRewards?.announced_rewards
    const upcomming_rewards = state?.adminRewards?.upcomming_rewards

    // states
    const [type, setType] = useState('announced')
    const [loading, setLoading] = useState(true)
    const [monthValue, setMonthValue] = useState('')
    const [addEditReward, setAddEditReward] = useState(false)
    const [editData, setEditData] = useState()
    const [announceReward, setAnnounceReward] = useState()

    // rewards data
    const rewards = useMemo(() => {
        return type == "announced" ? announced_rewards : type == 'upcomming' ? upcomming_rewards : []
    }, [type, announced_rewards, upcomming_rewards])

    // handle redirection to announce project
    const handleRedirectionAnnounceProject = (itm) => {
        // Get the current month number (1-12)
        const currentMonthNumber = moment().month() + 1;
        let date = moment(`${itm?.year}-${itm?.month}`, 'YYYY-MM')
        // Get the start of the month
        const startOfMonth = date.startOf('month').format('DD MMM YYYY');
        if (currentMonthNumber < itm?.month) {
            toast.warn(`This reward is scheduled for release on ${startOfMonth}`)
        } else {
            setAnnounceReward(itm)
        }
    }

    // get announced rewards data
    const getAnnouncedRewards = () => {
        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }
        setLoading(true)
        const payload = {
            month: monthValue ? moment(monthValue).format('MM') : '',
            year: monthValue ? moment(monthValue).format('YYYY') : '',
            type: "announced"
        }
        dispatch(getAdminAnnoncedReward(access_token, payload, success, fail))
    }

    // get upcomming/unannounced rewards data
    const getUpcommingRewards = () => {
        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }
        setLoading(true)
        const payload = {
            month: monthValue ? moment(monthValue).format('MM') : '',
            year: monthValue ? moment(monthValue).format('YYYY') : '',
            type: "unannounced"
        }
        dispatch(getAdminUpcommingReward(access_token, payload, success, fail))
    }

    // get data for first time
    const getRewardDataForFirstTime = () => {
        setLoading(true)
        if (type == "announced") {
            getAnnouncedRewards()
        } else if (type == "upcomming") {
            getUpcommingRewards()
        }
    }

    useEffect(() => {
        getRewardDataForFirstTime()
    }, [type, monthValue])

    useEffect(() => {
        setMonthValue('')
    }, [type])

    return {
        navigate, type, setType, loading, rewards, monthValue, setMonthValue, addEditReward, setAddEditReward, editData, setEditData,
        getRewardDataForFirstTime, announceReward, setAnnounceReward, handleRedirectionAnnounceProject
    }

}

export default useAdminRewards