import Svgs from 'Assets/svgs'
import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useInvoiceReportHelper from './helper'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import Back from 'Components/Elements/Back'
import InvoiceReportTable from 'Utility/Tables/InvoiceReportTable'
import InvoiceReportFilter from 'Pages/Admin/Analytics/Elements/InvoiceReportFilter'
import Button from 'Components/Elements/Button'
import DownloadCSVPopup from 'Pages/Admin/Analytics/Elements/DownloadCSVPopup'
import ShareInvoicePopup from 'Pages/Admin/Analytics/Elements/ShareInvoicePopup'

const RestaurantInvoiceReport = () => {
    const {
        paginationData, currentPage, setCurrentPage, filterData, setFilterData, DownloadClick, loader, data, addFilter, setAddFilter, handleChangeFilter,
        onClearFilter, onApplyFilter, onDownloadClick, setOnDownloadClick, downloadFilter, setDownloadFilter, downloadLoading,
        share, setShare, shareEmail, shareLoading, onShareChangeHandler, errors, SendInvoiceByEmail
    } = useInvoiceReportHelper()
    return (
        <DashboardLayout active={'invoice-report'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                <div className='flex gap-2 justify-between items-center py-4 px-2'>
                    <Back
                        title={'Invoice Report'}
                    />
                    <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                </div>
                <div className="flex justify-end">
                    {loader
                        ? <div className='h-10 w-48 rounded-[10px] bg-gray-600 animate-pulse' />
                        : data?.length > 0 &&
                        <Button
                            className={'flex gap-2 items-center'}
                            onClick={() => setOnDownloadClick(true)}
                        >
                            <Svgs.DownloadIcon />
                            Download CSV
                        </Button>
                    }
                </div>
                <div className="py-3">
                    <div className="lg:col-span-8 space-y-5">
                        <div className="space-y-3">
                            <div className="relative sm:rounded-lg border border-lightGrey">
                                {loader
                                    ? <BookingTableShimer columns={7} />
                                    : <InvoiceReportTable data={data} onShareClick={setShare} />
                                }
                                {data?.length > 0
                                    && <Pagination
                                        currentPage={currentPage}
                                        pageSize={paginationData?.per_page}
                                        totalCount={paginationData?.total_pages}
                                        onPageChange={(page) => setCurrentPage(page)}
                                        totalDataCount={paginationData?.total}
                                        currentPageCount={data?.all_bookings?.length}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <InvoiceReportFilter
                open={addFilter}
                close={setAddFilter}
                onClearFilter={onClearFilter}
                onApplyFilter={onApplyFilter}
                filterData={filterData}
                setFilterData={setFilterData}
                handleChangeFilter={handleChangeFilter}
            />

            <DownloadCSVPopup
                open={onDownloadClick}
                close={setOnDownloadClick}
                downloadLoading={downloadLoading}
                onApplyFilter={DownloadClick}
                filterData={downloadFilter}
                setFilterData={setDownloadFilter}
            />

            {share &&
                <ShareInvoicePopup
                    open={share}
                    close={setShare}
                    downloadLoading={shareLoading}
                    onApplyFilter={SendInvoiceByEmail}
                    data={shareEmail}
                    onChange={onShareChangeHandler}
                    errors={errors}
                />
            }
        </DashboardLayout>
    )
}

export default RestaurantInvoiceReport