import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const InvoiceReportTable = ({ data, onShareClick }) => {
    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[600px]">
                    <thead class="text-sm text-white bg-primary border-b capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Date
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Invoice Number
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Total Amount
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Paid Amount
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Pending Amount
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Payment Status
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Invoice Type
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                View Invoice
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="bg-primary border-b border-lightGrey text-blueGrey">
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {moment(itm?.date).format('DD-MM-YYYY')}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.number}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.amount}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.paid_amount}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.grand_total}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                <span className={`${itm?.status?.split(' ')[0]?.toLowerCase()} px-2 py-1 w-fit rounded-lg capitalize text-sm font-normal flex justify-center items-center`}>
                                                    {itm?.status}
                                                </span>
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.type}
                                            </td>
                                            <td class="px-6 py-2 flex gap-2">
                                                <Button className={'!p-2 text-sm'} title={'View Invoice'}
                                                    onClick={() => window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/invoice/${itm?.number}`)}
                                                />
                                                <Button className={'!p-2 text-sm'} title={'Pay Now'}
                                                    onClick={() => window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pay-invoice/${itm?.number}`)}
                                                />
                                                <Button
                                                    onClick={() => onShareClick(itm?.id)}
                                                    className={'!p-1 !bg-transparent'}
                                                    text={<Svgs.ShareIcon />}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default InvoiceReportTable