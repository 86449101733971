import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import React from 'react'

const CategoryCard = ({ onEditClick, imageSrc, name }) => {
    return (
        <div className="relative bg-darkGrey rounded-lg md:rounded-[10px] min-h-20 flex flex-col items-center justify-between p-5 text-center">
            <div className="absolute right-2 top-2 cursor-pointer" onClick={onEditClick && onEditClick}>
                <Svgs.EditGoldIcon />
            </div>
            <Image
                src={imageSrc}
                className={'w-14 max-h-14 object-cover'}
                customLoaderHeight={" w-full h-full"}
            />
            <hr className="my-4 w-full" />
            <h2 className="text-blueGrey text-sm">{name}</h2>
        </div>
    )
}

export default CategoryCard
