import { BASE_URL, get_referral_analytics } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";

// get admin referral analytics data
export const getAdminReferralAnalytics = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_referral_analytics}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}