import { getAdminBookingAnalyticsReport } from "Adapters/APIs/Admin/analytics"
import { getDropdownBusinessAPI, getDropdownConciergesAPI, getDropdownRestaurantAPI } from "Adapters/APIs/Admin/dashboard"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

let initialFilterState = {
    dropdownValue: "",
    start_date: "",
    end_date: "",
    status: "",
    filter: "",
    date_filter_type: "date"
}
const useBookingAnalytics = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const dropdown_concierges = state?.adminDashboard?.dropdown_concierges
    const dropdown_restaurants = state?.adminDashboard?.dropdown_restaurants
    const dropdown_businesses = state?.adminDashboard?.dropdown_businesses

    const prevFilterDataRef = useRef(initialFilterState);

    // states
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [data, setData] = useState([])
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [activeTab, setActiveTab] = useState('')
    const [addFilter, setAddFilter] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterUpdate, setFilterUpdate] = useState(false)

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // on clear filter
    const onClearFilter = () => {
        setAddFilter(false)
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            setActiveTab('')
            getAdminBookingAnalytics(true)
            setFilterData(initialFilterState)
        }
    }

    // get booking analytics data
    const getAdminBookingAnalytics = (noFilter) => {
        let payload = {
            page: currentPage,
            per_page: 10
        }
        if (!noFilter) {
            let dropdownDataValue
            if (activeTab == 'concierges') {
                dropdownDataValue = {
                    concierges: filterData?.dropdownValue
                }
            } else if (activeTab == "restaurants") {
                dropdownDataValue = {
                    restaurant: filterData?.dropdownValue
                }
            } else {
                dropdownDataValue = {
                    business_id: filterData?.dropdownValue
                }
            }
            payload = {
                ...payload,
                ...dropdownDataValue,
                ...filterData
            }
            delete payload.dropdownValue
        }
        setLoader(true)
        const success = (res) => {
            setData(res)
            setPaginationData(res?.pagination_data?.meta)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminBookingAnalyticsReport(access_token, payload, success, fail))
    }

    useEffect(() => {
        getAdminBookingAnalytics()
    }, [currentPage])

    useEffect(() => {
        // get dropdown data for concierges and restaurants
        dispatch(getDropdownConciergesAPI(access_token))
        dispatch(getDropdownRestaurantAPI(access_token))
        dispatch(getDropdownBusinessAPI(access_token, { type: "non_paginated" }))
    }, [])

    useEffect(() => {
        if (activeTab == "concierges") {
            setDropdownOptions(dropdown_concierges)
        } else if (activeTab == "restaurants") {
            setDropdownOptions(dropdown_restaurants)
        } else {
            setDropdownOptions(dropdown_businesses)
        }
    }, [activeTab, dropdown_concierges, dropdown_restaurants, dropdown_businesses])

    useEffect(() => {
        const prevFilterData = prevFilterDataRef.current;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current state for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    return {
        navigate, activeTab, setActiveTab, dropdownOptions, paginationData, currentPage, setCurrentPage, filterData, setFilterData,
        getAdminBookingAnalytics, loader, data, addFilter, setAddFilter, initialFilterState, handleChangeFilter, filterUpdate,
        onClearFilter
    }
}

export default useBookingAnalytics