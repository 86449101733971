import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminWishlist from './helper'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import LoginInput from 'Components/Elements/LoginInput'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import RequestCard from 'Components/RequestCard'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import WishlistShimmer from 'Utility/Shimmers/WishlistShimmer'
import Cookies from 'js-cookie'
import UpdateWishlist from './UpdateWishlist'
import CreateWishList from 'Pages/Concierges/Wishlist/CreateWishList'

const AdminWishlist = () => {
    const { wishlist, navigate, searchText, setSearchText, paginationData, loader, secondLoader, currentPage, setCurrentPage, startDate,
        setStartDate, endDate, setEndDate, activeTab, setActiveTab, wishlistDetail, updateStaff, setUpdateStaff, createReq, setCreateReq,
        updateForm, setUpdateForm, getWishlistFirstPageData } = useAdminWishlist()
    return (
        <DashboardLayout active={'Wishlist'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <Back
                    title={'Wishlist'}
                />
                <div className="py-6">
                    <div className="border-b mt-2 mb-5">
                        <div className="flex items-center gap-6">
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "not converted" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("not converted");
                                }}
                            >
                                <h2>Pending</h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "converted" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("converted");
                                }}
                            >
                                <h2>
                                    Converted
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-8 space-y-5">
                        <div className="flex xs:flex-row flex-col w-full gap-3 xs:items-end lg:items-center justify-end">
                            <div className="flex">
                                <LoginInput
                                    title={""}
                                    placeholder={'Search...'}
                                    value={searchText}
                                    onChange={(e) => { setSearchText(e.target.value) }}
                                    required={false}
                                    inputPadding={"px-4 py-[9px]"}
                                    parentClass={"xs:w-fit w-full"}
                                />
                            </div>
                            <DateRangePicker
                                startDate={startDate}
                                className="!max-w-none"
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="space-y-3">
                            <div className="relative sm:rounded-lg">
                                {loader
                                    ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                        {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                            return <WishlistShimmer />
                                        })}
                                    </div>
                                    :
                                    wishlist?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={wishlist?.length ? wishlist?.length : 10} //This is important field to render the next data
                                            next={() => {
                                                setCurrentPage(currentPage + 1)
                                            }}
                                            hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                                            loader={secondLoader
                                                ? <div className="flex justify-center items-center my-4">
                                                    <PreloaderSm />
                                                </div> : ""
                                            }
                                        >
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                                {wishlist?.map(itm => {
                                                    return <RequestCard
                                                        onCardClick={() => {
                                                            // setWishlistDetail(itm)
                                                            navigate(`/admin/wishlist/${itm?.id}`)
                                                            Cookies.set("wishlist_type", activeTab)
                                                        }}
                                                        assignedUser={itm?.assigned_user ? `${itm?.assigned_user?.first_name} ${itm?.assigned_user?.last_name}` : ""}
                                                        addedBy={`${itm?.user?.first_name} ${itm?.user?.last_name}`}
                                                        address={itm?.city}
                                                        id={itm?.id}
                                                        number={itm?.number}
                                                        status={itm?.status}
                                                        title={itm?.name}
                                                        category={itm?.type}
                                                        date={itm?.created_at}
                                                        user={itm?.user}
                                                        adminWishlist={true}
                                                        onEditClick={() => {
                                                            setUpdateForm(itm)
                                                            setCreateReq(true)
                                                        }}
                                                        allowEdit={activeTab == 'not converted'}
                                                    />
                                                })}
                                            </div>
                                        </InfiniteScroll>
                                        : <NotFound />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {updateStaff &&
                    <UpdateWishlist
                        open={updateStaff}
                        close={setUpdateStaff}
                        wishlistDetail={wishlistDetail}
                        successCallBack={getWishlistFirstPageData}
                    />
                }

                {createReq &&
                    <CreateWishList
                        open={createReq}
                        close={setCreateReq}
                        onSuccess={getWishlistFirstPageData}
                        isUpdate={updateForm}
                        setIsUpdate={setUpdateForm}
                    />
                }
            </main>
        </DashboardLayout>
    )
}

export default AdminWishlist