import React from 'react'
import NotFound from 'Utility/NotFound'

const PendingRecievables = ({ data }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[800px]">
                    <thead className="text-sm text-white border-b border-table bg-primary capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Business Name
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total Bookings
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Recievable Amount
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Concierge Commission
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="text-blueGrey cursor-pointer text-blueGrey border-b border-table bg-primary">
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.name}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.transactions_count}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.transactions_sum_commission}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.transactions_sum_manager_earning}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default PendingRecievables