import React from 'react'
import DashboardLayout from 'Components/DashboardLayout'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import Back from 'Components/Elements/Back'
import useAdminPendingPayables from './helper'
import PendingPayablesTable from 'Utility/Tables/PendingPayablesTable'

const AdminPendingPayables = ({ fromOtherPage }) => {
    const { financeData, paginationData, loader, currentPage, setCurrentPage
    } = useAdminPendingPayables()
    return (
        <DashboardLayout active={'Dashboard'}>
            <main className={`${fromOtherPage ? "" : "p-4 lg:ml-72 md:ml-64 h-auto pt-20"} min-h-screen overflow-y-auto`}>
                {!fromOtherPage &&
                    <div className='flex gap-4 justify-between items-center'>
                        <Back
                            title={'Pending Payables'}
                        />
                    </div>
                }
                <div className="!m-0 flex flex-col">
                    <div className={`${fromOtherPage ? "" : "py-6"}`}>
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border border-table">
                                    {loader
                                        ? <BookingTableShimer columns={4} />
                                        :
                                        <PendingPayablesTable data={financeData} />
                                    }
                                    {financeData?.length > 0
                                        && <Pagination
                                            currentPage={currentPage}
                                            pageSize={paginationData?.per_page}
                                            totalCount={paginationData?.total_pages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            totalDataCount={paginationData?.total}
                                            currentPageCount={financeData?.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default AdminPendingPayables