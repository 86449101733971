import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminSingleRestaurant from './helper'
import Svgs from 'Assets/svgs'
import ToggleWithText from 'Components/Elements/ToggleWithText'
import Button from 'Components/Elements/Button'
import Slider from '../Elements/Slider'
import moment from 'moment'
import Image from 'Components/Elements/Image'
import LoginInput from 'Components/Elements/LoginInput'
import { currency } from 'Adapters/variables'
import SingleRestaurantRatingPupup from './SingleRestaurantRatingPupup'
import SingleAdminRestaurantDetailShimmer from 'Utility/Shimmers/SingleAdminRestaurantDetailShimmer'
import ConfirmationPopup from 'Components/ConfirmationPopup/ConfirmationPopup'
import Back from 'Components/Elements/Back'
import RestaurantBusinessHour from './SingleRestaurantRatingPupup/RestaurantBusinessHour'

const AdminSingleRestaurant = () => {
    const { restaurant, toogleStatus, changeRestaurantStatus, confirmToogle, setConfirmToogle, statusLoader,
        handleSubscriptionDataChange, restaurantSubscriptionData, updateRestaurantSubscriptionData, updateSubscriptionLoader,
        showRatingPopup, setShowRatingPopup, navigate, loader, errors, suspendToogle, setSuspendToogle, showBusinessHour, setShowBusinessHour
    } = useAdminSingleRestaurant()

    const { id, name, address, average_monthly_bookings, created_at, images, description, mobile_code, mobile_number, phone_code, phone_number,
        email, website_link, user, ratings, average_rating, total_rating, terms_and_conditions, instagram_link, contact_person, authorized_manager,
        is_suspended, business_hours
    } = restaurant

    const { admin_percentage, subscription_charges } = restaurantSubscriptionData

    return (
        <>
            <DashboardLayout active={'Restaurant'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    {loader ?
                        <SingleAdminRestaurantDetailShimmer />
                        :
                        <div className="pt-4 space-y-5">
                            <div className='flex justify-between items-center'>
                                <Back
                                    title={name}
                                />
                                <div>
                                    <ToggleWithText
                                        checked={toogleStatus == 1}
                                        value={toogleStatus}
                                        name="active"
                                        onChange={() => { setConfirmToogle(true) }}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center text-center justify-end gap-2">
                                <div className="flex items-center border text-center border-secondary rounded-xl">
                                    <div className={`cursor-pointer px-3 py-2 text-center ${is_suspended === 0 ? "font-semibold text-black bg-secondary rounded-xl" : "text-blueGrey"}`}
                                        onClick={() => { setSuspendToogle(true) }}
                                    >
                                        <h2>Active</h2>
                                    </div>
                                    <div className={`cursor-pointer px-3 py-2 text-center ${is_suspended === 1 ? "font-semibold text-black bg-secondary rounded-xl" : "text-blueGrey"}`}
                                        onClick={() => { setSuspendToogle(true) }}
                                    >
                                        <h2>
                                            Suspend
                                        </h2>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        customPadding={'!p-2'}
                                        title={'Edit Profile'}
                                        onClick={() => { navigate(`/admin/edit-restaurant/${id}`) }}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col space-y-2 justify-between'>
                                <div className="flex gap-2 items-center text-blueGrey">
                                    <span><Svgs.LocationIcon stroke={'var(--secondary-color)'} /></span>
                                    <span className="font-extralight">{address}</span>
                                </div>
                                <div className="flex gap-2 items-center text-blueGrey">
                                    <span><Svgs.ClockIcon stroke={'var(--secondary-color)'} width={14} height={18} /></span>
                                    <span className="font-extralight">Average Monthly Booking: {average_monthly_bookings}</span>
                                </div>
                                <div className="flex gap-2 items-center text-blueGrey">
                                    <span><Svgs.ClockIcon stroke={'var(--secondary-color)'} width={14} height={18} /></span>
                                    <span className="font-extralight">Joining Date: {moment(created_at).format('DD-MM-YYYY')}</span>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 justify-between items-center gap-4'>
                                <div className='flex flex-col space-y-2'>
                                    <h1 className='text-white'>Profile Images</h1>
                                    <Slider data={images} />
                                </div>
                                {/* <div className='flex flex-col space-y-2'>
                                    <h1 className='text-white'>Promotions</h1>
                                    <Slider data={restaurantPromotions} />
                                </div> */}
                            </div>
                            <div className='flex flex-wrap whitespace-nowrap justify-between gap-4 items-center'>
                                <div className='flex flex-1 gap-4 items-center'>
                                    <div className="bg-darkGrey rounded-full flex flex-col gap-3 items-center justify-between py-2 xs:px-[15px] px-2  cursor-pointer"
                                        onClick={() => { setShowRatingPopup('rating') }}>
                                        <div className="flex gap-2 items-center">
                                            <Svgs.StarIcon className={'size-4'} />
                                            <p className='text-white font-bold text-xs'>View Ratings</p>
                                        </div>
                                    </div>
                                    <div className="bg-darkGrey rounded-full flex flex-col gap-3 items-center justify-between py-2 xs:px-[15px] px-2 cursor-pointer"
                                        onClick={() => { navigate(`/admin/restaurant-feedback/${id}`) }}>
                                        <div className="flex gap-2 items-center">
                                            {average_rating > 0 && <Svgs.StarIcon />}
                                            <p className='text-white font-bold text-xs'>{average_rating} ({total_rating ? total_rating : 0} Reviews)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <Button
                                        onClick={() => {
                                            setShowBusinessHour(!showBusinessHour)
                                        }}
                                        className={'w-full sm:w-fit flex-end bg-transparent px-4 border-secondary border rounded-[10px] py-2 flex items-center gap-2'}
                                    >
                                        <Svgs.ColoredClockIcon stroke={'var(--secondary-color)'} />
                                        <span className="text-xs font-bold text-secondary">Business Hours</span>
                                    </Button>
                                    <Button
                                        customPadding={'rounded-[10px] px-4 py-2 gap-1.5'}
                                        onClick={() => { navigate(`/admin/restaurant-menu/${id}`) }}
                                        title={<>
                                            <Svgs.MenuIcon className={'size-5'} fill={'var(--primary-color)'} />
                                            <span className="text-xs font-bold text-black">Menu</span>
                                        </>}
                                    />
                                </div>
                            </div>
                            <div className='grid lg:grid-cols-4 xs:grid-cols-2 grid-cols-1 gap-4'>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5  px-2 bg-darkGrey'>
                                    <Svgs.PhoneIcon stroke={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm'>{`+${mobile_code} ${mobile_number}`}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5  px-2 bg-darkGrey'>
                                    <Svgs.PhoneIcon stroke={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm'>{`+${phone_code} ${phone_number}`}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5  px-2 bg-darkGrey'>
                                    <Svgs.MailIcon stroke={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm line-clamp-2'>{email}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5  px-2 bg-darkGrey'>
                                    <Svgs.WebGlobe fill={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm line-clamp-2'>{website_link ? website_link : "No URL"}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 px-2 bg-darkGrey'>
                                    <Svgs.InstagramLink fill={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm line-clamp-2'>{instagram_link ? instagram_link : "No Instagram URL"}</p>
                                </div>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                                <div className='flex flex-col space-y-2'>
                                    {contact_person &&
                                        <div className='flex flex-col space-y-2'>
                                            <h1 className='text-white'>Contact Person:</h1>
                                            <p className='text-blueGrey'>{contact_person ? contact_person : "-----"}</p>
                                        </div>
                                    }
                                    {authorized_manager &&
                                        <div className='flex flex-col space-y-2'>
                                            <h1 className='text-white'>Main point of contact:</h1>
                                            <p className='text-blueGrey'>{authorized_manager ? authorized_manager : "-----"}</p>
                                        </div>
                                    }
                                    {description &&
                                        <div className='flex flex-col space-y-2'>
                                            <h1 className='text-white'>Description:</h1>
                                            <p className='text-blueGrey'>{description ? description : "-----"}</p>
                                        </div>
                                    }
                                    {terms_and_conditions &&
                                        <div className='flex flex-col space-y-2'>
                                            <h1 className='text-white'>Terms and conditions:</h1>
                                            <p className='text-blueGrey'>{terms_and_conditions ? terms_and_conditions : "-----"}</p>
                                        </div>
                                    }
                                    <h1 className='text-white'>Agreement Signature</h1>
                                    <div className='max-w-[14rem] flex flex-col justify-center items-center'>
                                        <div className="flex items-center justify-center h-[7rem] w-[7rem] overflow-hidden">
                                            <Image src={user?.user_signature} className="w-full h-full" />
                                        </div>
                                        <div className='border-[1.5px] border-t-black w-[14rem]'></div>
                                    </div>
                                </div>
                                <div className={` bg-darkGrey rounded-lg flex flex-col space-y-2 items-start justify-between p-5`}>
                                    <div className='flex gap-2 items-center'>
                                        <Svgs.GroupBuilding />
                                        <h1 className='font-medium text-white text-[16px]'>{name}</h1>
                                    </div>
                                    <LoginInput
                                        title="Monthly Subscription"
                                        leftIcon={currency}
                                        parentClass={'w-full'}
                                        required={true}
                                        value={subscription_charges}
                                        name={"subscription_charges"}
                                        onChange={handleSubscriptionDataChange}
                                        placeholder={'Enter monthly subscription'}
                                        inputClass={'bg-lightGrey'}
                                        iconStyle={'border-blueGrey min-w-16'}
                                    />
                                    <LoginInput
                                        title="Commission Percentage"
                                        leftIcon={'%'}
                                        parentClass={'w-full'}
                                        value={admin_percentage}
                                        name={"admin_percentage"}
                                        onChange={handleSubscriptionDataChange}
                                        placeholder={'Enter commission percentage'}
                                        required={true}
                                        onlyNumericAllowed={true}
                                        error={errors?.admin_percentage}
                                        inputClass={'bg-lightGrey'}
                                        iconStyle={'border-blueGrey min-w-16'}
                                    />
                                    <div className='w-full'>
                                        <Button
                                            text={'Update'}
                                            className={"w-full mt-3"}
                                            customPadding={"px-5 py-2"}
                                            disabled={updateSubscriptionLoader}
                                            loading={updateSubscriptionLoader}
                                            onClick={updateRestaurantSubscriptionData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </main>
            </DashboardLayout>


            <ConfirmationPopup
                open={confirmToogle}
                close={setConfirmToogle}
                yesLoader={statusLoader}
                onYesClick={changeRestaurantStatus}
                status={toogleStatus}
            />

            <ConfirmationPopup
                open={suspendToogle}
                close={setSuspendToogle}
                yesLoader={statusLoader}
                onYesClick={() => { changeRestaurantStatus("suspend") }}
                title={`Are you sure you want to ${is_suspended === 1 ? "un-suspend" : "suspend"} this?`}
            />

            {(showRatingPopup == 'rating') &&
                <SingleRestaurantRatingPupup
                    open={showRatingPopup}
                    close={setShowRatingPopup}
                    ratingData={ratings}
                />
            }

            <RestaurantBusinessHour
                open={showBusinessHour}
                close={setShowBusinessHour}
                data={business_hours}
            />
        </>
    )
}

export default AdminSingleRestaurant