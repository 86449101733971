import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminSlotsManagementHelper from './helper'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import Svgs from 'Assets/svgs'
import SlotCard from './Elements/SlotCard'
import SlotCardShimmer from 'Utility/Shimmers/SlotCardShimmer'
import Button from 'Components/Elements/Button'
import UniversalSlotSettingPopup from './UniversalSlotSettingPopup'
import AdminCreateEditSlot from './CreateEditSlot'

const AdminSlotsManagement = () => {
    const { slotsData, loader, slotDetail, setSlotDetail, addEditSlot, setAddEditSlot, errors, universalSetting, adminSlotLoader,
        adminSlotSetting, setAdminSlotSetting, updateAdminSettingsData, handleChangeSlotSetting, getSlotsData
    } = useAdminSlotsManagementHelper()
    return (
        <DashboardLayout active={'slots-management'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className="flex items-center gap-3 justify-between">
                    <Back
                        title={'Slots Management'}
                    />
                    <div className="flex justify-end items-center my-2 gap-4">
                        <Button
                            text={'Add Slot'}
                            onClick={() => { setAddEditSlot(true) }}
                        />
                        <div className='cursor-pointer' onClick={() => { setAdminSlotSetting(true) }}>
                            <Svgs.SettingIconV2 />
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <div className="lg:col-span-8 space-y-5">
                        <div className="space-y-3">
                            <div className="relative sm:rounded-lg">
                                {loader
                                    ? <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8]?.map(itm => {
                                            return <SlotCardShimmer />
                                        })}
                                    </div>
                                    :
                                    slotsData?.length > 0 ?
                                        <div className={`grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-2 grid-cols-1 gap-2`}>
                                            {slotsData.map((itm, ind) => (
                                                <SlotCard
                                                    ind={ind}
                                                    name={itm?.name}
                                                    weekday_price={itm?.weekday_price}
                                                    weekend_price={itm?.weekend_price}
                                                    allowEdit
                                                    onEditClick={() => {
                                                        setAddEditSlot(true)
                                                        setSlotDetail(itm)
                                                    }}
                                                />
                                            ))}
                                        </div>
                                        : <NotFound />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {adminSlotSetting &&
                <UniversalSlotSettingPopup
                    open={adminSlotSetting}
                    close={setAdminSlotSetting}
                    universalSetting={universalSetting}
                    handleChangeSlotSetting={handleChangeSlotSetting}
                    errors={errors}
                    updateAdminSettingsData={updateAdminSettingsData}
                    loading={adminSlotLoader}
                />
            }

            {addEditSlot &&
                <AdminCreateEditSlot
                    open={addEditSlot}
                    close={setAddEditSlot}
                    successCallBack={getSlotsData}
                    editData={slotDetail}
                    setEditData={setSlotDetail}
                />
            }
        </DashboardLayout>
    )
}

export default AdminSlotsManagement