import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminReferralAnalytics from './helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import Dropdown from 'Components/Elements/Dropdown'
import ReferralCard from './Elements/ReferralCard'
import ReferralDetailPopup from './ReferralDetailPopup'
import AdminAmountCardShimmer from 'Utility/Shimmers/AdminAmountCardShimmer'
import AmountCard from '../Analytics/Elements/AmountCard'

const AdminReferralAnalytics = () => {
    const { data, paginationData, loader, secondLoader, currentPage, setCurrentPage, activeTab, setActiveTab, detail, setDetail, type,
        setType, filterTypes, completeData } = useAdminReferralAnalytics()
    return (
        <DashboardLayout active={'referral-analtics'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className="px-4 space-y-4">
                    <div className="flex justify-between items-center">
                        <Back title={'Referral Analtics'} />
                        <div>
                            <Dropdown
                                title={''}
                                placeholder={'Select Type'}
                                onChange={(e) => { setType(e.target.value) }}
                                value={type}
                                option={filterTypes}
                            />
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <div className="border-b mt-2 mb-5">
                        <div className="flex items-center gap-6">
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "restaurant" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("restaurant");
                                }}
                            >
                                <h2>
                                    Businesses Analytics
                                </h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "concierge" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("concierge");
                                }}
                            >
                                <h2>Concierges Analytics</h2>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-8 space-y-5">
                        <div className="pt-6">
                            {loader ?
                                <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
                                    {[0, 1, 2]?.map((itm) => {
                                        return (
                                            <AdminAmountCardShimmer />
                                        )
                                    })}
                                </div>
                                :
                                <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
                                    <AmountCard
                                        amount={activeTab == "concierge" ? completeData?.total_concierges : completeData?.total_restaurants}
                                        title={`${activeTab == "concierge" ? "Total Concierges" : "Total Businesses"}`}
                                    />
                                    <AmountCard
                                        amount={activeTab == "concierge" ? completeData?.concierges_with_referral : completeData?.restaurants_with_referral}
                                        title={`${activeTab == "concierge" ? "Concierge with referral" : "Business with referral"}`}
                                    />
                                    <AmountCard
                                        amount={activeTab == "concierge" ? completeData?.concierges_without_referral : completeData?.restaurants_without_referral}
                                        title={`${activeTab == "concierge" ? "Concierge without referral" : "Business without referral"}`}
                                    />
                                </div>
                            }
                        </div>
                        <div className="space-y-3">
                            <div className="relative sm:rounded-lg">
                                {loader
                                    ? <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                        {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                            return <div className='flex flex-col gap-4 h-[110px] animate-pulse bg-darkGrey sm:rounded-[20px] rounded-lg'>
                                            </div>
                                        })}
                                    </div>
                                    :
                                    data?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={data?.length ? data?.length : 10} //This is important field to render the next data
                                            next={() => {
                                                setCurrentPage(currentPage + 1)
                                            }}
                                            hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                                            loader={secondLoader
                                                ? <div className="flex justify-center items-center my-4">
                                                    <PreloaderSm />
                                                </div> : ""
                                            }
                                        >
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                                {data?.map(itm => {
                                                    return <ReferralCard
                                                        onCardClick={() => {
                                                            setDetail(itm)
                                                        }}
                                                        name={`${itm?.first_name ? itm?.first_name : ""} ${itm?.last_name ? itm?.last_name : ""}`}
                                                        imgSrc={itm?.profile_picture}
                                                        earning={itm?.total_earned}
                                                        email={itm?.email}
                                                        referralsCount={itm?.referrals?.length}
                                                    />
                                                })}
                                            </div>
                                        </InfiniteScroll>
                                        : <NotFound />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {detail?.user_id &&
                <ReferralDetailPopup
                    open={detail?.user_id}
                    close={setDetail}
                    data={detail}
                    filterTypes={filterTypes}
                />}
        </DashboardLayout>
    )
}

export default AdminReferralAnalytics