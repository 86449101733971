import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const ConciergeBookingAnalyticsTable = ({ data }) => {
    return (
        <>
            <div className="overflow-x-auto rounded-md">
                <table className="w-full text-sm text-left rtl:text-right min-w-[800px]">
                    <thead className="text-sm text-white bg-primary border-b border-table capitalize ">
                        <tr className='rounded-t'>
                            <th scope="col" className="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Created At
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Confirmed At
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Booking Date
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Business
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Team Member
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Total Bill
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Guest
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                My Earning
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Payment Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <tr className="text-blueGrey cursor-pointer text-blueGrey border-b border-table bg-primary">
                                        <td className="px-6 py-4">
                                            {itm?.number}
                                        </td>
                                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                            {itm?.created_at ? moment(itm?.created_at)?.format('DD-MM-YYYY') : "----"}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                            {itm?.confirmed_at ? moment(itm?.confirmed_at)?.format('DD-MM-YYYY') : "----"}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                            {itm?.date_time ? moment(itm?.date_time)?.format('DD-MM-YYYY') : "----"}
                                        </th>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {itm?.restaurant?.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {itm?.member ? itm?.member?.name : `${itm?.user?.first_name ? itm?.user?.first_name : ""} ${itm?.user?.last_name ? itm?.user?.last_name : ""}`}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`${itm?.status == 'Commission paid' ? 'billed' : itm?.status?.split(' ')[0]?.toLowerCase()} px-2 py-1 w-fit rounded-lg text-sm font-normal flex justify-center items-center`}>
                                                {itm?.status == 'Commission paid' ? 'Billed' : itm?.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {itm?.amount}
                                        </td>
                                        <td className="px-6 py-4">
                                            {itm?.total_pax}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {itm?.manager_earning}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {itm?.concierges_paid}
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default ConciergeBookingAnalyticsTable