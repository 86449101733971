import React from 'react'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer';
import DashboardLayout from 'Components/DashboardLayout';
import Pagination from 'Components/Pagination';
import useAdminTransactionPayments from './helper';
import AdminTransactionTable from 'Utility/Tables/AdminTransactionTable';
import Svgs from 'Assets/svgs';
import TransactionDetail from './TransactionDetail';
import AdminAmountCardShimmer from 'Utility/Shimmers/AdminAmountCardShimmer';
import AmountCard from '../Analytics/Elements/AmountCard';
import Filters from './Elements/Filters';
import Back from 'Components/Elements/Back';
import Button from 'Components/Elements/Button';
import CSVExportPopup from 'Pages/Concierges/BookingAnalytics/Elements/CSVExportPopup';
import Dropdown from 'Components/Elements/Dropdown';
import AdminPendingPayables from '../MainDashbaord/PendingPayables';
import AdminPendingRecievables from '../MainDashbaord/PendingRecievables';

const AdminTransactionPayments = () => {
    const { data, paginationData, loader, currentPage, setCurrentPage, activeTab, setActiveTab, dropdownOptions, selectedItmDetail,
        setSelectedItemDetail, getDataForFirstTime, transactionsData, addFilter, setAddFilter, filterData, setFilterData, onClearFilter,
        onApplyFilter, handleChangeFilter, downloadCSV, setDownloadCSV, downloadCSVData, setDownloadCSVData, CSVLoader, getCSVData,
        typeFiltered, setTypeFiltered
    } = useAdminTransactionPayments()

    return (
        <>
            <DashboardLayout active={'transaction'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className='flex gap-2 justify-between items-center pb-4 px-2'>
                        <Back
                            title={'Commissions & Payments'}
                        />
                        {typeFiltered == "total_payable" || typeFiltered == "total_recievables" ? "" :
                            <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                        }
                    </div>
                    <div className="!m-0">
                        <div className="border-b mt-2">
                            <div className="flex items-center gap-6">
                                <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "conciergesPayment" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                    onClick={() => {
                                        setActiveTab("conciergesPayment");
                                    }}
                                >
                                    <h2>Concierges Payments</h2>
                                </div>
                                <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "ourEarnings" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                    onClick={() => {
                                        setActiveTab("ourEarnings");
                                    }}
                                >
                                    <h2>
                                        Our Earnings
                                    </h2>
                                </div>
                            </div>
                        </div>
                        {typeFiltered == "total_payable" || typeFiltered == "total_recievables" ? "" :
                            <div className="pt-6">
                                {loader ?
                                    <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
                                        {[0, 1, 2]?.map((itm) => {
                                            return (
                                                <AdminAmountCardShimmer />
                                            )
                                        })}
                                    </div>
                                    :
                                    <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
                                        <AmountCard
                                            amount={transactionsData?.total_bill_value}
                                            title={'Total bill value'}
                                            showCurrency
                                        />
                                        <AmountCard
                                            amount={transactionsData?.total_commission}
                                            title={'Total platform commission'}
                                            showCurrency
                                        />
                                        <AmountCard
                                            amount={transactionsData?.total_manager_earning}
                                            title={'Total concierges commission'}
                                            showCurrency
                                        />
                                    </div>
                                }
                            </div>
                        }
                        <div className='flex gap-2 items-center justify-end py-6'>
                            {typeFiltered == "total_payable" || typeFiltered == "total_recievables" ? "" :
                                <div className='w-fit'>
                                    <Button
                                        onClick={() => {
                                            setDownloadCSV(!downloadCSV)
                                        }}
                                    >
                                        <div className='flex gap-2 items-center'>
                                            <Svgs.ExportIcon width={"20"} height={"20"} />
                                            Download CSV
                                        </div>
                                    </Button>
                                </div>
                            }
                            <div className='w-fit'>
                                <Dropdown
                                    name={"filter"}
                                    placeholder={'Select Filter'}
                                    onChange={(e) => {
                                        setTypeFiltered(e.target.value)
                                    }}
                                    value={typeFiltered}
                                    title={""}
                                    option={[
                                        {
                                            label: `Total Transferred`, value: ""
                                        },
                                        {
                                            label: `Total ${activeTab == "conciergesPayment" ? "Payable" : "Recievables"}`,
                                            value: `${activeTab == "conciergesPayment" ? "total_payable" : "total_recievables"}`
                                        },]}
                                />
                            </div>
                        </div>
                        <div className="py-6">
                            <div className="lg:col-span-8 space-y-5">
                                <div className="space-y-3">
                                    {typeFiltered == "total_payable" ?
                                        <AdminPendingPayables fromOtherPage /> :
                                        typeFiltered == "total_recievables" ?
                                            <AdminPendingRecievables fromOtherPage /> :
                                            <div className="relative sm:rounded-lg border border-lightGrey">
                                                {loader
                                                    ? <BookingTableShimer />
                                                    : <AdminTransactionTable
                                                        data={data}
                                                        onRowCick={(data) => { setSelectedItemDetail(data) }}
                                                        activeTab={activeTab}
                                                    />
                                                }
                                                {data?.length > 0
                                                    && <Pagination
                                                        currentPage={currentPage}
                                                        pageSize={paginationData?.per_page}
                                                        totalCount={paginationData?.total_pages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                        totalDataCount={paginationData?.total}
                                                        currentPageCount={data?.length}
                                                    />
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </DashboardLayout>

            <Filters
                open={addFilter}
                close={setAddFilter}
                onClearFilter={onClearFilter}
                onApplyFilter={onApplyFilter}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                filterData={filterData}
                setFilterData={setFilterData}
                dropdownOptions={dropdownOptions}
                handleChangeFilter={handleChangeFilter}
            />

            <TransactionDetail
                open={selectedItmDetail}
                close={setSelectedItemDetail}
                number={selectedItmDetail}
                activeTab={activeTab}
                successCallback={getDataForFirstTime}
            />

            <CSVExportPopup
                open={downloadCSV}
                close={setDownloadCSV}
                onSubmit={getCSVData}
                setData={setDownloadCSVData}
                data={downloadCSVData}
                loading={CSVLoader}
            />
        </>
    )
}

export default AdminTransactionPayments