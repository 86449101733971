import { add_business_category, BASE_URL, get_business_categories, update_business_category } from "Adapters/variables";
import axios from "axios";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertObjectToForm } from "Constants/Functions/convertDataToFormData";
import { toast } from "react-toastify";


// get business categories data
export const getBusinessCategoriesData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_business_categories}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.categories
            success && success(data)
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// add or update business category
export const updateOrAddBusinessCategory = (access_token, payload, success, fail) => async (dispatch) => {
    let endUrl = payload?.id ? update_business_category : add_business_category
    let editId = payload?.id ? payload?.id : ""
    let formData = convertObjectToForm(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${endUrl}${editId}`, formData, headers);
        if (response?.status == 201 || response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            toast.error(error?.response?.data?.message, { toastId: 'toast' })
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}