import { getAdminReferralAnalytics } from "Adapters/APIs/Admin/referralAnalytics";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

let filterTypes = [
    { label: "All", value: "" },
    { label: "Pending", value: "pending" },
    { label: "Accepted", value: "accepted" }
]

const useAdminReferralAnalytics = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [activeTab, setActiveTab] = useState('restaurant')
    const [type, setType] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [data, setData] = useState([])
    const [completeData, setCompleteData] = useState()
    const [detail, setDetail] = useState()

    // get admin referral analytics data
    const getAdminReferralAnalyticsData = () => {
        let payload = {
            page: currentPage,
            referral_type: activeTab,
            type: type
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        const success = (data) => {
            setCompleteData(data)
            let updatedData = []
            if (data?.referrals?.length == 0) {
                updatedData = []
            } else {
                if (data?.pagination_data?.meta?.current_page !== 1) {
                    updatedData = [...data || [], ...data?.referrals];
                } else {
                    updatedData = data?.referrals;
                }
            }
            setData(updatedData)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getAdminReferralAnalytics(access_token, payload, success, fail))
    }

    useEffect(() => {
        getAdminReferralAnalyticsData()
    }, [currentPage, activeTab, type])

    useEffect(() => {
        setLoader(true)
        setCurrentPage(1)
    }, [activeTab, type])

    return {
        data, paginationData, loader, secondLoader, currentPage, setCurrentPage, activeTab, setActiveTab, detail, setDetail, type,
        setType, filterTypes, completeData
    }
}

export default useAdminReferralAnalytics