import React from 'react'
import Svgs from 'Assets/svgs'
import moment from 'moment'

const AnnouncementCard = (props) => {
    const { key, title, description, date, onCardClick, isSuperAdmin, recipient, status } = props

    return (
        <div
            className={`${onCardClick && 'cursor-pointer'} flex flex-col sm:rounded-[20px] rounded-lg p-3 sm:p-5 gap-3 bg-darkGrey`}
            onClick={() => {
                onCardClick && onCardClick()
            }}
            key={key}
        >
            <div className={`flex flex-col gap-3`}>
                {isSuperAdmin &&
                    <div className={`flex justify-between items-center gap-1`}>
                        <p class="text-secondary border border-secondary font-semibold rounded-lg sm:text-lg px-2 py-1">{recipient}</p>
                        <span className={`${status === 1 ? "placed" : "pending"} rounded-lg sm:text-lg px-2 py-1`} >{status === 1 ? "Finished" : "Scheduled"}</span>
                    </div>
                }
                <div className={`flex flex-col gap-1`}>
                    <p class="text-white font-semibold sm:text-lg">{title}</p>
                    <span className={`text-blueGrey`} >{description}</span>
                </div>
                <hr className='border-lightGrey' />
                <div className={`flex gap-2 items-center`}>
                    <Svgs.CalendarV2 fill={'var(--secondary-color)'} />
                    <span className={`text-blueGrey text-sm`} >{moment(date).format('DD-MM-YYYY • hh:mm A')}</span>
                </div>
            </div>
        </div>
    )
}

export default AnnouncementCard