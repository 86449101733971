import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import Svgs from 'Assets/svgs'
import ToggleWithText from 'Components/Elements/ToggleWithText'
import Button from 'Components/Elements/Button'
import moment from 'moment'
import Image from 'Components/Elements/Image'
import LoginInput from 'Components/Elements/LoginInput'
import useAdminSingleConcierge from './helper'
import SingleAdminConciergeDetailShimmer from 'Utility/Shimmers/SingleAdminConciergeDetailShimmer'
import ConfirmationPopup from 'Components/ConfirmationPopup/ConfirmationPopup'
import Back from 'Components/Elements/Back'
import CheckBox from 'Components/Elements/Checkbox'

const AdminSingleConcierge = () => {
    const { concierge, navigate, loader, toogleStatus, changeConciergeStatus, confirmToogle, setConfirmToogle, statusLoader,
        commissionPercentage, setCommissionPercentage, updateConciergeCommissionData, updateCommissionLoader, errors, setErrors,
        handleChange, formData
    } = useAdminSingleConcierge()

    const { id, first_name, last_name, business_name, email, mobile_code, mobile_number, dob, created_at, profile_picture, payment_method,
        payment_details, allow_team
    } = concierge

    return (
        <>
            <DashboardLayout active={'Concierge'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    {loader ?
                        <SingleAdminConciergeDetailShimmer />
                        :
                        <div className="pt-4 space-y-5">
                            <div className='flex justify-between items-center'>
                                <div className='flex gap-2 items-center'>
                                    <Back />
                                    {profile_picture ?
                                        <div className="flex items-center justify-center h-[4rem] w-[4rem] overflow-hidden rounded-full bg-gray-200 ">
                                            <Image src={profile_picture} className="w-full h-full object-cover" />
                                        </div>
                                        :
                                        <Svgs.UserIcon2 width={'56'} height={'56'} />}

                                    <h2 className="font-semibold md:text-2xl sm:text-lg text-md text-white">{`${first_name ? first_name : ""} ${last_name ? last_name : ""}`}</h2>
                                </div>
                                <div className='flex gap-2'>
                                    <ToggleWithText
                                        checked={toogleStatus == 1}
                                        value={toogleStatus}
                                        name="active"
                                        onChange={() => { setConfirmToogle(true) }}
                                    />
                                    <div>
                                        <Button
                                            customPadding={'!p-2'}
                                            title={'Edit Profile'}
                                            onClick={() => { navigate(`/admin/edit-concierge/${id}`) }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className='grid sm:grid-cols-2 grid-cols-1 justify-between gap-2'>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Business Name</span>
                                    <span className="font-extralight text-blueGrey">{business_name}</span>
                                </div>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Email Address</span>
                                    <span className="font-extralight text-blueGrey">{email}</span>
                                </div>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Mobile Number</span>
                                    <span className="font-extralight text-blueGrey">{`+${mobile_code}-${mobile_number}`}</span>
                                </div>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Date of Birth</span>
                                    <span className="font-extralight text-blueGrey">{moment(dob).format("DD-MM-YYYY")}</span>
                                </div>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Joining Date</span>
                                    <span className="font-extralight text-blueGrey">{moment(created_at).format("DD-MM-YYYY")}</span>
                                </div>
                            </div>
                            <hr className='border-lightGrey' />
                            <div className='flex flex-col'>
                                <span className='text-white'>Payment Preference</span>
                                <span className="font-extralight text-blueGrey capitalize">{payment_method}</span>
                            </div>
                            {payment_details &&
                                <div className='grid sm:grid-cols-2 grid-cols-1 justify-between gap-2'>
                                    <div className='flex flex-col space-y-2'>
                                        <span className='text-white'>Bank Name</span>
                                        <span className="font-extralight text-blueGrey">{payment_details?.bank_name}</span>
                                    </div>
                                    <div className='flex flex-col space-y-2'>
                                        <span className='text-white'>Account Title</span>
                                        <span className="font-extralight text-blueGrey">{payment_details?.card_holder_name}</span>
                                    </div>
                                    <div className='flex flex-col space-y-2'>
                                        <span className='text-white'>Account IBAN</span>
                                        <span className="font-extralight text-blueGrey">{payment_details?.iban_number}</span>
                                    </div>
                                </div>}
                            <CheckBox
                                checked={formData?.allow_team}
                                name={'allow_team'}
                                onChange={handleChange}
                                label={'Allow Team Members'}
                            />
                            <p className='text-sm text-blueGrey'>Commission Structure</p>
                            <div className={`bg-darkGrey  rounded-lg lg:w-[50%] flex flex-col space-y-2 items-start justify-between p-5`}>
                                <h2 className='py-1 font-semibold text-white'>{business_name}</h2>
                                <LoginInput
                                    title="Commission Percentage"
                                    leftIcon={'%'}
                                    parentClass={'w-full'}
                                    value={commissionPercentage}
                                    name={"commissionPercentage"}
                                    onChange={(e) => {
                                        setCommissionPercentage(e.target.value)
                                        setErrors((prev) => ({
                                            ...prev,
                                            commissionPercentage: ''
                                        }))
                                    }}
                                    placeholder={'Enter commission percentage'}
                                    onlyNumericAllowed={true}
                                    error={errors?.commissionPercentage}
                                    inputClass={'bg-lightGrey'}
                                    iconStyle={'border-blueGrey min-w-16'}
                                />
                            </div>
                            <div className='w-full'>
                                <Button
                                    text={'Update'}
                                    className={"w-full mt-3"}
                                    customPadding={"px-5 py-2"}
                                    disabled={updateCommissionLoader}
                                    loading={updateCommissionLoader}
                                    onClick={updateConciergeCommissionData}
                                />
                            </div>
                        </div>
                    }
                </main>
            </DashboardLayout>

            <ConfirmationPopup
                open={confirmToogle}
                close={setConfirmToogle}
                yesLoader={statusLoader}
                onYesClick={changeConciergeStatus}
                status={toogleStatus}
            />
        </>
    )
}

export default AdminSingleConcierge