import Svgs from 'Assets/svgs';
import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Image from './Image';
import Popup from './Popup';
import mammoth from 'mammoth'

const ImageUploader = ({
    name,
    descText,
    title,
    hideImage,
    required,
    description,
    imgSize,
    accept,
    id,
    multiple,
    mb_file_size,
    onChange,
    value,
    imgWidth,
    imgHeight,
    error,
    customHeight,
    noUnderlineDesc,
    heading,
    errorValidation,
    errorText,
    hideEdit,
    hideRemove
}) => {
    const [image, setImage] = useState(undefined);
    const [fileType, setFileType] = useState(''); // State to track file type (pdf, image, etc.)
    const [showPopup, setShowPopup] = useState(false);
    const [content, setContent] = useState('');

    const inputRef = useRef(null);  // Ref for the input field

    // Effect to handle setting the image from URL
    useEffect(() => {
        if (value) {
            if (typeof value == 'string') {
                setImage(value);
                // Check if the URL ends with .pdf and set fileType accordingly
                if (value.endsWith('.pdf')) {
                    setFileType('pdf');
                } else if (typeof value == 'string' && (value.endsWith('.doc') || value.endsWith('.docx'))) {
                    if (value.endsWith('.doc')) {
                        setFileType('doc');
                    }
                    if (value.endsWith('.docx')) {
                        setFileType('docx');
                    }
                } else if (value.match(/\.(jpeg|jpg|gif|png)$/)) {
                    setFileType('image');
                }
            } else {
                let url = URL.createObjectURL(value);
                setImage(url);
            }
        }
    }, [value]);

    const handleLabelClick = (e) => {
        if (errorValidation) {
            e.preventDefault();
            toast.error(errorText ? errorText : "You can't add more images", { toastId: "errorValidationToast" });
        }
    };

    const handleDelete = () => {
        // Manually set the file input value to an empty string
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        setFileType('')
        setImage(undefined)
        // Call onChange with an empty value
        onChange && onChange({
            target: {
                name: name ? name : 'file',
                value: '',
                files: []
            }
        });
    };

    const handleViewClick = async () => {
        if (fileType === 'pdf') {
            window.open(image, '_blank');
        } else if (fileType === 'doc' || fileType === 'docx') {
            if (typeof value == 'string' && (value.endsWith('.doc') || value.endsWith('.docx'))) {
                window.open(image, '_blank');
            } else {
                const arrayBuffer = await value.arrayBuffer();
                const { value: htmlContent } = await mammoth.convertToHtml({ arrayBuffer });
                setContent(htmlContent);
                setShowPopup(true);
            }
        } else {
            setShowPopup(true);
        }
    };

    const handleImageChange = (e) => {
        if (errorValidation) {
            toast.error(errorText ? errorText : "You can't add more images", { toastId: "errorValidationToast" });
            return;
        }

        const file = e.target.files[0];
        if (!file) return;  // No file selected

        if (multiple) {
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i];
                let file_size_mb = file.size / 1000 / 1000;
                if (file_size_mb > mb_file_size) {
                    toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "sizeLimitToast" });
                    return;
                }
            }
        } else {
            let file_size_mb = file.size / 1000 / 1000;
            if (file_size_mb > mb_file_size) {
                toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "sizeLimitToast" });
                return;
            }
        }

        const fileExtension = file?.name?.split('.').pop();
        const validFileTypes = accept ? accept.split(',') : ['*'];

        if (!accept ||
            validFileTypes.includes(file.type) || // Check MIME type (like image/png)
            validFileTypes.includes(`.${fileExtension}`) || // Check extension (like .png)
            (accept ? accept : '*')
                ?.split(',')
                ?.map(format => {
                    if (format.startsWith('image/')) {
                        return format.replace('image/', '').trim();
                    } else if (format.startsWith('application/')) {
                        return format.replace('application/', '').trim();
                    }
                    return format.trim();
                })
                .includes(fileExtension)
        ) {
            // Update file type state based on the file extension
            setFileType(fileExtension);

            if (imgWidth && imgHeight) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const width = img.width;
                        const height = img.height;
                        if (width <= imgWidth && height <= imgHeight) {
                            onChange && onChange(e);
                            if (!multiple) {
                                let url = URL.createObjectURL(file);
                                setImage(url);
                            }
                        } else {
                            toast.info(`Reduce image resolution to ${imgSize} or lower`, { toastId: "resolutionToast" });
                        }
                    };
                };
                reader.readAsDataURL(file);
            } else {
                onChange && onChange(e);
                if (!multiple) {
                    let url = URL.createObjectURL(file);
                    setImage(url);
                }
            }

            inputRef.current.value = null; // Clear input value
        } else {
            toast.info(`Only ${accept ? accept : 'image/*'} formats are supported.`, { toastId: "toast" });
            return;
        }
    };

    return (
        <div className={`flex flex-col gap-2 flex-1 w-full`}>
            {heading && (
                <div className={`${heading === '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{heading}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            {!hideImage && image ? (
                <div
                    className={`cursor-pointer flex flex-1 gap-2 w-full items-center justify-end border-2 border-dashed border-secondary rounded-lg bg-darkGrey px-4 py-2`}
                >
                    <div className="flex items-center gap-2 line-clamp-1 text-blueGrey flex-1">
                        <div><Svgs.FileIcon /></div>
                        {heading ? heading : value?.name || image}
                    </div>
                    <div className='flex gap-4 items-center'>
                        {((fileType !== 'doc' && fileType !== 'docx' && !hideEdit) || hideEdit) &&
                            <div
                                className='cursor-pointer rounded-full'
                                onClick={handleViewClick} // Use the delete handler function
                            >
                                <Svgs.EyeHide fill={'var(--secondary-color)'} />
                            </div>
                        }
                        {!hideEdit ?
                            <div className='flex gap-4 items-center'>
                                <label
                                    htmlFor={id ? id : 'upload_img'}
                                    key={id}
                                    onClick={handleLabelClick}
                                >
                                    <div
                                        className='cursor-pointer rounded-full'
                                    >
                                        <Svgs.EditPencil />
                                    </div>
                                </label>
                                {!hideRemove &&
                                    <div
                                        className='cursor-pointer rounded-full'
                                        onClick={handleDelete} // Use the delete handler function
                                    >
                                        <Svgs.CrossIcon fill={'var(--secondary-color)'} />
                                    </div>
                                }
                            </div>
                            : ""
                        }
                    </div>
                </div>
            )
                : (
                    <label
                        htmlFor={id ? id : 'upload_img'}
                        className={`cursor-pointer flex flex-1 items-center ${!image ? "justify-center" : ""} ${!image ? customHeight ? customHeight : "h-[200px]" : ""} border-2 ${error ? "border-red-600" : "border-secondary"} border-dashed rounded-lg bg-darkGrey p-2`}
                        key={id}
                        onClick={handleLabelClick}
                    >
                        <div className="flex flex-1 flex-col items-center justify-center text-center space-y-2 py-5 cursor-pointer">
                            <Svgs.UploaderDarkGrey fill={"var(--secondary-color)"} />
                            <p className='font-medium text-xl text-white'>{title ? title : "Upload images"}</p>
                            <p className={`${descText} ${!noUnderlineDesc && 'underline'} text-secondary font-semibold normal-case px-2 text-lg`}>{description ? description : ""}</p>
                        </div>
                    </label>
                )}
            <input
                type="file"
                id={id ? id : 'upload_img'}
                className='hidden'
                name={name}
                accept={accept ? accept : 'image/*'}
                multiple={multiple}
                autoComplete="off"
                ref={inputRef}
                onChange={handleImageChange}
            />

            <Popup
                removeHeading
                open={showPopup}
                close={setShowPopup}
                customSizeStyle={'sm:w-[35vw] xl:w-[30vw]'}
            >
                <div className="flex flex-col gap-5 bg-white">
                    {content ?
                        <div style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: content }} />
                        :
                        <div className="flex h-60">
                            <Image src={image} showDummy={true} className='object-contain' />
                        </div>
                    }
                </div>
            </Popup>
        </div>
    );
};

ImageUploader.defaultProps = {
    onClick: () => { },
    multiple: false,
    title: ""
};

export default ImageUploader;
