import { getAdminReferralRewards } from "Adapters/APIs/Admin/payReferralReward";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

let timeOutId = undefined
const useAdminPayReferralRewardHelper = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [activeTab, setActiveTab] = useState('accepted')
    const [searchText, setSearchText] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [data, setData] = useState([])
    const [completeData, setCompleteData] = useState()
    const [detail, setDetail] = useState()

    // get admin referral reward data
    const getAdminReferralRewardData = () => {
        let payload = {
            page: currentPage,
            type: activeTab,
            search_text: searchText
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        const success = (data) => {
            setCompleteData(data)
            let updatedData = []
            if (data?.all_referral_transactions?.length == 0) {
                updatedData = []
            } else {
                if (data?.pagination_data?.meta?.current_page !== 1) {
                    updatedData = [...data || [], ...data?.all_referral_transactions];
                } else {
                    updatedData = data?.all_referral_transactions;
                }
            }
            setData(updatedData)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getAdminReferralRewards(access_token, payload, success, fail))
    }

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText 
            getAdminReferralRewardData()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getAdminReferralRewardData()
            }, 500);
        }
    }, [searchText, currentPage, activeTab])

    useEffect(() => {
        setLoader(true)
        setCurrentPage(1)
    }, [activeTab])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    return {
        data, paginationData, loader, secondLoader, currentPage, setCurrentPage, activeTab, setActiveTab, detail, setDetail, searchText,
        setSearchText, completeData
    }
}

export default useAdminPayReferralRewardHelper