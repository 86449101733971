import { BASE_URL, get_invoices_data, get_pending_payable_and_recieveable_data } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import axios from "axios";
import { toast } from "react-toastify";

// get admin finance data
export const getAdminFinanceInvoicesData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_invoices_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(error)
        return error;
    }
}

// get pending recievables and payables
export const getAdminPendingRecievablesPayablesData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_pending_payable_and_recieveable_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(error)
        return error;
    }
}