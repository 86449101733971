import { getAdminThreshold, updateAdminSettings, updateAdminThreshold } from "Adapters/APIs/Admin/settings";
import { getRestaurantBusiness } from "Adapters/APIs/Restaurant/Business";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useTopBar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { errors, setErrors, emailValidation, validateEmail } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const userData = getDataFromLocalStorage('user_data')
    const access_token = getDataFromLocalStorage('access_token')
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    // states
    const [showMenuPopup, setShowMenuPopup] = useState(false)
    const [adminSetting, setAdminSetting] = useState(false)
    const [adminThresholdData, setAdminThresholdData] = useState('')
    const [adminThresholdLoader, setAdminThresholdLoader] = useState(false)
    const [Notifications, setNotifications] = useState(false);
    const [showBusiness, setShowBusiness] = useState(false)
    const [logoutConfirmation, setLogoutConfirmation] = useState(false)
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [secondLoader, setSecondLoader] = useState(false)
    const [adminsettings, setAdminSettings] = useState({
        finance_email: "",
        support_email: "",
        sales_email: ""
    })
    const [adminSettingsUpdateLoader, setAdminSettingsUpdateLoader] = useState(false)

    // redux states
    const state = useSelector(state => state)
    const unread_count = state?.user?.unread_count
    const allBusinesses = state?.restaurantBusiness?.businesses_topbar
    const pagination_data = state?.restaurantBusiness?.pagination_data_topbar

    // get admin threshold data
    const getAdminThresholdData = () => {
        if (userData?.role == 'Super Admin') {
            const success = (res) => {
                let data = res?.versions
                setAdminThresholdData(data?.threshold)
                setAdminSettings({
                    finance_email: data?.finance_email,
                    support_email: data?.support_email,
                    sales_email: data?.sales_email
                })
            }
            const fail = () => {
            }
            dispatch(getAdminThreshold(access_token, success, fail))
        }
    }

    // update admin threshold data
    const updateAdminThresholdData = () => {
        setAdminThresholdLoader(true)
        let payload = {
            threshold: adminThresholdData
        }
        const success = () => {
            setAdminSetting(false)
            setAdminThresholdLoader(false)
            setShowMenuPopup(false)
            getAdminThresholdData()
        }
        const fail = () => {
            setAdminThresholdLoader(false)
        }
        dispatch(updateAdminThreshold(access_token, payload, success, fail))
    }

    // handle change admin settings
    const handleChangeAdminSettings = (e) => {
        const { name, value } = e?.target
        setErrors((prev) => ({ ...prev, [name]: '' }));
        if (value != "") {
            emailValidation(name, value);
        }
        setAdminSettings((prev) => ({ ...prev, [name]: value }))
    }

    // handle close popup
    const handleClosePopup = () => {
        setAdminSetting(false)
        setErrors()
        getAdminThresholdData()
    }

    // update admin settings
    const updateAdminSettingsData = () => {
        setAdminSettingsUpdateLoader(true);

        // Extract the email fields from the adminsettings object
        const { finance_email, support_email, sales_email } = adminsettings;

        // Run email validation for each email field
        const isFinanceEmailValid = finance_email ? validateEmail(finance_email) : true;
        const isSupportEmailValid = support_email ? validateEmail(support_email) : true;
        const isSalesEmailValid = sales_email ? validateEmail(sales_email) : true;

        // Check if any validation failed
        if (!isFinanceEmailValid || !isSupportEmailValid || !isSalesEmailValid) {
            setAdminSettingsUpdateLoader(false);
            return; // Stop the function if any email is invalid
        }

        let payload = { ...adminsettings };
        const success = () => {
            setAdminSetting(false);
            setAdminSettingsUpdateLoader(false);
            setShowMenuPopup(false);
            getAdminThresholdData();
        };
        const fail = () => {
            setAdminSettingsUpdateLoader(false);
        };

        dispatch(updateAdminSettings(access_token, payload, success, fail));
    };


    // get all businesses
    const getAllBusinesses = () => {
        const success = (data) => {
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        let payload = {
            page: currentPage,
            per_page: 10,
            restaurant_id: userData?.restaurant?.id,
            topbar: true
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        dispatch(getRestaurantBusiness(access_token, payload, success, fail))
    }

    useEffect(() => {
        getAdminThresholdData()
        if (access_token && (defaultRoute == '/restaurant/dashboard')) {
            getAllBusinesses()
        }
    }, [])

    return {
        showMenuPopup, setShowMenuPopup, userData, adminSetting, setAdminSetting, adminThresholdData, adminThresholdLoader,
        setAdminThresholdData, updateAdminThresholdData, Notifications, setNotifications, unread_count, navigate, defaultRoute, showBusiness,
        setShowBusiness, logoutConfirmation, setLogoutConfirmation, loader, allBusinesses, currentPage, setCurrentPage, secondLoader,
        pagination_data, handleClosePopup, errors, adminsettings, handleChangeAdminSettings, updateAdminSettingsData,
        adminSettingsUpdateLoader
    }
}
export default useTopBar