import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAdminWishlistUpdate from './helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import LabelAvatar from 'Components/Elements/LabelAvatar'
import StaffDataShimmer from 'Utility/Shimmers/StaffDataShimmer'
import LoginInput from 'Components/Elements/LoginInput'

const UpdateWishlist = ({ open, close, wishlistDetail, successCallBack }) => {
    const { paginationData, loader, secondLoader, currentPage, setCurrentPage, staffData, updateWishListStaffMember, searchText, setSearchText
    } = useAdminWishlistUpdate({ wishlistDetail, successCallBack, close })
    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Select Staff'}
        >
            <div className="space-y-3">
                <LoginInput
                    title={""}
                    placeholder={'Search...'}
                    value={searchText}
                    onChange={(e) => { setSearchText(e.target.value) }}
                    required={false}
                    inputPadding={"px-4 py-[9px]"}
                    parentClass={"w-full"}
                />
                {loader
                    ? <div className="flex flex-col space-y-4">
                        {[0, 1, 2, 3, 4, 5]?.map(itm => {
                            return <StaffDataShimmer />
                        })}
                    </div>
                    :
                    staffData?.length > 0 ?
                        <InfiniteScroll
                            dataLength={staffData?.length ? staffData?.length : 10} //This is important field to render the next data
                            next={() => {
                                setCurrentPage(currentPage + 1)
                            }}
                            hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                            loader={secondLoader
                                ? <div className="flex justify-center items-center my-4">
                                    <PreloaderSm />
                                </div> : ""
                            }
                        >
                            <div className="flex flex-col space-y-4">
                                {staffData?.map(itm => {
                                    return <div className='flex gap-2 items-center border-b border-lightGrey pb-4 cursor-pointer' onClick={() => { updateWishListStaffMember(itm?.id) }}>
                                        <LabelAvatar name={itm?.first_name} />
                                        <div>
                                            <h1 className='font-semibold text-blueGrey'>{`${itm?.first_name} ${itm?.last_name}`}</h1>
                                            <span className='extralight text-blueGrey'>{itm?.email}</span>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </InfiniteScroll>
                        : <NotFound />
                }
            </div>
        </Popup>
    )
}

export default UpdateWishlist