import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAdminCreateEditSlotHelper from './helper'
import { currency } from 'Adapters/variables'
import FloatInput from 'Components/Elements/FloatInput'
import ToggleWithText from 'Components/Elements/ToggleWithText'

const AdminCreateEditSlot = ({ open, close, editData, successCallBack, setEditData }) => {
    const { data, loader, updateOrAddSlotData, handleChange, errors, handleClosePopup
    } = useAdminCreateEditSlotHelper({ editData, close, successCallBack, setEditData })
    return (
        <Popup
            open={open}
            close={handleClosePopup}
            heading={editData ? "Edit Slot" : "Add Slot"}
            customSizeStyle={'sm:w-[40vw] md:w-[50vw] lg:w-[40vw]'}
        >
            <div className="flex flex-col space-y-5">
                <LoginInput
                    required={true}
                    title={'Slot Name'}
                    placeholder={'Enter Slot Name'}
                    value={data?.name}
                    name={'name'}
                    onChange={handleChange}
                    error={errors?.name}
                />
                <FloatInput
                    title="Profile Boosting Week Day Fees"
                    leftIcon={currency}
                    parentClass={'w-full'}
                    value={data?.weekday_price}
                    name={"weekday_price"}
                    onChange={handleChange}
                    placeholder={'Enter Profile Boosting Week Day Fees'}
                    error={errors?.weekday_price}
                    bgIconTransparent={"bg-transparent border-r border-blueGrey min-w-16"}
                />
                <FloatInput
                    title="Profile Boosting Weekend Fees"
                    leftIcon={currency}
                    parentClass={'w-full'}
                    value={data?.weekend_price}
                    name={"weekend_price"}
                    onChange={handleChange}
                    placeholder={'Enter Profile Boosting Weekend Fees'}
                    error={errors?.weekend_price}
                    bgIconTransparent={"bg-transparent border-r border-blueGrey min-w-16"}
                />
                {editData && editData?.id &&
                    <div className="flex border border-lightGrey text-blueGrey p-4 justify-between rounded-2xl">
                        <h1>Slot Status</h1>
                        <ToggleWithText
                            onChange={() => {
                                handleChange({
                                    target: {
                                        name: 'status',
                                    }
                                })
                            }}
                            checked={data?.status}
                        />
                    </div>
                }
                <div className='pt-2'>
                    <Button
                        className={'w-full'}
                        title={editData ? "Save" : "Add"}
                        loading={loader}
                        disabled={loader}
                        onClick={updateOrAddSlotData}
                    />
                </div>
            </div>
        </Popup>
    )
}

export default AdminCreateEditSlot