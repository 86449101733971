// Country
export const country_code_url = "https://geolocation-db.com/json/";

export const currency = 'AED'

export const SOCKET_BASE_URL = `${process.env.REACT_APP_SOCKET_BASE_URL}`

export const secondAdminEmail = `${process.env.REACT_APP_SECOND_ADMIN_EMAIL}`

export const BASE_URL = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`
export const terms_condition_url = `${process.env.REACT_APP_BACKEND_BASEURL}terms-and-conditions`
export const privacy_policy_url = `${process.env.REACT_APP_BACKEND_BASEURL}privacy`
export const subscription_invoice_url = `${process.env.REACT_APP_BACKEND_BASEURL}pdf/membership`

//  AUTH APIS
export const register_url = 'user/register'
export const login_url = 'user/login'
export const update_profile_url = 'update-profile'
export const verify_otp_url = 'user/verify/email'
export const verify_otp_url_forgot_password = 'user/verify/code'
export const reset_passwrod_url = 'user/forgot/password'
export const change_password_url = 'user/reset/password'
export const resend_otp_url = 'user/resend/otp'
export const delete_account_url = 'delete-account'
export const get_login_user_data = 'user-profile'
export const upload_trade_license = "upload_trade_license/"
export const post_user_signature = "admin/userTerms"
export const update_is_new_for_restaurant = "update_is_new_for_restaurant"

export const get_all_business_url = 'business/all'
export const add_business = 'add/business'
export const get_all_cuisines_url = 'cuisines'
export const add_cuisine = 'add/cuisine'
export const get_all_categories_url = 'categories'
export const get_all_restaurants_url = 'restaurants'
export const get_restaurant_business_hours = 'business-hours/get'
export const post_restaurant_business_hours = 'business-hours/set'
export const get_all_promotions_url = 'promotions'

// Rewards URL
export const get_announced_rewards_url = 'announced/rewards'
export const get_unannounced_rewards_url = 'unannounced/rewards'
export const get_rewards_url = 'rewards'

export const get_bookings_by_id = 'get_bookings_by_id'
export const get_wishlist = 'wishlist'
export const get_staff_data = 'staff/members'
export const get_staff_roles_data = 'staff/get-roles'
export const add_staff_member = 'staff/add'
export const update_staff_member = 'staff/update/'
export const update_wishlist_staff = 'wishlist/assign-to-staff/'
export const add_reward = 'add/reward'
export const update_reward = 'update/reward/'
export const get_top_performs = 'get_deservers'
export const announce_reward = 'announce_reward'


// ADMIN APIS
export const update_admin_settings = "settings/update"
export const get_admin_dashboard_detail_url = 'home'
export const get_dropdown_concierges_url = 'dropdown/concierges'
export const get_dropdown_restaurant_url = 'dropdown/restaurants'
export const get_dropdown_business_url = 'business/all'
export const get_concierge_restaurant_stats_url = 'adminStats'
export const get_unapproved_concierge = 'requested/concierges'
export const get_unapproved_restaurant = 'requested/restaurants'
export const edit_profile_by_admin = 'edit-profile-by-admin'
export const reject_submission = 'reject/request'
export const get_restaurant_url = 'restaurants'
export const get_concierges_url = 'concierges'
export const get_profile_by_id = 'get_profile_by_id'
export const update_restaurant_status = 'toogle/restaurant/status'
export const update_restaurant_suspension = 'toogle/suspension'
export const update_restaurant_subscription_commission = 'approve/restaurant'
export const get_single_restaurant_feedback_data = 'restaurant/feedback'
export const get_single_restaurant_menu_data = 'menu/categories'
export const get_single_restaurant_menu_pdf_data = 'pdf/menu'
export const get_admin_threshold = 'versions/get'
export const update_admin_threshold = 'update/threshold'
export const update_concierge_status = 'toogle/user/status'
export const update_concierge_commission = 'approve/user'
export const pay_concierger = 'pay/concierger'
export const get_single_concierge_bookings_data = 'concierger/details'
export const get_admin_earnings = 'get/admin-earnings'
export const get_admin_concierges_payments = 'get/concierges-payments'
export const get_single_conierge_transaction_detail = 'get/concierge-earning-by-number'
export const get_single_earning_detail = 'get/admin-earning-by-number'
export const update_single_earning_recieve_status = 'receive/restaurant/payment'
export const get_packages = 'packages'
export const add_package = 'add/package'
export const update_package = 'update/package/'
export const get_purchased_packages = 'purchased/packages'
export const approve_promotion_purchase_status = 'slots/approve'
export const get_invoices_data = 'invoices'
export const get_pending_payable_and_recieveable_data = 'pending-payable-and-recieveable'
export const get_restaurant_booking_analytics = 'booking_report'
export const get_booking_analytics = 'reports'
export const get_concierge_restaurant_analytics = 'top-performers-by-bookings'
export const get_specific_concierge_restaurant_analytics = 'barchart'

// Conceirge Bookings
export const get_all_reservations_url = 'reservations'
export const get_all_booking_url = 'user/my-bookings'
export const get_single_reservation = 'reservation/'
export const create_booking_url = 'user/add/reservation'
export const update_booking_url = 'update/reservation'
export const booking_report_url = 'booking_report'
export const send_booking_email_url = 'send_booking_by_email'
export const send_booking_email_followup_url = 'booking_follow_up_by_email'

// CONCIERGE WALLET
export const get_my_wallet_url = 'user/my-wallet'
export const recieve_commission = 'user/receive/payment'

// Restaurant Booking URL
export const get_restaurant_booking_url = 'admin/my-bookings'
export const modification_booking_url = 'admin/reservation/modification'
export const cancel_booking_url = 'cancel/reservation'
export const confirm_booking_url = 'admin/confirm/booking'
export const noShow_booking_url = 'admin/reservation/unappear'
export const bill_booking_url = 'admin/pay'

//  Chat URL
export const get_concierge_chat_url = 'chat/restaurants'
export const get_restaurant_chat_url = 'chat/concierges'
export const get_commission_analytics_url = 'commission'
export const receive_payment_url = 'user/receive/payment'
export const get_tranaction_url = 'user/transactions'

// Support request
export const get_concierge_support_req_url = 'requests'
export const get_singel_concierge_support_req_url = 'request'
export const add_thread_comment_url = 'thread'
export const create_support_request_url = 'add_request'
export const update_support_request_status = 'toogle-support-request'
export const reopen_support_request_url = 'reopen/request'

// Wishlist
export const create_wishlist_url = 'wishlist/add'
export const get_wishlist_url = 'wishlist'
export const update_wishlist_url = 'wishlist/update'
export const toggle_wish_list_url = 'wishlist/toogle'

// Udpate email
export const update_email_otp_url = "update/email/otp"
export const verify_email_otp_url = "update/email/verify"
export const verify_restaurant_email_otp_url = "admin/business/update-email/verify-otp"
export const send_restaurant_email_otp_url = "admin/business/update-email/send-otp"

// FCM Tokens
export const update_fcm_tokens_url = "update_fcm_tokens"
export const remove_fcm_token_url = "logout"
export const generate_test_notifications = "test_notification"

// NOTIFICATIONS
export const get_all_notification_url = "notifications"
export const read_single_notification_url = "read/notification"
export const read_all_notification_url = "read/all/notifications"

// BOOKING REVIEW
export const booking_review_url = process.env.REACT_APP_BACKEND_BASEURL + 'reservation/'

//Promotion URL 
export const add_promotion_url = 'admin/add/promotion'
export const update_promotion_url = 'admin/update/promotion'

// Packages URL 
export const get_active_packages = 'active-packages'
export const buy_promotion_package_url = 'admin/buy-package'
export const boost_promotion_package_url = 'admin/boost-promotion'

// get payments
export const get_restaurant_payments_url = 'admin/transactions/payment'
export const get_due_payments_url = 'restaurant/details'
export const get_booking_stats_url = 'admin/restaurant/stats'
export const pay_super_admin_url = 'admin/pay/admin'
export const get_subscription_url = 'restaurant/subscription'
export const get_restaurant_fincancials_url = 'financials'
export const pay_subscription_url = 'admin/pay/subscription'
export const get_subscription_payments_url = 'admin/transactions/subscription'

// Menus Category
export const add_pdf_menu_url = 'admin/add/menu/pdf'
export const add_menu_category_url = 'admin/add/menu/category'
export const get_menu_category_url = 'menu/categories'
export const update_menu_category_url = 'admin/update/menu/category'
export const delete_menu_category_url = 'admin/delete/menu/category'
export const delete_pdf_menu_url = 'admin/delete/menu/pdf/'

// Sub Menu for menu category
export const add_sub_menu_url = 'admin/add/menu/item'
export const update_menu_itm_url = 'admin/update/menu/item'
export const delete_menu_itm_url = 'admin/delete/menu/item'

// CONCIERGE INVITE FRIEND
export const get_referral_data_url = 'user/referrals'
export const check_and_send_referral_url = 'check-and-send-referral'
export const resend_referral_invite_url = 'resend-referral-invite'
export const delete_referral_invite_url = 'delete-referral-invite'
export const get_redeem_history_data_url = 'referral/transactions'
export const referral_withdraw_url = 'referral/withdraw'

// GENERATE DESCRIPTION
export const generate_description = 'generate/description'

// Invoices 
export const get_invoice_report_url = 'invoices'
export const send_invoice_by_email_url = 'send_invoice_by_email'

// BUSINESS
export const get_business_types = 'business-types'
export const get_restaurant_business = 'admin/business'
export const add_restaurant_business = 'admin/business/add'
export const update_restaurant_business = 'business/update'
export const resend_email_otp = 'admin/business/resend-email-otp'
export const business_email_otp_verification = 'admin/business/verify-email'
export const switch_business_profile = 'admin/business/switch-profile'

// PROFILE BOOSTING
export const get_setting_data = 'settings'
export const get_all_available_slots = 'slots'
export const check_available_slot = 'admin/slots/check-availability'
export const book_available_slot = 'admin/slots/book'
export const get_boosting_history_data = 'slots/get-all'
export const get_boosting_history_detail_data = 'slots/get/single/booking/'
export const add_slot = "slots/add"
export const update_slot = "slots/update/"

// TEAM MEMBERS
export const get_team_members = "user/team"
export const add_team_members = "user/team/add"
export const update_team_members = "user/team/update"

// ANNOUNCEMENT
export const get_announcements = "announcements"
export const add_announcement = "add/announcement"
export const update_announcement = "update/announcement/"
export const delete_announcement = "delete/announcement/"

// Referral Analytics
export const get_referral_analytics = "referral/all"

// Pay Referral Rewards
export const get_referral_rewards = "referral/transactions"

// BUSINESS CATEGORIES
export const get_business_categories = "categories"
export const add_business_category = "categories/add"
export const update_business_category = "categories/update/"
