import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'Components/Elements/Button';
import Dropdown from 'Components/Elements/Dropdown';
import ToggleWithText from 'Components/Elements/ToggleWithText';
import { WEEKDAYS } from 'Constants/Data/Months';
import { BUSINESS_TIME } from 'Constants/Data/OpeningHours';

const BusinessHour = ({ postBusinessHours, businessHourLoader }) => {
    // redux states
    const state = useSelector(state => state);
    const hoursData = state?.retaurant?.business_hours;
    const business_hours_state_updated = state?.retaurant?.business_hours_state_updated;

    // states
    const [location2, setLocation2] = useState({});
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});

    const handleToggle = (day) => {
        setLocation2((prev) => {
            if (prev[day]) {
                const updated = { ...prev };
                delete updated[day];
                return updated;
            } else {
                return {
                    ...prev,
                    [day]: {
                        is_closed: false,
                        opening_time: "",
                        closing_time: "",
                    },
                };
            }
        });
    };

    const handleOpeningTimeChange = (day, value) => {
        setLocation2((prev) => ({
            ...prev,
            [day]: {
                ...prev[day],
                opening_time: value,
            },
        }));
    };

    const handleClosingTimeChange = (day, value) => {
        setLocation2((prev) => ({
            ...prev,
            [day]: {
                ...prev[day],
                closing_time: value,
            },
        }));
    };

    const convertArrayToObject = (data) => {
        const result = {};
        data?.forEach(item => {
            const { day_of_week, opening_time, closing_time } = item;
            const openingTimeValue = BUSINESS_TIME?.find(itm => itm?.label === opening_time || itm?.value === opening_time)?.value || '';
            const closingTimeValue = BUSINESS_TIME?.find(itm => itm?.label === closing_time || itm?.value === closing_time)?.value || '';
            result[day_of_week] = {
                is_closed: false,
                opening_time: openingTimeValue,
                closing_time: closingTimeValue
            };
        });
        return result;
    };

    useEffect(() => {
        if (business_hours_state_updated) {
            if (hoursData?.length > 0) {
                const convertedData = convertArrayToObject(hoursData);
                setLocation2(convertedData);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }
    }, [hoursData, business_hours_state_updated]);

    const validateData = (data) => {
        const newErrors = {};
        Object.keys(data).forEach(day => {
            const item = data[day];
            if (item.is_closed) {
                return;
            }
            if (!item.opening_time) {
                newErrors[day] = { ...newErrors[day], opening_time: "Opening time is required." };
            }
            if (!item.closing_time) {
                newErrors[day] = { ...newErrors[day], closing_time: "Closing time is required." };
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = () => {
        if (validateData(location2)) {
            postBusinessHours(location2);
        }
    };

    return (
        loading ?
            <div>
                {WEEKDAYS.map((day, index) => (
                    <div key={index} className="lg:grid flex grid-cols-5 gap-4 lg:flex-row flex-col pb-[1rem]">
                        <div className="col-span-1 flex items-center">
                            <div className="w-full bg-gray-500 rounded animate-pulse h-8"></div>
                        </div>
                        <div className="col-span-4 flex items-center w-full sm:gap-4 gap-2 lg:flex-row flex-col">
                            <div className="w-full bg-gray-500 rounded animate-pulse h-8"></div>
                            <div className="w-full bg-gray-500 rounded animate-pulse h-8"></div>
                        </div>
                    </div>
                ))}

                <div className="flex justify-end">
                    <div className="w-24 bg-gray-500 rounded animate-pulse h-10"></div>
                </div>
            </div>
            :
            <div>
                {WEEKDAYS.map((day, index) => {
                    const dayData = location2[day];

                    return (
                        <div key={index} className="lg:grid flex grid-cols-5 gap-4 lg:flex-row flex-col pb-[1rem]">
                            <div className="col-span-1 flex items-center">
                                <div className="w-full">
                                    <ToggleWithText
                                        checked={!!dayData}
                                        title={<p className="capitalize text-white">{day}</p>}
                                        onChange={() => handleToggle(day)}
                                    />
                                </div>
                            </div>
                            {dayData && (
                                <div className="col-span-4 flex items-center w-full sm:gap-6 gap-2 lg:flex-row flex-col">
                                    <div className="w-full">
                                        <Dropdown
                                            className="w-full"
                                            title={false}
                                            showTitle={false}
                                            placeholder={"Start Time"}
                                            option={BUSINESS_TIME}
                                            value={dayData.opening_time}
                                            onChange={(e) => handleOpeningTimeChange(day, e.target.value)}
                                            error={errors[day]?.opening_time} // Pass the error message
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Dropdown
                                            className="w-full"
                                            title={false}
                                            showTitle={false}
                                            placeholder={"End Time"}
                                            // option={BUSINESS_TIME.filter((itm, itm_index) => {
                                            //     if (!dayData.opening_time) {
                                            //         return true;
                                            //     }
                                            //     else {
                                            //         let index_start = BUSINESS_TIME.find(
                                            //             (op_itm) => op_itm.value === dayData.opening_time
                                            //         );
                                            //         index_start = BUSINESS_TIME.indexOf(index_start);
                                            //         return itm_index > index_start;
                                            //     }
                                            // })}
                                            option={BUSINESS_TIME}
                                            value={dayData.closing_time}
                                            onChange={(e) => handleClosingTimeChange(day, e.target.value)}
                                            error={errors[day]?.closing_time} // Pass the error message
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}

                <div className="flex justify-end pt-4">
                    <Button
                        loading={businessHourLoader}
                        disabled={businessHourLoader}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
    );
};

export default BusinessHour;
