import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import React from 'react'

const SlotCard = ({ ind, weekday_price, weekend_price, name, allowEdit, onEditClick }) => {
    return (
        <div className='rounded-[1.25rem] bg-darkGrey p-4'>
            <div className="flex justify-between items-center gap-2">
                <div className="flex items-center gap-2">
                    <div className="relative w-7 h-7 bg-cover bg-center" style={{ backgroundImage: "url('/img/profile/Slotsbg.png')" }}>
                        <div className="absolute font-semibold inset-0 flex items-center justify-center text-primary">
                            {ind + 1}
                        </div>
                    </div>
                    <h2 className="text-white text-lg">{name}</h2>
                </div>
                {allowEdit &&
                    <div className="cursor-pointer" onClick={onEditClick && onEditClick}>
                        <Svgs.EditGoldIcon />
                    </div>
                }
            </div>
            <hr className='my-3 border-lightGrey' />
            <div className='flex items-center justify-center w-full gap-4'>
                <div className='flex flex-col space-y-2 flex-1 items-center justify-center'>
                    <p className='text-lg font-semibold text-white'>Week day fees</p>
                    <h2 className='text-primary text-primary font-semibold min-w-28 rounded-[10px] bg-secondary flex items-center justify-center py-2 px-1'>{(+weekday_price)?.toFixed(2)} {currency}</h2>
                </div>
                <hr className='w-[1px] h-24 border-r border-solid border-lightGrey' />
                <div className='flex flex-col space-y-2 flex-1 items-center justify-center'>
                    <p className='text-lg font-semibold text-white'>Weekend fees</p>
                    <h2 className='text-primary font-semibold min-w-28 rounded-[10px] bg-secondary flex items-center justify-center py-2 px-1'>{(+weekend_price)?.toFixed(2)} {currency}</h2>
                </div>
            </div>
        </div>
    )
}

export default SlotCard
