import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const BookingAnalytics = ({ data }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[800px]">
                    <thead className="text-sm text-white bg-primary border-b border-table capitalize ">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                ID
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Created At
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Confirmed At
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Time Difference
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Booking Date
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Cancel Reason
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Modification Reason
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Concierge Name
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Business Name
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Guest Name
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Guest
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Total Bill
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Business Earning
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Commission
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Payable To Concierge
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Platform Earning
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="text-blueGrey cursor-pointer text-blueGrey border-b border-table bg-primary">
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.number}
                                            </td>
                                            <td className="px-6 py-4 font-medium whitespace-nowrap ">
                                                {itm?.created_at ? itm?.created_at : "----"}
                                            </td>
                                            <td className="px-6 py-4 font-medium whitespace-nowrap ">
                                                {itm?.confirmed_at ? itm?.confirmed_at : "----"}
                                            </td>
                                            <td className="px-6 py-4 font-medium whitespace-nowrap ">
                                                {itm?.time_difference ? `After ${itm?.time_difference}` : "----"}
                                            </td>
                                            <td className="px-6 py-4 font-medium whitespace-nowrap ">
                                                {itm?.date_time ? moment(itm?.date_time)?.format('DD-MM-YYYY') : "----"}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                <span className={`${itm?.status?.split(' ')[0]?.toLowerCase()} px-2 py-1 w-fit rounded-lg text-sm font-normal flex justify-center items-center`}>
                                                    {itm?.status}
                                                </span>
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap ">
                                                <span className="max-w-xs overflow-hidden text-ellipsis line-clamp-2">{itm?.cancel_reason ? itm?.cancel_reason : "----"}</span>
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap">
                                                <span className="max-w-xs overflow-hidden text-ellipsis line-clamp-2">{itm?.modifications?.length > 0 ? itm?.modifications[0]?.restaurant_text : "----"}</span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {itm?.member ? itm?.member?.name : `${itm?.user?.first_name ? itm?.user?.first_name : ""} ${itm?.user?.last_name ? itm?.user?.last_name : ""}`}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {itm?.restaurant?.name}
                                            </td>
                                            <td class="px-6 py-3 font-semibold whitespace-nowrap ">
                                                {itm?.guest_name}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.total_pax}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.amount}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.restaurant_earning}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.commission}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.manager_earning}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.platform_earning}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default BookingAnalytics