import Button from 'Components/Elements/Button'
import Popup from 'Components/Elements/Popup'
import ReactMonthPicker from 'Components/Elements/ReactMonthPicker'
import React, { useEffect, useState } from 'react'

const DownloadCSVPopup = ({ open, close, onClearFilter, downloadLoading, onApplyFilter, filterData, setFilterData }) => {
    // states
    const [date, setDate] = useState(filterData)

    useEffect(() => {
        if (date) {
            setFilterData((prev) => ({
                ...prev,
                monthValue: date,
            }))
        }
    }, [date])


    // handle on clear filters
    const handleOnClearFilters = () => {
        onClearFilter && onClearFilter()
        setDate()
    }

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Download Transaction Data'}
        >
            <div className='flex flex-col space-y-4'>
                <h1 className='text-blueGrey'>
                    Select the year and the month for which you want to download transaction data
                </h1>
                <ReactMonthPicker
                    title={'Select Month'}
                    name={'date'}
                    value={date}
                    onChange={(e) => { setDate(e.target.value) }}
                    placeholder={'Select Month'}
                    inputPadding={"px-4 py-[9px]"}
                    inputClass={"flex-1"}
                    className={"flex-1"}
                />
                <div className="flex items-center justify-between gap-2 mt-4">
                    {/* <Button text={'Clear'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={handleOnClearFilters} /> */}
                    <Button loading={downloadLoading} text={'Download CSV'} className={'w-full'} onClick={onApplyFilter && onApplyFilter} />
                </div>
            </div>
        </Popup>
    )
}

export default DownloadCSVPopup