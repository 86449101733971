import React from 'react'

const AdminStaffShimmer = () => {
    return (
        <div className="bg-darkGrey restaurant-card-shadow rounded-xl p-4 pt-2 w-full animate-pulse">
            <div className='flex gap-2 items-center'>
                <div className="flex items-center justify-center h-[4rem] w-[4rem] overflow-hidden rounded-full bg-gray-200 ">
                </div>
                <div className="flex flex-1 flex-col space-y-2 mt-5">
                    <div className='flex gap-4 justify-between items-center'>
                        <div className='flex flex-col space-y-1'>
                            <div className='h-4 rounded-md bg-gray-200 w-20'></div>
                            <div className='h-4 rounded-md bg-gray-200 w-28'></div>
                        </div>
                        <div className="h-6 justify-end w-[36px] bg-gray-300 rounded-full"></div>
                    </div>
                    <div className='flex xs:items-center xs:flex-row flex-col justify-between gap-4'>
                        <div className="flex gap-2 items-center text-sm text-[#b0b0b0]">
                            <div className='h-6 rounded-md bg-gray-200 w-10'></div>
                            <div className='h-6 rounded-md bg-gray-200 w-28'></div>
                        </div>
                        <div className="flex items-center gap-2 text-[#b0b0b0] mt-1 text-sm">
                            <div className='h-6 rounded-md bg-gray-200 w-10'></div>
                            <div className='h-6 rounded-md bg-gray-200 w-28'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminStaffShimmer