import React from 'react'
import Svgs from '../../Assets/svgs'
import useTopBar from './helper'
import NotificationsDropdown from 'Components/Notifications'
import DeletePopup from 'Pages/Restaurants/Menu/DeleteVerificationPopup'
import BusinessPopup from './Popups/BusinessPopup'
import useLogoutHelper from 'Hooks/useLogoutHelper'
import Image from 'Components/Elements/Image'
import AdminSettingsPopup from './Popups/AdminSettingsPopup'
import { secondAdminEmail } from 'Adapters/variables'

const TopBar = ({ openSidebar, showTopbarSearch, showFullLength, hideNotificationIcon, showLogo }) => {
    const { showMenuPopup, setShowMenuPopup, userData, adminSetting, setAdminSetting, Notifications, setNotifications, unread_count,
        navigate, defaultRoute, showBusiness, setShowBusiness, logoutConfirmation, setLogoutConfirmation, loader, allBusinesses,
        currentPage, setCurrentPage, secondLoader, pagination_data, handleClosePopup, errors, adminsettings, handleChangeAdminSettings,
        updateAdminSettingsData, adminSettingsUpdateLoader
    } = useTopBar()

    const { logoutUser, logoutLoader } = useLogoutHelper()

    return (
        <>
            <div className={`${!showFullLength ? "lg:ml-72 md:ml-64" : ""} bg-primary border-b border-lightGrey px-4 py-2.5 fixed left-0 right-0 top-0 z-[55]`}>
                <div className="flex justify-between items-center ">
                    {showLogo ?
                        <div className="flex items-center justify-between cursor-pointer" onClick={() => { navigate(defaultRoute) }}>
                            <img src={"/img/logo3.svg"} className="mr-3 h-10" alt="Hotel Logo" />
                        </div>
                        :
                        <div className='md:hidden flex gap-4'>
                            <div onClick={openSidebar} className="p-2 text-blueGrey rounded-lg cursor-pointer md:hidden hover:text-white hover:bg-lightGrey border border-lightGrey focus:bg-lightGrey focus:ring-2 focus:ring-lightGrey ">
                                <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                </svg>
                                <svg aria-hidden="true" className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="flex items-center justify-between cursor-pointer md:hidden" onClick={() => { navigate(defaultRoute) }}>
                                <img src="/img/logo3.svg" className="mr-3 h-10" alt="Hotel Logo" />
                            </div>
                        </div>
                    }
                    <div className="hidden md:block md:pl-2">
                        {showTopbarSearch &&
                            <div className="relative md:w-64 xl:w-96">
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <Svgs.SearchIcon />
                                </div>
                                <input autoComplete="off" type="text" name="email" id="topbar-search" className="bg-[#4E4E4E] text-[#9C9AA7] text-sm rounded-lg placeholder:[#9C9AA7] block lg:w-[300px] pl-10 p-2.5 " placeholder="Search" />
                            </div>
                        }
                    </div>
                    <div className='flex justify-between items-center gap-x-2 relative'>
                        {hideNotificationIcon || defaultRoute == '/admin/dashboard' ?
                            "" :
                            <div className="relative p-2 cursor-pointer"
                                onClick={() => setNotifications(!Notifications)}
                            >
                                <Svgs.BellIcon fill={'var(--blueGrey-color)'} />
                                {unread_count != 0 && unread_count &&
                                    <span className="absolute top-0 right-0 h-5 w-5 text-xs flex items-center font-semibold justify-center border-[2px] border-secondary bg-secondary text-primary rounded-full">
                                        {unread_count}
                                    </span>
                                }
                            </div>
                        }
                        <div className="flex items-center gap-x-2 text-sm rounded-full cursor-pointer" onClick={() => {
                            setNotifications(false)
                            if (defaultRoute == '/concierge/edit-profile' || defaultRoute == '/concierge/dashboard' || defaultRoute == '/admin/dashboard' || defaultRoute == '/restaurant/edit-profile') {
                                setShowMenuPopup(!showMenuPopup)
                            } else if (defaultRoute == '/restaurant/dashboard') {
                                setShowBusiness(!showBusiness)
                            }
                        }}>
                            {(userData?.profile_picture || userData?.restaurant?.images?.length > 0) ?
                                <Image src={userData?.profile_picture || userData?.restaurant?.images[0]?.url} className={'h-12 w-12 object-cover rounded-full'} alt={"Profile Picture"} customLoaderClass={'h-12 w-12 rounded-full'} customLoaderHeight={'h-12'} />
                                :
                                <Svgs.UserIcon2 width={'40'} height={'40'} />
                            }
                            <p className="text-base font-semibold text-white">{userData?.restaurant?.name ? userData?.restaurant?.name : `${userData?.first_name ? userData?.first_name : ""} ${userData?.last_name ? userData?.last_name : ""}`}</p>
                            <Svgs.ArrowDown />
                        </div>
                        {showMenuPopup && (defaultRoute == '/concierge/edit-profile' || defaultRoute == '/concierge/dashboard' || defaultRoute == '/admin/dashboard' || defaultRoute == '/restaurant/edit-profile') &&
                            <>
                                <div className="absolute w-full z-10 rounded-md bg-darkGrey shadow-popup-card top-12 divide-y text-blueGrey">
                                    {userData?.role == 'Super Admin' && userData?.email != secondAdminEmail &&
                                        <div className="flex  group items-center gap-3 px-5 py-3 cursor-pointer rounded-md MiniMenu" onClick={() => { setAdminSetting(!adminSetting) }}>
                                            <p className="text-sm">Settings</p>
                                        </div>
                                    }
                                    <div className="flex  group items-center gap-3 px-5 py-3 cursor-pointer border-t border-lightGrey rounded-md MiniMenu" onClick={() => {
                                        setLogoutConfirmation(!logoutConfirmation)
                                    }}>
                                        <Svgs.LogoutIcon className={'icon w-[1rem] h-[1rem]'} />
                                        <p className="text-sm">Logout</p>
                                    </div>
                                </div>
                                <div
                                    className="fixed inset-0 bg-black/20 z-[1] top-0 bottom-0 left-0 right-0"
                                    onClick={() => {
                                        setShowMenuPopup(!showMenuPopup)
                                    }}
                                ></div>
                            </>}
                    </div>
                </div>
                {Notifications && <NotificationsDropdown setOpen={setNotifications} open={Notifications} />}
            </div>

            {adminSetting &&
                <AdminSettingsPopup
                    open={adminSetting}
                    close={handleClosePopup}
                    data={adminsettings}
                    errors={errors}
                    handleChange={handleChangeAdminSettings}
                    onUpdate={updateAdminSettingsData}
                    loading={adminSettingsUpdateLoader}
                />
            }

            {/* <Popup
                open={adminSetting}
                close={setAdminSetting}
                customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
                header={false}
            >
                <div className='my-2'>
                    <LoginInput
                        title={'Set Threshold'}
                        onChange={(e) => { setAdminThresholdData(e.target.value) }}
                        value={adminThresholdData}
                        required={true}
                        placeholder={'Set Threshold'}
                    />
                    <div className="flex items-center justify-between gap-4 mt-4">
                        <Button text={'Cancel'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={() => { setAdminSetting(false) }} />
                        <Button text={'Save'} className={'w-full'} onClick={updateAdminThresholdData} loading={adminThresholdLoader} disabled={adminThresholdLoader} />
                    </div>
                </div>
            </Popup> */}

            <BusinessPopup
                open={showBusiness}
                close={setShowBusiness}
                loader={loader}
                allBusinesses={allBusinesses}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                secondLoader={secondLoader}
                pagination_data={pagination_data}
            />

            <DeletePopup
                open={logoutConfirmation}
                close={setLogoutConfirmation}
                title={<div className='flex flex-col space-y-4 mx-auto items-center justify-center pb-4 text-center'>
                    <h2 className='text-white'>Are you sure you want to Logout?</h2>
                </div>}
                loading={logoutLoader}
                onDelete={logoutUser}
                customSize={'md:w-[35vw] xl:w-[30vw]'}
            />
        </>
    )
}

export default TopBar