import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'

const AdminSettingsPopup = ({ open, close, data, errors, handleChange, onUpdate, loading }) => {
    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={"Settings"}
        >
            <div className='flex flex-col space-y-5'>
                <LoginInput
                    title={'Finance Email'}
                    name={"finance_email"}
                    onChange={handleChange}
                    error={errors?.finance_email}
                    value={data?.finance_email}
                    placeholder={'Set Finance Email'}
                />
                <LoginInput
                    title={'Support Email'}
                    name={"support_email"}
                    onChange={handleChange}
                    error={errors?.support_email}
                    value={data?.support_email}
                    placeholder={'Set Support Email'}
                />
                <LoginInput
                    title={'Sales Email'}
                    name={"sales_email"}
                    onChange={handleChange}
                    error={errors?.sales_email}
                    value={data?.sales_email}
                    placeholder={'Set Sales Email'}
                />
                <div className="flex items-center justify-between gap-4 mt-4">
                    <Button text={'Cancel'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={close} />
                    <Button text={'Save'} className={'w-full'} onClick={onUpdate} loading={loading} disabled={loading} />
                </div>
            </div>
        </Popup>
    )
}

export default AdminSettingsPopup
