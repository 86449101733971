import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminSidebarRoutes from "./SidebarRoutes/AdminSidebarRoutes";
import RestaurantSidebarRoutes from "./SidebarRoutes/RestaurantSidebarRoutes";
import ConiergesSidebarRoutes from "./SidebarRoutes/ConciergesSidebarRoutes";
import { removeTokenFromFCM } from "firebase.config";
import { removeFCMToken } from "Adapters/APIs/Authentication";
import { logoutfunction } from "Constants/Functions/Logout";
import { useDispatch } from "react-redux";

const useSidebar = (active) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const defaultRoute = getDataFromLocalStorage('defaultRoute')
    const access_token = getDataFromLocalStorage('access_token')
    const fcm_token = getDataFromLocalStorage('fcm_token')
    const user_data = getDataFromLocalStorage('user_data')

    const adminRoutes = AdminSidebarRoutes(active, user_data)
    const conciergesRoutes = ConiergesSidebarRoutes(active, user_data)
    const restaurantRoutes = RestaurantSidebarRoutes(active, user_data)

    // states
    const [sidebarRoutes, setSidebarRoutes] = useState(defaultRoute == '/admin/dashboard' ? adminRoutes
        : defaultRoute == '/restaurant/dashboard' ? restaurantRoutes
            : conciergesRoutes)
    const [logoutConfirmation, setLogoutConfirmation] = useState(false)
    const [logoutLoader, setLogoutLoader] = useState(false)

    useEffect(() => {
        const userData = getDataFromLocalStorage('user_data')
        switch (userData?.role) {
            case 'Super Admin':
                setSidebarRoutes(adminRoutes?.filter(itm => !itm?.isAllowed))
                break;
            case 'User':
                setSidebarRoutes(conciergesRoutes?.filter(itm => !itm?.isAllowed))
                break;
            case 'Admin':
                setSidebarRoutes(restaurantRoutes?.filter(itm => !itm?.isChild))
                break;
            default:
                break;
        }
    }, [])


    // logout function
    const logoutUser = async (onSuccessCallback) => {
        const success = () => {
            onSuccessCallback && onSuccessCallback()
            logoutfunction()
            // setLogoutLoader(false)
        }
        setLogoutLoader(true)
        await removeTokenFromFCM()
        dispatch(removeFCMToken(access_token, { fcm_token: fcm_token }, success))
    }

    return {
        navigate, sidebarRoutes, defaultRoute, logoutUser, logoutLoader, logoutConfirmation, setLogoutConfirmation
    }
}

export default useSidebar