import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import Back from 'Components/Elements/Back'
import Button from 'Components/Elements/Button'
import FilePicker from 'Components/Elements/FilePicker'
import FloatInput from 'Components/Elements/FloatInput'
import Image from 'Components/Elements/Image'
import ImageUploader from 'Components/Elements/ImageUploader'
import Textarea from 'Components/Elements/TextArea'
import { copyToClipBoard } from 'Constants/Functions/CopyToClipBoard'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import BookingDetailShimmer from 'Utility/Shimmers/BookingDetailShimmer'

const ConfirmBooking = ({ close, data, onEditClick, isRestaurant, sendMail, onReasonSubmit, btnLoading, errors, setErrors, loading, navigation }) => {
    const navigate = useNavigate()

    const { getDataFromLocalStorage, removeDataFromSessionStorage } = useSetDataInLocalStorage()
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    const status = defaultRoute == '/admin/dashboard' ? data?.status : data?.status == 'Commission paid' ? 'Billed' : data?.status

    // states
    const [requestPopup, setRequestPopup] = useState('')
    const [message, setMessage] = useState(false)
    const [billPopup, setBillPopup] = useState(false)
    const [billingDetail, setBillingDetail] = useState({
        proof: '',
        amount: ''
    })

    useEffect(() => {
        setErrors && setErrors('')
    }, [billPopup, requestPopup])

    return (
        <>
            {loading ?
                <BookingDetailShimmer close={close} /> :
                <div id="bookings_sidebar" className="translate-x-0 transition-all fixed top-0 left-0 z-[4443] w-full max-h-screen h-screen bg-[#ffffff]/15">
                    <div className="h-full">
                        <div className="relative p-4 w-full max-h-full">
                            <div className="fixed h-screen top-0 right-0 xs:max-w-md max-w-[320px] bg-primary shadow ">
                                <div className="h-full w-full">
                                    <div className="flex px-4 md:px-5">
                                        <Back title={''} onClick={() => {
                                            if (navigation) {
                                                navigation()
                                            } else {
                                                close(false)
                                                removeDataFromSessionStorage("create_booking")
                                            }
                                        }} />
                                    </div>
                                    <div id="step1" className="pt-2 pb-6 xs:w-[26rem] w-[320px] gap-4 flex flex-col overflow-y-auto max-h-[90vh] px-4 md:px-5">
                                        <div className='flex flex-col space-y-2 items-center'>
                                            <Image
                                                src={data?.restaurant?.images[0]?.url}
                                                showDummy={true}
                                                className="h-64 w-full rounded-lg"
                                                customLoaderClass={"h-64 w-full rounded-lg"}
                                                customLoaderHeight={'h-64'}
                                            />
                                            {data?.restaurant?.images?.length > 1 &&
                                                <div className='flex gap-2 items-center w-full'>
                                                    {data?.restaurant?.images?.slice(1, 5)?.map((itm, ind) => {
                                                        return (
                                                            <Image
                                                                src={itm?.url}
                                                                showDummy={true}
                                                                className="h-20 w-20 rounded-lg"
                                                                customLoaderClass={'h-20 w-20 rounded-lg'}
                                                                customHeight={"h-20"}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <div className="flex gap-3 items-center w-full">
                                                <Button
                                                    customPadding={'!p-2 gap-2'}
                                                    title={`ID-${data?.number}`}
                                                />
                                                <Button
                                                    customPadding={'!p-2 gap-2'}
                                                    onClick={() => {
                                                        if (defaultRoute == '/concierge/dashboard') {
                                                            navigate(`/concierge/restaurant-menu/${data?.restaurant?.id}`)
                                                        } else if (defaultRoute == '/admin/dashboard') {
                                                            navigate(`/admin/restaurant-menu/${data?.restaurant?.id}`)
                                                        } else {
                                                            navigate(`/restaurant/menu`)
                                                        }
                                                    }}
                                                >
                                                    <div className='flex gap-2 items-center'>
                                                        <Svgs.MenuIcon fill={'var(--primary-color)'} />
                                                        Menu
                                                    </div>
                                                </Button>
                                            </div>
                                            <div className={`border min-w-fit rounded-full capitalize px-4 py-2 text-xs border-transparent font-bold flex justify-center items-center gap-1 ${(status == 'No show' || status == 'Cancelled') ? "text-error border-error" : "text-secondary border-secondary"}`}>
                                                {status}
                                            </div>
                                        </div>

                                        {/* Restaurant Detail */}
                                        <div>
                                            {(defaultRoute == '/concierge/dashboard' || defaultRoute == '/admin/dashboard') &&
                                                <h4 className="text-2xl md:text-3xl font-semibold text-white">
                                                    {data?.restaurant?.name}
                                                </h4>
                                            }
                                            {status?.toLowerCase() === 'billed' &&
                                                <div className={`flex w-fit my-3 text-sm gap-1 p-2 rounded-md shadow bg-lightGrey justify-between`}>
                                                    <p className='text-secondary'>Bill Amount - <span>{data?.amount} {currency}</span></p>
                                                </div>
                                            }
                                        </div>
                                        <div className="pt-2">
                                            <ul className="space-y-2">
                                                <li className="flex items-center gap-2 text-xs text-blueGrey">
                                                    <div className='flex justify-center  w-7'><Svgs.ClockIcon stroke={'var(--secondary-color)'} /></div>
                                                    {/* <p className='flex gap-1'>
                                                            {moment(data?.date)?.format('DD-MM-YYYY')}
                                                            <span>●</span>
                                                            {data?.time}
                                                        </p> */}
                                                    <p className='flex gap-1'>
                                                        {moment(data?.date_time)?.format('hh:mm A • DD-MM-YYYY')}
                                                    </p>
                                                </li>
                                                {(defaultRoute == '/concierge/dashboard' || defaultRoute == '/admin/dashboard') &&
                                                    <>
                                                        <li className="flex items-center gap-2 text-xs text-blueGrey">
                                                            <div className='flex justify-center  w-7'><Svgs.PhoneIcon stroke={'var(--secondary-color)'} /></div>
                                                            <p>+{data?.restaurant?.phone_number && data?.restaurant?.phone_number != 'null' ? data?.restaurant?.phone_code : data?.restaurant?.mobile_code} {data?.restaurant?.phone_number && data?.restaurant?.phone_number != 'null' ? data?.restaurant?.phone_number : data?.restaurant?.mobile_number}</p>
                                                        </li>
                                                        <li className="flex items-center gap-2 text-xs text-blueGrey">
                                                            <div className='flex justify-center  w-7'><Svgs.LocationIcon stroke={'var(--secondary-color)'} /></div>
                                                            <p> {data?.restaurant?.address}</p>
                                                        </li>
                                                        {data?.restaurant?.user?.email &&
                                                            <li className="flex items-center gap-2 text-xs text-blueGrey">
                                                                <div className='flex justify-center  w-7'><Svgs.EmailIcon width={14} height={18} fill={'var(--secondary-color)'} /></div>
                                                                <p> {data?.restaurant?.user?.email}</p>
                                                            </li>
                                                        }
                                                    </>
                                                }
                                            </ul>
                                        </div>

                                        {/* Booking Persion Detail */}
                                        <div className="grid grid-cols-2 gap-5 text-sm">
                                            {data?.user?.first_name &&
                                                <div>
                                                    <p className="text-white">Booked By:</p>
                                                    <p className='text-blueGrey'><b>{defaultRoute == '/concierge/dashboard' ?
                                                        data?.member ? data?.member?.name :
                                                            `${data?.user?.first_name ? data?.user?.first_name : ""} ${data?.user?.last_name ? data?.user?.last_name : ""}`
                                                        : `${data?.user?.first_name ? data?.user?.first_name : ""} ${data?.user?.last_name ? data?.user?.last_name : ""}`} </b></p>
                                                </div>
                                            }
                                            <div>
                                                <p className="text-white">Business Name</p>
                                                <p className='text-blueGrey'><b>{data?.user?.business_name}</b></p>
                                            </div>
                                            <div>
                                                <p className="text-white">Guest Name:</p>
                                                <p className='text-blueGrey'><b>{data?.guest_name}</b></p>
                                            </div>
                                            <div>
                                                <p className="text-white">Guest Email:</p>
                                                <p className='text-blueGrey break-words break-all'><b>{data?.guest_email ? data?.guest_email : "----"}</b></p>
                                            </div>
                                            <div>
                                                <p className="text-white">Total Guest:</p>
                                                <p className='text-blueGrey'><b>{data?.total_pax}</b></p>
                                            </div>
                                            <div>
                                                <p className="text-white">Created At:</p>
                                                <p className='text-blueGrey'><b>{data?.created_at ? moment(data?.created_at).format('hh:mm A • DD-MM-YYYY') : "----"}</b></p>
                                            </div>
                                            <div>
                                                <p className="text-white">Confirmed At:</p>
                                                <p className='text-blueGrey'><b>{data?.confirmed_at ? moment(data?.confirmed_at).format('hh:mm A • DD-MM-YYYY') : "----"}</b></p>
                                            </div>
                                            {data?.occasion &&
                                                <div>
                                                    <p className="text-white">Celebrating any occasion</p>
                                                    <p className='text-blueGrey'><b>{data?.occasion}</b></p>
                                                </div>
                                            }
                                            {data?.guest_phone_number &&
                                                <div>
                                                    <p className="text-white">Guest Phone Number</p>
                                                    <p className='text-blueGrey'><b>+{data?.guest_phone_code} {data?.guest_phone_number}</b></p>
                                                </div>
                                            }
                                            {data?.allergies &&
                                                <div>
                                                    <p className="text-white">Allergies</p>
                                                    <p className='text-blueGrey'><b>{data?.allergies}</b></p>
                                                </div>
                                            }
                                        </div>
                                        {(defaultRoute == '/concierge/dashboard' || status?.toLowerCase() === 'billed') && data?.transaction?.proof &&
                                            <div className="flex flex-col gap-3">
                                                <div className={`text-sm text-white flex items-center`}>
                                                    Customer bill copy
                                                </div>
                                                <ImageUploader value={data?.transaction?.proof} hideEdit />
                                                {/* <Image src={data?.transaction?.proof} className={'h-24 object-cover w-full'} /> */}
                                            </div>
                                        }

                                        {/* Note regarding Booking */}
                                        {data?.special_note &&
                                            <div className="text-sm">
                                                <p className="text-white">Special Notes</p>
                                                <p className='text-blueGrey'>{data?.special_note}</p>
                                            </div>
                                        }
                                        {data?.restaurant?.terms_and_conditions &&
                                            <div className="flex flex-col gap-3">
                                                <div className={`text-sm text-white flex items-center`}>
                                                    Booking Policy
                                                </div>
                                                <div className={`flex w-full text-sm gap-1 p-3 rounded-md shadow bg-darkGrey justify-between`}>
                                                    <p className='text-blueGrey'>{data?.restaurant?.terms_and_conditions}</p>
                                                </div>
                                            </div>
                                        }
                                        {defaultRoute == '/concierge/dashboard' && data?.customer_feedback &&
                                            <div className="flex flex-col gap-3">
                                                <div className={`text-sm text-white flex items-center`}>
                                                    Customer Feedback
                                                </div>
                                                <div className={`flex w-full text-sm gap-1 p-3 rounded-md shadow bg-darkGrey justify-between`}>
                                                    <p className='text-blueGrey'>{data?.customer_feedback}</p>
                                                </div>
                                            </div>
                                        }

                                        {/* Any modification Request */}
                                        {!billPopup && data?.modifications?.length > 0
                                            ? <div className="flex flex-col gap-3 mt-5">
                                                <p className='font-semibold text-white'>Modifications</p>
                                                {data?.modifications?.map(itm => {
                                                    return (
                                                        <div className={`flex w-full text-sm gap-1 p-3 rounded-md shadow bg-darkGrey justify-between`}>
                                                            <div className='flex items-center gap-3'>
                                                                <div className='cursor-pointer' onClick={() => {
                                                                    copyToClipBoard(`${itm?.restaurant_text}`)
                                                                }}>
                                                                    <Svgs.CopyIcon />
                                                                </div>
                                                                <p className='text-blueGrey pl-3 border-l border-gray-400'>{itm?.restaurant_text}</p>
                                                            </div>
                                                            <p className="text-white">{itm?.date ? moment(itm?.date).format('DD MMM YYYY') : ''}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            : ''}

                                        {/* Cancel Reason */}
                                        {!billPopup && data?.cancel_reason && <>
                                            <p className="text-white">Cancel Reason</p>
                                            <div className={`flex w-full text-sm gap-1 p-3 rounded-md shadow bg-darkGrey justify-between`}>
                                                <p className='text-blueGrey'>{data?.cancel_reason}</p>
                                            </div>
                                        </>}

                                        {/* Modificaiton/Cancel Request */}
                                        {requestPopup &&
                                            <div className="flex flex-col w-full gap-3">
                                                <p className='font-semibold text-white'>
                                                    {requestPopup == 'modify'
                                                        ? "Modification Request"
                                                        : "Cancel Request"
                                                    }
                                                </p>
                                                <Textarea
                                                    placeholder={requestPopup == 'modify'
                                                        ? 'Provide a brief description' : "Please enter a cancel reason"}
                                                    parent={'w-full'}
                                                    onChange={(e) => {
                                                        setErrors({ ...errors, message: '' })
                                                        setMessage(e?.target?.value)
                                                    }}
                                                    value={message}
                                                    error={errors?.message}
                                                />
                                                <div className="pt-2 grid grid-cols-1 gap-3">
                                                    {/* <Button
                                                        customThemeBtn={'view-edit-btn'}
                                                        onClick={() => {
                                                            setRequestPopup();
                                                            setMessage('')
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button> */}
                                                    <Button
                                                        loading={btnLoading}
                                                        onClick={() => {
                                                            const modify = {
                                                                reservation_id: data?.id,
                                                                restaurant_text: message,
                                                            }
                                                            const cancel = {
                                                                number: data?.number,
                                                                cancel_reason: message,
                                                            }

                                                            const payload = requestPopup == 'modify' ? modify : cancel
                                                            !message
                                                                ? setErrors({ ...errors, message: 'This field is required' })
                                                                : onReasonSubmit && onReasonSubmit(requestPopup, payload)
                                                        }}>
                                                        Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        }

                                        {/* Billing */}
                                        {billPopup &&
                                            <>
                                                <hr />
                                                <div className="flex flex-col w-full gap-3 bg-darkGrey px-2 py-4 rounded-md">
                                                    <h1 className='font-semibold text-white'>Total Bill Value</h1>
                                                    <p className='font-medium text-white'>Proceed to payment</p>
                                                    <div className="flex flex-col">
                                                        <FilePicker
                                                            error={errors?.proof}
                                                            value={billingDetail?.proof}
                                                            name={'proof'}
                                                            onChange={(e) => {
                                                                setErrors({ ...errors, proof: '' })
                                                                setBillingDetail({
                                                                    ...billingDetail,
                                                                    proof: e?.target?.files[0]
                                                                })
                                                            }}
                                                            title={'Customer bill copy'}
                                                            accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX"}
                                                            customClass={"attach-file-btn-signup"}
                                                            customValueClass={"border border-lightGrey"}
                                                        />
                                                    </div>

                                                    <FloatInput
                                                        title="Bill Amount"
                                                        leftIcon={currency}
                                                        iconStyle={"py-4"}
                                                        parentClass={'w-full'}
                                                        required={true}
                                                        type={'number'}
                                                        name={"amount"}
                                                        value={billingDetail?.amount}
                                                        error={errors?.amount}
                                                        onChange={(e) => {
                                                            setErrors({ ...errors, amount: '' })
                                                            setBillingDetail({
                                                                ...billingDetail,
                                                                amount: e?.target?.value
                                                            })
                                                        }}
                                                        placeholder={'Enter price'}
                                                    />
                                                    <div className="pt-2 grid grid-cols-2 gap-3">
                                                        <Button
                                                            customThemeBtn={'view-edit-btn'}
                                                            onClick={() => {
                                                                setBillPopup(false)
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            loading={btnLoading == 'billing'}
                                                            onClick={() => {
                                                                onReasonSubmit && onReasonSubmit('billing', {
                                                                    ...billingDetail,
                                                                    reservation_id: data?.id
                                                                })
                                                            }}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/* Only Show this to Restaurant */}
                                        {defaultRoute == '/admin/dashboard' ? "" : isRestaurant
                                            ? !requestPopup && !billPopup && <div className={`pt-2 grid ${status?.toLowerCase() === 'billed' ? "grid-cols-1" : "grid-cols-2"} gap-3`}>
                                                {/* Confirmed Status  */}
                                                {status?.toLowerCase() === 'cancelled' || status?.toLowerCase() === 'no show' || status?.toLowerCase() === 'commission paid'
                                                    ? ''
                                                    : status?.toLowerCase() === 'billed'
                                                        ? ''
                                                        : status?.toLowerCase() === 'confirmed'
                                                            ? <div className="pt-2 grid col-span-2 grid-cols-2 gap-3">
                                                                <Button
                                                                    loading={btnLoading == 'no_show'}
                                                                    customThemeBtn={'view-edit-btn'}
                                                                    onClick={() => {
                                                                        onReasonSubmit && onReasonSubmit('no_show', { number: data?.number })
                                                                    }}
                                                                >
                                                                    No Show
                                                                </Button>
                                                                <Button
                                                                    loading={btnLoading == 'confirm'}
                                                                    onClick={() => {
                                                                        const currentDate = new Date();
                                                                        const dataDate = new Date(data?.date);

                                                                        if (dataDate > currentDate) {
                                                                            toast.error("You cannot bill this reservation before Booking date", { toastId: 'toast' });
                                                                        } else {
                                                                            setBillPopup(true);
                                                                        }
                                                                    }}
                                                                >
                                                                    Proceed
                                                                </Button>
                                                            </div>
                                                            : <>
                                                                <div className="col-span-2">
                                                                    <Button
                                                                        customPadding={'!w-full px-5 py-3 '}
                                                                        // customThemeBtn={'modify-btn'}
                                                                        onClick={() => { setRequestPopup('modify') }}
                                                                    >
                                                                        Modification
                                                                    </Button>
                                                                </div>
                                                                <Button
                                                                    customThemeBtn={'view-edit-btn'}
                                                                    onClick={() => { setRequestPopup('cancel') }}
                                                                >
                                                                    Cancel Booking
                                                                </Button>
                                                                <Button
                                                                    loading={btnLoading == 'confirm'}
                                                                    onClick={() => {
                                                                        onReasonSubmit && onReasonSubmit('confirm', { number: data?.number })
                                                                    }}
                                                                >
                                                                    Confirm Booking
                                                                </Button>
                                                            </>
                                                }
                                            </div>
                                            // Button Behavious on its status
                                            : status?.toLowerCase() === 'cancelled' || status?.toLowerCase() === 'no show' || status?.toLowerCase() === 'commission paid'
                                                ? ""
                                                : status?.toLowerCase() === 'billed' || status?.toLowerCase() === 'confirmed'
                                                    ? defaultRoute == '/concierge/dashboard' ?
                                                        <div className="pt-2 grid grid-cols-1 gap-x-3 gap-y-6">
                                                            {/* <Button
                                                                customThemeBtn={'attach-file-btn'}
                                                                className={'border border-secondary'}
                                                                onClick={() => {
                                                                    copyToClipBoard(`${booking_review_url}${data?.number}`)
                                                                    // close && close(false)
                                                                }}
                                                            >
                                                                Copy Link
                                                            </Button> */}
                                                            <Button
                                                                customThemeBtn={'attach-file-btn'}
                                                                className={'border border-secondary'}
                                                                onClick={() => {
                                                                    sendMail && sendMail(true)
                                                                    // close && close(false)
                                                                }}
                                                            >
                                                                Send Email
                                                            </Button>
                                                            {/* <div className="col-span-2">
                                                                <Button
                                                                    className={'!w-full'}
                                                                    onClick={() => {
                                                                        window.open(`${booking_review_url}${data?.number}`, '_blank')
                                                                        close && close(false)
                                                                    }}
                                                                >
                                                                    Get Customer Feedback
                                                                </Button>
                                                            </div> */}
                                                        </div> : ""
                                                    : !requestPopup && <div className="pt-2 grid grid-cols-2 gap-3">
                                                        <Button
                                                            customThemeBtn={'view-edit-btn'}
                                                            onClick={() => {
                                                                // close && close(false)
                                                                setRequestPopup('cancel')
                                                            }}
                                                        >
                                                            Cancel Booking
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                onEditClick && onEditClick({
                                                                    ...data,
                                                                    edit_booking: true
                                                                })
                                                                close && close(false)
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ConfirmBooking