import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAdminCreateEditAnnouncement from './helper'
import Textarea from 'Components/Elements/TextArea'
import ReactDateTimePicker from 'Components/Elements/ReactDateTimePicker'
import moment from 'moment'
import RadioBtn from 'Components/Elements/RadioBtn'

const AdminCreateEditAnnouncement = ({ open, close, editData, successCallBack }) => {
    const { data, loader, updateOrAddAnnouncementData, handleChange, errors, setData, handleClose
    } = useAdminCreateEditAnnouncement({ editData, close, successCallBack })
    return (
        <Popup
            open={open}
            close={handleClose}
            heading={editData ? "Edit Staff" : "Add Staff"}
            customSizeStyle={'sm:w-[45vw] md:w-[50vw] lg:w-[50vw]'}
        >
            <div className="flex flex-col space-y-5">
                <LoginInput
                    required={true}
                    title={'Announcement Title'}
                    placeholder={'Enter Announcement Title'}
                    value={data?.name}
                    name={'name'}
                    onChange={handleChange}
                    error={errors?.name}
                />
                <Textarea
                    title={'Announcement Description'}
                    value={data?.details}
                    error={errors?.details}
                    name={'details'}
                    placeholder={"Enter Announcement Description"}
                    required={true}
                    onChange={handleChange}
                />
                <ReactDateTimePicker
                    title={'Announcement Date'}
                    required={true}
                    type="date"
                    name="start_date"
                    onChange={handleChange}
                    value={data?.start_date}
                    error={errors?.start_date}
                    min={moment().toDate()}
                    isClearable={true}
                    time={data?.time}
                />
                <div className="flex items-center gap-4">
                    <div className={`border ${data?.recipient == 'concierge' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => {
                        setData((prev) => ({
                            ...prev,
                            "recipient": "concierge"
                        }))
                    }}>
                        <RadioBtn name={'activeTab'} text={"Concierge"} checked={data?.recipient == 'concierge'} id={'concierge'} />
                    </div>
                    <div className={`border ${data?.recipient == 'restaurant' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => {
                        setData((prev) => ({
                            ...prev,
                            "recipient": "restaurant"
                        }))
                    }}>
                        <RadioBtn name={'activeTab'} text={"Business"} checked={data?.recipient == 'restaurant'} id={'restaurant'} />
                    </div>
                </div>
                <div className='pt-2'>
                    <Button
                        className={'w-full'}
                        title={editData ? "Save" : "Add"}
                        loading={loader}
                        disabled={loader}
                        onClick={updateOrAddAnnouncementData}
                    />
                </div>
            </div>
        </Popup>
    )
}

export default AdminCreateEditAnnouncement