import React from 'react';

const BusinessCategoryCardShimmer = () => {
    return (
        <div className="relative bg-darkGrey rounded-lg md:rounded-[10px] min-h-20 flex flex-col items-center justify-between p-5 text-center animate-pulse">
            <div className="absolute right-2 top-2 w-8 h-8 bg-gray-300 rounded-full"></div>
            <div className="w-16 h-16 bg-gray-300 rounded-full mx-auto mb-2"></div>
            <hr className="my-4 w-full bg-gray-300 h-1 rounded" />
            <div className="w-24 h-4 bg-gray-300 rounded mb-2"></div>
        </div>
    );
};

export default BusinessCategoryCardShimmer;
