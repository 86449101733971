import { getAdminSingleConciergeApiData, updateConciergeCommission, updateConciergeToogleStatus } from "Adapters/APIs/Admin/concierge";
import { updateRestaurantRejectReason } from "Adapters/APIs/Admin/dashboard";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const useAdminUnapprovedConcierge = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user_id } = useParams();

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const concierge = state?.adminConcierges?.singleConcierge

    // states
    const [loader, setLoader] = useState(true)
    const [toogleStatus, setToogleStatus] = useState(false)
    const [confirmToogle, setConfirmToogle] = useState(false)
    const [statusLoader, setStatusLoader] = useState(false)
    const [updateCommissionLoader, setUpdateCommissionLoader] = useState(false)
    const [commissionPercentage, setCommissionPercentage] = useState('')
    const [rejectPopup, setRejectPopup] = useState(false)
    const [rejectLoader, setRejectLoader] = useState(false)
    const [rejectReason, setRejectReason] = useState('')
    const [formData, setFormData] = useState()

    // get single concierge
    const getSingleConciergeData = () => {
        let payload = {
            user_id: user_id
        }
        setLoader(true)
        const success = () => {
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminSingleConciergeApiData(access_token, payload, success, fail))
    }

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target
        if (name == "allow_team") {
            setFormData((prev) => ({
                ...prev,
                [name]: e?.target?.checked ? 1 : 0
            }))
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        setErrors((prev) => ({
            ...prev,
            [name]: ""
        }))
    }

    // handle reject submission
    const handleRejectSubmission = () => {
        let payload = {
            user_id: concierge?.id,
            rejection_reason: rejectReason,
            type: 'concierge'
        }
        const success = () => {
            setLoader(true)
            setRejectLoader(false)
            setRejectPopup(false)
            setRejectReason("")
            getSingleConciergeData()
        }
        const fail = () => {
            setRejectLoader(false)
        }
        let requiredFields = { rejectReason: rejectReason }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setRejectLoader(true)
            dispatch(updateRestaurantRejectReason(access_token, payload, success, fail))
        }
    }

    // update concierge status
    const changeConciergeStatus = () => {
        setStatusLoader(true)
        let payload = {
            user_id: concierge?.id
        }
        const success = () => {
            setToogleStatus(!toogleStatus)
            setStatusLoader(false)
            setLoader(true)
            setConfirmToogle(false)
            getSingleConciergeData()
        }
        const fail = () => {
            setStatusLoader(false)
        }
        dispatch(updateConciergeToogleStatus(access_token, payload, success, fail))
    }

    // update concierge commission data
    const updateConciergeCommissionData = () => {
        let payload = {
            user_id: concierge?.id,
            commission_percentage: commissionPercentage,
            ...formData
        }
        const success = () => {
            setLoader(true)
            setUpdateCommissionLoader(false)
            navigate('/admin/dashboard')
        }
        const fail = () => {
            setUpdateCommissionLoader(false)
        }
        let requiredFields = { commissionPercentage: commissionPercentage }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setUpdateCommissionLoader(true)
            dispatch(updateConciergeCommission(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        setCommissionPercentage(Math.floor(concierge?.commission_percentage))
        setFormData({
            allow_team: concierge?.allow_team ? 1 : 0
        })
    }, [concierge])

    useEffect(() => {
        setToogleStatus(concierge?.is_active == 1 ? true : false)
    }, [concierge?.is_active])

    useEffect(() => {
        getSingleConciergeData()
    }, [])

    return {
        concierge, navigate, loader, toogleStatus, setToogleStatus, changeConciergeStatus, confirmToogle, setConfirmToogle, statusLoader,
        commissionPercentage, setCommissionPercentage, updateConciergeCommissionData, updateCommissionLoader, errors, setErrors,
        rejectPopup, setRejectPopup, rejectLoader, setRejectLoader, rejectReason, setRejectReason, handleRejectSubmission, formData,
        handleChange
    }
}

export default useAdminUnapprovedConcierge