import { getAdminnnouncement } from 'Adapters/APIs/Announcement'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useAnnouncementHelper = () => {
    const dispatch = useDispatch()

    // redux states
    const state = useSelector(state => state)
    const announcement = state?.announcement?.announcements
    const pagination = state?.announcement?.announcement_pagination

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    // states
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [secondLoader, setSecondLoader] = useState(false)
    const [addEditAnnouncement, setAddEditAnnouncement] = useState(false)
    const [editDetail, setEditDetail] = useState(false)

    // get admin announcement data
    const getAdminAnnouncementData = () => {
        const success = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        if (currentPage == 1) {
            setLoading(true)
        } else {
            setSecondLoader(true)
        }
        dispatch(getAdminnnouncement(access_token, { page: currentPage }, success, fail))
    }

    // handle successcallback
    const successCallback = () => {
        setCurrentPage(1)
        setLoading(true)
        getAdminAnnouncementData()
    }

    useEffect(() => {
        getAdminAnnouncementData()
    }, [currentPage])

    return {
        announcement, pagination, loading, currentPage, setCurrentPage, secondLoader, addEditAnnouncement, setAddEditAnnouncement,
        successCallback, editDetail, setEditDetail
    }
}

export default useAnnouncementHelper