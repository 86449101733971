import Button from 'Components/Elements/Button'
import ImageUploader from 'Components/Elements/ImageUploader'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import ReactMonthPicker from 'Components/Elements/ReactMonthPicker'
import ToggleWithText from 'Components/Elements/ToggleWithText'
import React from 'react'
import useAdminRewardAddUpdate from './helper'
import Dropdown from 'Components/Elements/Dropdown'

const CreateEditReward = ({ open, close, editData, successCallBack }) => {
    const { rewardData, loader, updateOrAddRewardData, handleChange, setRewardData, errors
    } = useAdminRewardAddUpdate({ editData, close, successCallBack })
    return (
        <Popup
            open={open}
            close={close}
            heading={editData ? "Edit Reward" : "Add reward"}
            customSizeStyle={'sm:w-[50vw] lg:w-[40vw]'}
        >
            <div className="flex flex-col space-y-5">
                <ImageUploader
                    title={'Upload your reward image'}
                    description={<div>
                        <div className='text-secondary text-lg'>Browse</div>
                    </div>}
                    value={rewardData?.reward}
                    accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX"}
                    multiple={false}
                    id={'CreateEditReward'}
                    mb_file_size={8}
                    onChange={(e) => { handleChange(e) }}
                    error={errors?.reward}
                    name={'reward'}
                />
                <ReactMonthPicker
                    title={'Select Month'}
                    placeholder={'Select month'}
                    required={true}
                    name={'date'}
                    value={rewardData?.date}
                    onChange={handleChange}
                    error={errors?.date}
                />
                <Dropdown
                    title={'Evaluation Criteria'}
                    name={'type'}
                    required
                    placeholder={'Select evaluation criteria'}
                    onChange={handleChange}
                    value={rewardData?.type}
                    option={[
                        { label: "Booking Count", value: "booking_count" },
                        { label: "Booking Revenue", value: "booking_revenue" }
                    ]}
                    error={errors?.type}
                />
                <LoginInput
                    required={true}
                    title={'Booking Threshold'}
                    placeholder={'Enter booking threshold'}
                    onlyNumericAllowed={true}
                    value={rewardData?.booking_threshold}
                    name={'booking_threshold'}
                    onChange={handleChange}
                    error={errors?.booking_threshold}
                    type={"number"}
                />
                <LoginInput
                    required={true}
                    title={'Prize'}
                    placeholder={'Enter prize'}
                    value={rewardData?.prize}
                    name={'prize'}
                    onChange={handleChange}
                    error={errors?.prize}
                />
                <div className={`border border-lightGrey rounded-lg flex gap-2 items-center justify-between p-3`}>
                    <h1 className='font-medium text-white'>Active</h1>
                    <ToggleWithText
                        checked={rewardData?.status}
                        onChange={() => {
                            setRewardData((prev) => ({
                                ...prev,
                                status: !prev?.status
                            }))
                        }}
                    />
                </div>
                <Button
                    title={editData ? "Save" : "Add"}
                    loading={loader}
                    disabled={loader}
                    onClick={updateOrAddRewardData}
                />
            </div>
        </Popup>
    )
}

export default CreateEditReward