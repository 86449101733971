import { getAdminSingleRestaurantBookingsData } from "Adapters/APIs/Admin/restaurant";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

let initialStatsData = {
    total_bookings: "",
    our_commission: ""
}
const useSingleRestaurantBookings = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { restuarant_id } = useParams();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [activeTab, setActiveTab] = useState('AllBookings')
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [bookingData, setBookingData] = useState([])
    const [paginationData, setPaginationData] = useState({})
    const [bookingStats, setBookingStats] = useState(initialStatsData)
    const [status, setStatus] = useState('')
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [bookingDetail, setBookingDetail] = useState(false)

    // get restaurant bookings
    const getRestaurantBookings = () => {
        let payload = {
            type: activeTab == 'AllBookings' ? "" : "upcoming",
            restaurant_id: restuarant_id,
            page: currentPage,
            per_page: 10,
            status: status,
            start_date: startDate,
            end_date: endDate
        }
        setLoader(true)
        const success = (res) => {
            setBookingStats({
                total_bookings: res?.total_bookings,
                our_commission: res?.our_commission,
                name: res?.name
            })
            setPaginationData(res?.pagination_data?.meta)
            setBookingData(res?.reservations)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminSingleRestaurantBookingsData(access_token, payload, success, fail))
    }

    useEffect(() => {
        setLoader(true)
        getRestaurantBookings()
    }, [currentPage, activeTab, status, startDate, endDate])

    useEffect(() => {
        setStartDate()
        setEndDate()
    }, [activeTab])

    useEffect(() => {
        setCurrentPage(1)
    }, [status, startDate, endDate])

    return {
        navigate, bookingData, paginationData, loader, currentPage, setCurrentPage, activeTab, setActiveTab, bookingStats, status, setStatus,
        startDate, setStartDate, endDate, setEndDate, bookingDetail, setBookingDetail
    }
}

export default useSingleRestaurantBookings