import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { error_message } from 'Constants/Functions/Variables'
import Svgs from 'Assets/svgs';
import moment from 'moment';

const ReactMonthPicker = ({ required, className, title, name, placeholder, value, min, max, onChange, onBlur, error, isClearable }) => {
    const [selectDate, setSelectDate] = useState(null);
    const handleDateChange = (value) => {
        setSelectDate(value)
        // Get the start date of the month
        let startDate = value ? moment(value).clone().startOf('month').format('YYYY-MM-DD') : "";
        // Get the end date of the month
        let endDate = value ? moment(value).clone().endOf('month').format('YYYY-MM-DD') : "";

        let event = {
            target: {
                name: name,
                value: value,
                startDate: startDate,
                endDate: endDate
            }
        }
        onChange && onChange(event)

    }

    useEffect(() => {
        if (value) {
            setSelectDate(new Date(value))
        } else
            setSelectDate()

    }, [value])
    return (
        <div className={`${className} flex flex-col gap-2`}>
            {title ? (
                <div className="text-sm flex items-center gap-1">
                    <div className='text-[#808080]'>{title}</div> {required && title && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            ) : ""}
            <div className="date-picker-container relative z-[4443]">
                <DatePicker
                    selected={selectDate}
                    name={name}
                    value={selectDate}
                    onChange={handleDateChange}
                    onBlur={onBlur}
                    primaryColor="var(--primary-color)"
                    minDate={min}
                    maxDate={max}
                    isClearable={isClearable}
                    icon="fa fa-calendar"
                    className={`${error ? "border-error" : "border-lightGrey"} cursor-pointer px-4 py-2 min-h-10 rounded-[10px] w-full border bg-darkGrey text-base text-blueGrey focus:outline-none custom-datepicker input`}
                    placeholderText={placeholder ? placeholder : "Select Month"}
                    dateFormat='MM/yyyy'
                    showMonthYearPicker
                    shouldCloseOnScroll={true}
                    portalId="root" // add to show complete calendar without cuting in popup if less height
                    popperClassName="high-zindex-datepicker" // add to show complete calendar without cuting in popup if less height
                />
                {error && typeof error == "string" ? (
                    <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" />
                        {error}
                    </p>
                ) : typeof error == "boolean" && error == true ? (
                    <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" /> {error_message}
                    </p>
                ) : (
                    ""
                )}
            </div>
        </div>
    )
}

ReactMonthPicker.defaultProps = {
    isClearable: false
}

export default ReactMonthPicker