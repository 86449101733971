import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import React from 'react'

const ReferralCard = ({ onCardClick, imgSrc, name, earning, email, referralsCount }) => {
    return (
        <div className={`flex w-full sm:rounded-[20px] rounded-lg p-4 gap-4 justify-between bg-darkGrey ${onCardClick && 'cursor-pointer'}`}
            onClick={onCardClick && onCardClick}>
            <div className='flex gap-2 items-center'>
                {imgSrc ?
                    <div className="flex items-center justify-center h-[3rem] w-[3rem] overflow-hidden rounded-full bg-gray-200 ">
                        <Image src={imgSrc} className="w-full h-full object-cover" />
                    </div>
                    :
                    <Svgs.UserIcon2 width={'48'} height={'48'} />}
            </div>
            <div className="flex flex-1 flex-col space-y-2">
                <div className='flex gap-2 items-center justify-between'>
                    <h1 className='font-semibold text-white'>{name}</h1>
                    <div className='placed px-2 py-1 rounded-lg text-sm'>
                        {earning} {currency}
                    </div>
                </div>
                <div className='flex gap-1 items-center text-blueGrey break-words break-all text-sm'>
                    <Svgs.MailIcon stroke={'var(--secondary-color)'} />
                    {email}
                </div>
                <div className='flex gap-1 items-center text-blueGrey break-words break-all text-sm'>
                    <Svgs.InviteFriend fill={'var(--secondary-color)'} />
                    {referralsCount} {referralsCount?.length > 1 ? "Invitations" : "Invitation"}
                </div>
            </div>
        </div>
    )
}

export default ReferralCard
