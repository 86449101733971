import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import moment from 'moment'
import React from 'react'

const ReferralCard = ({ onCardClick, date, name, earning, approved }) => {
    return (
        <div className={`flex w-full sm:rounded-[20px] rounded-lg p-4 gap-4 justify-between bg-darkGrey ${onCardClick && 'cursor-pointer'}`}
            onClick={onCardClick && onCardClick}>
            <div className='flex gap-2 items-center'>
                <Svgs.UserIcon2 width={'48'} height={'48'} />
            </div>
            <div className="flex flex-1 flex-col space-y-2">
                <div className='flex gap-2 items-center justify-between'>
                    <h1 className='font-semibold text-white'>{name}</h1>
                    <div className='placed px-2 py-1 rounded-lg text-sm'>
                        {earning} {currency}
                    </div>
                </div>
                <div className='flex gap-1 items-center text-blueGrey break-words break-all text-sm'>
                    <Svgs.CalendarV3 stroke={'var(--secondary-color)'} />
                    {moment(date).format("DD-MM-YYYY")}
                </div>
                <hr className='my-2' />
                <div className='flex justify-end'>
                    <button className={`${approved ? 'approve' : 'not-approved'} flex items-center gap-1 px-4 py-1.5 rounded-lg `}>
                        {
                            approved
                                ? <>
                                    <Svgs.Tick stroke={'white'} />
                                    Approved
                                </>
                                : <>
                                    Not Approved
                                </>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ReferralCard
