import { add_announcement, BASE_URL, get_announcements, update_announcement } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getConceirgeAnnouncementData, getRestaurantAnnouncementData } from "../../Redux/Announcement/Actions";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";

// get restaurant announcement
export const getRestaurantAnnouncement = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_announcements}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getRestaurantAnnouncementData(data))
        }
    } catch (error) {
        fail && fail()
        dispatch(getRestaurantAnnouncementData({
            announcements: [],
            pagination_data: {}
        }))
        return error;
    }
}

// get concierge announcement
export const getConciergeAnnouncement = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_announcements}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getConceirgeAnnouncementData(data))
        }
    } catch (error) {
        fail && fail()
        dispatch(getConceirgeAnnouncementData({
            announcements: [],
            pagination_data: {}
        }))
        return error;
    }
}

// get admin announcement
export const getAdminnnouncement = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_announcements}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getConceirgeAnnouncementData(data))
        }
    } catch (error) {
        fail && fail()
        dispatch(getConceirgeAnnouncementData({
            announcements: [],
            pagination_data: {}
        }))
        return error;
    }
}

// update or add new announcement
export const updateOrAddAdminAnnouncement = (access_token, payload, success, fail) => async (dispatch) => {
    const formData = convertDataToFormData(payload)
    let endUrl = payload?.id ? update_announcement : add_announcement
    let editId = payload?.id ? payload?.id : ""
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${endUrl}${editId}`, formData, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            toast.error(error?.response?.data?.message, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}