import { getAdminThreshold, updateAdminSettings } from "Adapters/APIs/Admin/settings";
import { getAllAvailableSlots } from "Adapters/APIs/Restaurant/ProfileBoosting";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useAdminSlotsManagementHelper = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const userData = getDataFromLocalStorage('user_data')

    const { setErrors, errors, validation } = useValidations()


    // states
    const [loader, setLoader] = useState(true)
    const [slotsData, setSlotsData] = useState([])
    const [slotDetail, setSlotDetail] = useState()
    const [addEditSlot, setAddEditSlot] = useState(false)
    const [universalSetting, setUnviversalSetting] = useState({
        profile_boosting_week_end_fee: "",
        profile_boosting_week_day_fee: ""
    })
    const [adminSlotSetting, setAdminSlotSetting] = useState(false)
    const [adminSlotLoader, setAdminSlotLoader] = useState(false)

    // get slots data
    const getSlotsData = () => {
        let payload = {}
        setLoader(true)
        const success = (data) => {
            setSlotsData(data)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAllAvailableSlots(access_token, payload, success, fail))
    }

    // handle onChange function
    const handleChangeSlotSetting = (event) => {
        const { name, value } = event.target
        setUnviversalSetting((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({ ...prev, [name]: '' }));
    }

    // get universal slot settings data
    const getUniversalSLotSettingsData = () => {
        if (userData?.role == 'Super Admin') {
            const success = (res) => {
                let data = res?.versions
                setUnviversalSetting({
                    profile_boosting_week_end_fee: data?.profile_boosting_week_end_fee,
                    profile_boosting_week_day_fee: data?.profile_boosting_week_day_fee
                })
            }
            const fail = () => {
            }
            dispatch(getAdminThreshold(access_token, success, fail))
        }
    }


    // update admin slot settings
    const updateAdminSettingsData = () => {
        const success = () => {
            setAdminSlotSetting(false);
            setAdminSlotLoader(false);
            setAdminSlotSetting(false);
            getUniversalSLotSettingsData();
        };
        const fail = () => {
            setAdminSlotLoader(false);
        };
        let requiredData = { ...universalSetting }
        let payload = { ...universalSetting }
        setErrors(validation(requiredData));
        let result = validation(requiredData);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setAdminSlotLoader(true);
            dispatch(updateAdminSettings(access_token, payload, success, fail));
        }
    };


    useEffect(() => {
        getSlotsData()
        getUniversalSLotSettingsData()
    }, [])


    return {
        slotsData, loader, slotDetail, setSlotDetail, addEditSlot, setAddEditSlot, errors, universalSetting, adminSlotLoader,
        adminSlotSetting, setAdminSlotSetting, updateAdminSettingsData, handleChangeSlotSetting, getSlotsData
    }
}

export default useAdminSlotsManagementHelper