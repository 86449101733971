import React from 'react'
import Popup from 'Components/Elements/Popup'
import FloatInput from 'Components/Elements/FloatInput'
import { currency } from 'Adapters/variables'
import Button from 'Components/Elements/Button'

const UniversalSlotSettingPopup = ({ open, close, universalSetting, handleChangeSlotSetting, errors, updateAdminSettingsData,
    loading
}) => {
    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={"Universal Slot Setting"}
        >
            <div className='my-2'>
                <div className='flex flex-col space-y-2 mb-6'>
                    <FloatInput
                        title="Profile Boosting Week Day Fees"
                        leftIcon={currency}
                        parentClass={'w-full'}
                        value={universalSetting?.profile_boosting_week_day_fee}
                        name={"profile_boosting_week_day_fee"}
                        onChange={handleChangeSlotSetting}
                        placeholder={'Enter Profile Boosting Week Day Fees'}
                        error={errors?.profile_boosting_week_day_fee}
                        inputClass={'bg-lightGrey'}
                        iconStyle={'border-blueGrey min-w-16'}
                    />
                    <FloatInput
                        title="Profile Boosting Weekend Fees"
                        leftIcon={currency}
                        parentClass={'w-full'}
                        value={universalSetting?.profile_boosting_week_end_fee}
                        name={"profile_boosting_week_end_fee"}
                        onChange={handleChangeSlotSetting}
                        placeholder={'Enter Profile Boosting Weekend Fees'}
                        error={errors?.profile_boosting_week_end_fee}
                        inputClass={'bg-lightGrey'}
                        iconStyle={'border-blueGrey min-w-16'}
                    />
                </div>
                <Button text={'Save'} className={'w-full'} onClick={updateAdminSettingsData} loading={loading} disabled={loading} />
            </div>
        </Popup>
    )
}

export default UniversalSlotSettingPopup
