import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAdminCreateEditCategory from './helper'
import Textarea from 'Components/Elements/TextArea'
import RadioBtn from 'Components/Elements/RadioBtn'
import ImageUploader from 'Components/Elements/ImageUploader'
import StatusInput from 'Components/Elements/StatusInput'

const AdminCreateEditCategory = ({ open, close, editData, successCallBack }) => {
    const { data, loader, updateOrAddStaffData, handleChange, errors, handleClick, handleClose
    } = useAdminCreateEditCategory({ editData, close, successCallBack })
    return (
        <Popup
            open={open}
            close={handleClose}
            heading={editData ? "Edit Category" : "Add Category"}
            customSizeStyle={'sm:w-[40vw] md:w-[50vw] lg:w-[40vw]'}
        >
            <div className="flex flex-col space-y-5">
                <LoginInput
                    required={true}
                    title={'Name'}
                    placeholder={'Enter Name'}
                    value={data?.name}
                    name={'name'}
                    onChange={handleChange}
                    error={errors?.name}
                />
                <Textarea
                    title={'Description'}
                    value={data?.description}
                    name={'description'}
                    placeholder={"Enter Description"}
                    required={false}
                    onChange={handleChange}
                    noLimit
                />

                {editData?.id &&
                    <StatusInput
                        title={"Status"}
                        required
                        name={"status"}
                        value={data?.status}
                        onChange={handleChange}
                    />
                }

                <div className="flex items-center gap-4 text-sm text-blueGrey justify-between">
                    <h2>Sub Category Required:</h2>
                    <div className="flex items-center gap-4">
                        <RadioBtn name={'is_sub_category_required'} text={"Yes"} checked={data?.is_sub_category_required === 1} id={'Yes'} onClick={() => { handleClick(1) }} />
                        <RadioBtn name={'is_sub_category_required'} text={"No"} checked={data?.is_sub_category_required === 0} id={'No'} onClick={() => { handleClick(0) }} />
                    </div>
                </div>

                <ImageUploader
                    title={'Upload Category Icon'}
                    value={data?.icon}
                    accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                    multiple={false}
                    id={'icon'}
                    mb_file_size={8}
                    onChange={(e) => { handleChange(e) }}
                    error={errors?.icon}
                    name={'icon'}
                    hideRemove
                />

                <div className='pt-2'>
                    <Button
                        className={'w-full'}
                        title={editData ? "Save" : "Add"}
                        loading={loader}
                        disabled={loader}
                        onClick={updateOrAddStaffData}
                    />
                </div>
            </div>
        </Popup>
    )
}

export default AdminCreateEditCategory