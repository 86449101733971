import { updateOrAddSlot } from "Adapters/APIs/Admin/slotsManagement";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

let initialData = {
    name: "",
    weekend_price: "",
    weekday_price: "",
    status: 1
}
const useAdminCreateEditSlotHelper = ({ editData, close, successCallBack, setEditData }) => {
    const dispatch = useDispatch()

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState(initialData)

    // handle close popup
    const handleClosePopup = () => {
        setData(initialData)
        setErrors()
        close && close(false)
        setEditData && setEditData()
    }

    // handle onChange function
    const handleChange = (event) => {
        const { name, value } = event.target
        if (name == 'status') {
            setData({
                ...data,
                [name]: data?.status === 1 ? 0 : 1
            })
        }
        if (name != 'status') {
            setData((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        setErrors((prev) => ({ ...prev, [name]: '' }));
    }


    // update or add slot data
    const updateOrAddSlotData = () => {
        let payload = {
            id: editData?.id,
            name: data?.name,
            weekend_price: data?.weekend_price,
            weekday_price: data?.weekday_price,
        }
        const success = () => {
            setLoader(false)
            successCallBack && successCallBack()
            handleClosePopup()
        }
        const fail = () => {
            setLoader(false)
        }
        let requiredData = {
            name: data?.name,
            weekend_price: data?.weekend_price,
            weekday_price: data?.weekday_price,
        }
        if (editData?.id) {
            payload = {
                ...payload,
                status: data?.status
            }
        }
        setErrors(validation(requiredData));
        let result = validation(requiredData);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoader(true)
            dispatch(updateOrAddSlot(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        if (editData) {
            setData({
                name: editData?.name,
                weekend_price: editData?.weekend_price,
                weekday_price: editData?.weekday_price,
                status: editData?.status
            })
        }
    }, [editData])

    return {
        data, loader, updateOrAddSlotData, handleChange, setData, errors, handleClosePopup
    }
}

export default useAdminCreateEditSlotHelper