import { updatePendingPromotionsStatusStatus } from "Adapters/APIs/Admin/promotionrequest";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useState } from "react";
import { useDispatch } from "react-redux";

const useDetailPromotionRequest = ({ data, successCallback, close }) => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [updateLoader, setUpdateLoader] = useState(false)

    // update pending promotion request
    const updatePendingPromotionRequestStatus = () => {
        let payload = {
            slot_booking_id: data?.id
        }
        const success = () => {
            setUpdateLoader(false)
            close && close()
            successCallback && successCallback()
        }
        const fail = () => {
            setUpdateLoader(false)
        }
        setUpdateLoader(true)
        dispatch(updatePendingPromotionsStatusStatus(access_token, payload, success, fail))
    }

    return {
        updateLoader, updatePendingPromotionRequestStatus
    }
}

export default useDetailPromotionRequest