import { getAdminPurchasedPackages } from "Adapters/APIs/Admin/promotionrequest";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useAdminPromotionRequest = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [activeTab, setActiveTab] = useState('approved')
    const [currentPage, setCurrentPage] = useState(1)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [paginationData, setPaginationData] = useState({})
    const [data, setData] = useState([])
    const [dataDetail, setDataDetail] = useState()
    const [totalearning, setTotalEarning] = useState()

    // get admin approved/unapproved promotions/packages 
    const getAdminApprovedUnapprovedPackages = () => {
        let payload = {
            page: currentPage,
            status: activeTab,
            start_date: startDate,
            end_date: endDate,
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        const success = (res) => {
            let updatedData = []
            if (res?.slot_bookings?.length == 0) {
                updatedData = []
            } else {
                if (res?.pagination_data?.meta?.current_page !== 1) {
                    updatedData = [...data || [], ...res?.slot_bookings];
                } else {
                    updatedData = res?.slot_bookings;
                }
            }
            setTotalEarning(res?.total_earned)
            setData(updatedData)
            setPaginationData(res?.pagination_data?.meta)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getAdminPurchasedPackages(access_token, payload, success, fail))
    }

    // call data for first time
    const callDataForFirstTime = () => {
        setCurrentPage(1)
        setActiveTab('approved')
        setStartDate()
        setEndDate()
        getAdminApprovedUnapprovedPackages()
    }

    useEffect(() => {
        getAdminApprovedUnapprovedPackages()
    }, [currentPage, activeTab, startDate, endDate])

    useEffect(() => {
        setLoader(true)
        setCurrentPage(1)
    }, [activeTab, startDate, endDate])

    return {
        data, navigate, paginationData, loader, secondLoader, currentPage, setCurrentPage, startDate, setStartDate, endDate, setEndDate,
        activeTab, setActiveTab, dataDetail, setDataDetail, totalearning, callDataForFirstTime
    }
}

export default useAdminPromotionRequest