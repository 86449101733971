import { getAdminDashboardDataAPI, getConciergeApiData, getRestaurantApiData } from "Adapters/APIs/Admin/dashboard";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useAdminDashboard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    const state = useSelector(state => state)
    const DasboardStats = state?.adminDashboard?.dashbaord_data
    const allRestaurants = state?.adminDashboard?.restaurants
    const allConcierges = state?.adminDashboard?.concierges

    // states
    const [cardDataLoader, setCardLoader] = useState(true)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [statisticsDetail, setStatisticsDetail] = useState("")
    const [restaurantLoader, setRestaurantLoader] = useState(true)
    const [conciergeLoader, setConciergeLoader] = useState(true)

    // get dashboard cards data
    const dashboardCardData = () => {
        let payload = {
            start_date: startDate ? startDate : "",
            end_date: endDate ? endDate : ""
        }
        setCardLoader(true)
        const success = () => {
            setCardLoader(false)
        }
        const fail = () => {
            setCardLoader(false)
        }
        dispatch(getAdminDashboardDataAPI(access_token, payload, success, fail))
    }

    useEffect(() => {
        dashboardCardData()
    }, [startDate, endDate])

    useEffect(() => {
        dispatch(getRestaurantApiData(access_token, setRestaurantLoader))
        dispatch(getConciergeApiData(access_token, setConciergeLoader))
    }, [])

    return {
        DasboardStats, cardDataLoader, startDate, setStartDate, endDate, setEndDate, statisticsDetail, setStatisticsDetail, allRestaurants,
        navigate, restaurantLoader, allConcierges, conciergeLoader, user_data
    }
}

export default useAdminDashboard