import { BASE_URL, get_admin_threshold, update_admin_settings, update_admin_threshold } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import axios from "axios";
import { toast } from "react-toastify";

// get admin threshold
export const getAdminThreshold = (access_token, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_admin_threshold}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// update admin threshold
export const updateAdminThreshold = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_admin_threshold}`, payload, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// update admin settings
export const updateAdminSettings = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_admin_settings}`, payload, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}