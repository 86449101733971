import { getDropdownConciergesAPI, getDropdownRestaurantAPI } from "Adapters/APIs/Admin/dashboard";
import { getAdminConciergePaymentsAndEarnings, getAdminConciergePaymentsAndEarningsCSVData } from "Adapters/APIs/Admin/transaction&payments";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

let initialFilterState = {
    searchText: "",
    dropdownValue: "",
    start_date: "",
    end_date: "",
    direction: "desc",
    filter: ""
}
const useAdminTransactionPayments = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const prevFilterDataRef = useRef(initialFilterState);

    const state = useSelector(state => state)
    const dropdown_concierges = state?.adminDashboard?.dropdown_concierges
    const dropdown_restaurants = state?.adminDashboard?.dropdown_restaurants

    // states
    const [loader, setLoader] = useState(true)
    const [activeTab, setActiveTab] = useState('conciergesPayment')
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [data, setData] = useState([])
    const [transactionsData, setTransactionsData] = useState()
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [selectedItmDetail, setSelectedItemDetail] = useState(false)
    const [addFilter, setAddFilter] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterUpdate, setFilterUpdate] = useState(false)
    const [downloadCSV, setDownloadCSV] = useState(false)
    const [downloadCSVData, setDownloadCSVData] = useState({
        month: "",
        year: new Date().getFullYear().toString()
    })
    const [CSVLoader, setCSVLoader] = useState(false)
    const [typeFiltered, setTypeFiltered] = useState("")

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // on clear filter
    const onClearFilter = () => {
        setAddFilter(false)
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            setActiveTab('')
            getAdminConciergePaymentsAndEarningsData(true)
            setFilterData(initialFilterState)
        }
    }

    // on apply filter
    const onApplyFilter = () => {
        setAddFilter(false)
        if (filterUpdate) {
            getAdminConciergePaymentsAndEarningsData()
        }
    }

    // get admin concierge payments and admin earnings
    const getAdminConciergePaymentsAndEarningsData = (noFilter) => {
        let payload = {
            page: currentPage,
            per_page: 10,
            type: activeTab
        }
        if (!noFilter) {
            let dropdownDataValue
            if (activeTab == 'conciergesPayment') {
                dropdownDataValue = {
                    user_id: filterData?.dropdownValue
                }
            } else {
                dropdownDataValue = {
                    restaurant_id: filterData?.dropdownValue
                }
            }
            payload = {
                ...payload,
                ...filterData,
                search_text: filterData?.searchText,
                start_date: filterData?.start_date,
                end_date: filterData?.end_date,
                ...dropdownDataValue
            }
            delete payload.dropdownValue
            delete payload.searchText
        }
        setLoader(true)
        const success = (data) => {
            if (activeTab == 'conciergesPayment') {
                setData(data?.concierges_payments)
            } else {
                setData(data?.admin_earnings)
            }
            setTransactionsData(data)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminConciergePaymentsAndEarnings(access_token, payload, success, fail))
    }

    // get data for first time
    const getDataForFirstTime = () => {
        setLoader(true)
        setCurrentPage(1)
        getAdminConciergePaymentsAndEarningsData()
    }

    // get CSV Data
    const getCSVData = () => {
        let payload = {
            export: "csv",
            year: downloadCSVData?.year,
            month: downloadCSVData?.month,
            type: activeTab
        }
        setCSVLoader(true)
        const success = () => {
            setCSVLoader(false)
            setDownloadCSV(false)
            setDownloadCSVData({
                month: "",
                year: new Date().getFullYear().toString()
            })
        }
        const fail = () => {
            setCSVLoader(false)
        }
        dispatch(getAdminConciergePaymentsAndEarningsCSVData(access_token, payload, success, fail))
    }

    // Parse the query parameter when the component mounts
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tab = params.get('activeTab');
        if (tab) {
            setActiveTab(tab);
        }
    }, [location.search]);

    useEffect(() => {
        getAdminConciergePaymentsAndEarningsData()
    }, [currentPage, activeTab])

    useEffect(() => {
        setLoader(true)
        setCurrentPage(1)
        setTypeFiltered("")
    }, [activeTab])

    useEffect(() => {
        // get dropdown data for concierges and restaurants
        dispatch(getDropdownConciergesAPI())
        dispatch(getDropdownRestaurantAPI())
    }, [])

    useEffect(() => {
        const prevData = prevFilterDataRef.current;
        // Check if the object structure or values have changed
        const hasObjectChanged = (prev, current) => {
            // Get all keys from both objects
            const prevKeys = Object.keys(prev);
            const currentKeys = Object.keys(current);
            // Check if the number of keys has changed (new key added or removed)
            if (prevKeys.length !== currentKeys.length) {
                return true;
            }
            // Check if any key or value has changed
            for (const key of currentKeys) {
                if (!prev.hasOwnProperty(key) || prev[key] !== current[key]) {
                    return true; // New key added or value updated
                }
            }
            return false;
        };
        if (hasObjectChanged(prevData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current object for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    useEffect(() => {
        if (activeTab == "conciergesPayment") {
            setDropdownOptions(dropdown_concierges)
        } else {
            setDropdownOptions(dropdown_restaurants)
        }
    }, [activeTab, dropdown_concierges, dropdown_restaurants])

    return {
        data, navigate, paginationData, loader, currentPage, setCurrentPage, activeTab, setActiveTab, dropdownOptions, selectedItmDetail,
        setSelectedItemDetail, getDataForFirstTime, transactionsData, addFilter, setAddFilter, filterData, setFilterData, onClearFilter,
        onApplyFilter, handleChangeFilter, downloadCSV, setDownloadCSV, downloadCSVData, setDownloadCSVData, CSVLoader, getCSVData,
        typeFiltered, setTypeFiltered
    }
}

export default useAdminTransactionPayments