import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminBusinessCategoryHelper from './helper'
import Back from 'Components/Elements/Back'
import CategoryCard from './Elements/CategoryCard'
import BusinessCategoryCardShimmer from 'Utility/Shimmers/BusinessCategoryCardShimmer'
import Button from 'Components/Elements/Button'
import AdminCreateEditCategory from './CreateEditCategory'

const AdminBusinessCategory = () => {
    const { loading, data, editData, setEditData, addEditCategory, setAddEditCategory, getBusinessCategories
    } = useAdminBusinessCategoryHelper()
    return (
        <DashboardLayout active={'business-category'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className="flex items-center gap-3 justify-between">
                    <Back
                        title={'Business Category'}
                    />
                    <div className="flex justify-end my-2 gap-4">
                        <Button
                            text={'Add Category'}
                            onClick={() => { setAddEditCategory(true) }}
                        />
                    </div>
                </div>
                <div className="!m-0 flex flex-col">
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className='grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 justify-between items-center'>
                                {loading ?
                                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map(itm => {
                                        return <BusinessCategoryCardShimmer />
                                    })
                                    :
                                    data?.map((itm, index) => {
                                        return <>
                                            <CategoryCard
                                                name={itm?.name}
                                                imageSrc={itm?.icon}
                                                onEditClick={() => {
                                                    setAddEditCategory(true)
                                                    setEditData(itm)
                                                }}
                                            />
                                        </>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {addEditCategory &&
                <AdminCreateEditCategory
                    open={addEditCategory}
                    close={() => {
                        setAddEditCategory(false)
                        setEditData()
                    }}
                    editData={editData}
                    successCallBack={getBusinessCategories}
                />
            }

        </DashboardLayout>
    )
}

export default AdminBusinessCategory