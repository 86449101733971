import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminFinance from './helper'
import ReactMonthPicker from 'Components/Elements/ReactMonthPicker'
import Dropdown from 'Components/Elements/Dropdown'
import { BookingAnalyticsTimePeriod } from 'Constants/Data/Categories'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import Restaurant from 'Utility/Tables/AdminFinanceTable/Restaurant'
import Concierge from 'Utility/Tables/AdminFinanceTable/Concierge'
import Back from 'Components/Elements/Back'

const AdminFinance = () => {
    const { financeData, navigate, paginationData, loader, currentPage, setCurrentPage, monthValue, activeTab, setActiveTab, type, setType,
        setMonthValue, filter, setFilter } = useAdminFinance()
    return (
        <DashboardLayout active={'finance'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <Back
                    title={'Finance'}
                />
                <div className="!m-0 flex flex-col">
                    <div className="border-b mt-4 mb-5">
                        <div className="flex items-center gap-6">
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "concierges" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("concierges");
                                }}
                            >
                                <h2>Concierges</h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "restaurants" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("restaurants");
                                }}
                            >
                                <h2>
                                    Business
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap w-full gap-3 float-end items-center justify-end">
                        <div>
                            <Dropdown
                                title={''}
                                placeholder={'Select report type'}
                                option={[
                                    { label: "Last month invoices", value: "last_month" },
                                    { label: "Historical invoices", value: "historical" },
                                    { label: "Unpaid invoices", value: "unpaid" }
                                ]}
                                value={type}
                                onChange={(e) => { setType(e.target.value) }}
                            />
                        </div>
                        <div>
                            <Dropdown
                                title={''}
                                placeholder={'Select Time period'}
                                option={BookingAnalyticsTimePeriod}
                                onChange={(e) => setFilter(e?.target?.value)}
                                value={filter}
                            />
                        </div>
                        {filter == 'date' &&
                            <ReactMonthPicker
                                onChange={(e) => setMonthValue(e?.target?.value)}
                                value={monthValue}
                                name={'month'}
                                isClearable={true}
                            />
                        }
                    </div>
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                    <div className="relative sm:rounded-lg border border-table">
                                    {loader
                                        ? <BookingTableShimer columns={7} />
                                        :
                                        activeTab == 'restaurants' ?
                                            <Restaurant data={financeData} /> :
                                            <Concierge data={financeData} />
                                    }
                                    {financeData?.length > 0
                                        && <Pagination
                                            currentPage={currentPage}
                                            pageSize={paginationData?.per_page}
                                            totalCount={paginationData?.total_pages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            totalDataCount={paginationData?.total}
                                            currentPageCount={financeData?.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default AdminFinance