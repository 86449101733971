import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminPayReferralRewardHelper from './helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import ReferralCard from './Elements/ReferralCard'
import AdminAmountCardShimmer from 'Utility/Shimmers/AdminAmountCardShimmer'
import AmountCard from '../Analytics/Elements/AmountCard'
import LoginInput from 'Components/Elements/LoginInput'

const AdminPayReferralReward = () => {
    const { data, paginationData, loader, secondLoader, currentPage, setCurrentPage, activeTab, setActiveTab, detail, setDetail,
        searchText, setSearchText, completeData } = useAdminPayReferralRewardHelper()
    return (
        <DashboardLayout active={'pay-referral-reward'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className="px-4 space-y-4">
                    <div className="flex justify-between items-center">
                        <Back title={'Pay Referral Reward'} />
                        <div>
                            <LoginInput
                                title={""}
                                placeholder={'Search...'}
                                value={searchText}
                                onChange={(e) => { setSearchText(e.target.value) }}
                                required={false}
                                inputPadding={"px-4 py-[9px]"}
                                parentClass={"xs:w-fit w-full"}
                            />
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <div className="border-b mt-2 mb-5">
                        <div className="flex items-center gap-6">
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "accepted" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("accepted");
                                }}
                            >
                                <h2>
                                    Accepted
                                </h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "pending" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("pending");
                                }}
                            >
                                <h2>Pending</h2>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-8 space-y-5">
                        <div className="pt-6">
                            {loader ?
                                <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
                                    {[0, 1]?.map((itm) => {
                                        return (
                                            <AdminAmountCardShimmer />
                                        )
                                    })}
                                </div>
                                :
                                <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
                                    <AmountCard
                                        amount={completeData?.total_paid}
                                        title={`Total Paid`}
                                        showCurrency
                                    />
                                    <AmountCard
                                        amount={completeData?.total_payable}
                                        title={`Total Payable`}
                                        showCurrency
                                    />
                                </div>
                            }
                        </div>
                        <div className="space-y-3">
                            <div className="relative sm:rounded-lg">
                                {loader
                                    ? <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                        {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                            return <div className='flex flex-col gap-4 h-[110px] animate-pulse bg-darkGrey sm:rounded-[20px] rounded-lg'>
                                            </div>
                                        })}
                                    </div>
                                    :
                                    data?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={data?.length ? data?.length : 10} //This is important field to render the next data
                                            next={() => {
                                                setCurrentPage(currentPage + 1)
                                            }}
                                            hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                                            loader={secondLoader
                                                ? <div className="flex justify-center items-center my-4">
                                                    <PreloaderSm />
                                                </div> : ""
                                            }
                                        >
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                                {data?.map(itm => {
                                                    return <ReferralCard
                                                        onCardClick={() => {
                                                            setDetail(itm)
                                                        }}
                                                        date={itm?.created_at}
                                                        name={`${itm?.user?.first_name ? itm?.user?.first_name : ""} ${itm?.user?.last_name ? itm?.user?.last_name : ""}`}
                                                        earning={itm?.amount}
                                                        approved={itm?.is_approved}
                                                    />
                                                })}
                                            </div>
                                        </InfiniteScroll>
                                        : <NotFound />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default AdminPayReferralReward