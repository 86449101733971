import { getBusinessCategoriesData } from "Adapters/APIs/Admin/businessCategories"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

const useAdminBusinessCategoryHelper = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [editData, setEditData] = useState()
    const [addEditCategory, setAddEditCategory] = useState(false)

    // get business categories
    const getBusinessCategories = () => {
        const success = (res) => {
            setData(res)
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        const payload = {
            is_admin: true
        }
        setLoading(true)
        dispatch(getBusinessCategoriesData(access_token, payload, success, fail))
    }

    useEffect(() => {
        getBusinessCategories()
    }, [])

    return {
        loading, data, editData, setEditData, addEditCategory, setAddEditCategory, getBusinessCategories
    }
}

export default useAdminBusinessCategoryHelper