import { getAdminConciergeApiData } from "Adapters/APIs/Admin/concierge";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

let timeOutId = undefined
const useAdminConcierges = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const allConcierges = state?.adminConcierges?.concierges
    const pagination_data = state?.adminConcierges?.pagination_data

    // states
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [active, setActive] = useState('')
    const [searchText, setSearchText] = useState("")
    const [currentPage, setCurrentPage] = useState(1)

    // get dashboard cards data
    const getAdminConcierges = () => {
        let payload = {
            page: currentPage,
            search_text: searchText,
            status: active,
            sort_by: "name",
            direction: "asc"
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        const success = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getAdminConciergeApiData(access_token, payload, success, fail))
    }

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText 
            getAdminConcierges()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getAdminConcierges()
            }, 500);
        }
    }, [searchText, currentPage, active])

    useEffect(() => {
        setLoader(true)
        setCurrentPage(1)
    }, [active])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    return {
        allConcierges, navigate, active, setActive, searchText, setSearchText, pagination_data, loader, secondLoader,
        currentPage, setCurrentPage
    }
}

export default useAdminConcierges