import Svgs from 'Assets/svgs'
import Dropdown from 'Components/Elements/Dropdown'
import Popup from 'Components/Elements/Popup'
import ProgressBar from 'Components/Elements/ProgressBar'
import React, { useMemo, useState } from 'react'
import NotFound from 'Utility/NotFound'

const ReferralDetailPopup = ({ open, close, data, filterTypes }) => {
    const [type, setType] = useState("")

    const filteredReferrals = useMemo(() => {
        return data?.referrals?.filter(itm => {
            if (type === "pending") {
                return !itm?.referred_user_first_name; // show only pending referrals
            } else if (type === "accepted") {
                return itm?.referred_user_first_name; // show only accepted referrals
            }
            return true; // show all referrals when type is empty
        });
    }, [data?.referrals, type]);

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={"md:w-[45vw]"}
            heading={'Referral Details'}
        >
            <div>
                <Dropdown
                    title={''}
                    placeholder={'Select Type'}
                    onChange={(e) => { setType(e.target.value) }}
                    value={type}
                    option={filterTypes}
                />
            </div>
            <div className="space-y-3 pt-4">
                {filteredReferrals?.length > 0 ? (
                    filteredReferrals.map((itm, index) => (
                        <div key={index} className="flex w-full sm:rounded-[20px] rounded-lg p-4 gap-4 justify-between bg-lightGrey flex-1">
                            <Svgs.UserIcon2 className="w-12 h-12 sm:w-16 sm:h-16 min-w-[48px] min-h-[48px]" />
                            <div className="flex flex-1 flex-col space-y-2 w-full">
                                <div className="flex gap-2 items-center justify-between flex-wrap">
                                    <h1 className="font-semibold text-white break-words break-all">{itm?.referred_user_email}</h1>
                                    <div className={`${itm?.referred_user_first_name ? "placed" : "pending"} px-2 py-1 rounded-lg text-sm`}>
                                        {itm?.referred_user_first_name ? "Accepted" : "Pending"}
                                    </div>
                                </div>
                                {itm?.referred_user_first_name && (
                                    <ProgressBar total={10} customWidth={'w-32 sm:w-44'} doneValue={itm?.referred_user_bookings} />
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <NotFound />
                )}
            </div>
        </Popup>
    )
}

export default ReferralDetailPopup
