import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminStaff from './helper'
import LoginInput from 'Components/Elements/LoginInput'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import AdminCreateEditStaff from './CreateEditStaff'
import StaffCard from './Elements/StaffCard'
import moment from 'moment'
import Button from 'Components/Elements/Button'
import ConfirmationPopup from 'Components/ConfirmationPopup/ConfirmationPopup'
import AdminStaffShimmer from 'Utility/Shimmers/AdminStaffShimmer'
import Back from 'Components/Elements/Back'

const AdminStaff = () => {
    const { staffData, navigate, searchText, setSearchText, paginationData, loader, secondLoader, currentPage, setCurrentPage,
        staffDetail, setStaffDetail, addEditStaff, setAddEditStaff, getStaffFirstTimeData, confirmToogle, setConfirmToogle,
        statusLoader, updateStaffStatus, staffRoles
    } = useAdminStaff()
    return (
        <DashboardLayout active={'staff'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className="flex items-center gap-3 justify-between">
                    <Back
                        title={'Staff'}
                    />
                    <div className="flex justify-end my-2 gap-4">
                        <Button
                            text={'Add Staff'}
                            onClick={() => { setAddEditStaff(true) }}
                        />
                    </div>
                </div>
                <div className="py-6">
                    <div className="lg:col-span-8 space-y-5">
                        <div className="flex xs:flex-row flex-col w-full gap-3 xs:items-end lg:items-center justify-end">
                            <div className="flex">
                                <LoginInput
                                    title={""}
                                    placeholder={'Search...'}
                                    value={searchText}
                                    onChange={(e) => { setSearchText(e.target.value) }}
                                    required={false}
                                    inputPadding={"px-4 py-[9px]"}
                                    parentClass={"xs:w-fit w-full"}
                                />
                            </div>
                        </div>
                        <div className="space-y-3">
                            <div className="relative sm:rounded-lg">
                                {loader
                                    ? <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8]?.map(itm => {
                                            return <AdminStaffShimmer />
                                        })}
                                    </div>
                                    :
                                    staffData?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={staffData?.length ? staffData?.length : 10} //This is important field to render the next data
                                            next={() => {
                                                setCurrentPage(currentPage + 1)
                                            }}
                                            hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                                            loader={secondLoader
                                                ? <div className="flex justify-center items-center my-4">
                                                    <PreloaderSm />
                                                </div> : ""
                                            }
                                        >
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                                {staffData?.map(itm => {
                                                    return <StaffCard
                                                        data={itm}
                                                        id={itm?.id}
                                                        name={`${itm?.first_name} ${itm?.last_name}`}
                                                        code={itm?.staff_data?.code}
                                                        phoneNumber={`+${itm?.mobile_code}-${itm?.mobile_number}`}
                                                        email={itm?.email}
                                                        created_at={moment(itm?.staff_data?.created_at).format('MMM, DD YYYY')}
                                                        active={itm?.is_active}
                                                        setConfirmToogle={setConfirmToogle}
                                                        onCardClick={() => {
                                                            setStaffDetail(itm)
                                                            setAddEditStaff(true)
                                                        }}
                                                    />
                                                })}
                                            </div>
                                        </InfiniteScroll>
                                        : <NotFound />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {confirmToogle &&
                    <ConfirmationPopup
                        open={confirmToogle}
                        close={setConfirmToogle}
                        yesLoader={statusLoader}
                        onYesClick={updateStaffStatus}
                        status={confirmToogle?.is_active}
                    />
                }

                {addEditStaff &&
                    <AdminCreateEditStaff
                        open={addEditStaff}
                        close={() => {
                            setStaffDetail()
                            setAddEditStaff(false)
                        }}
                        editData={staffDetail}
                        successCallBack={getStaffFirstTimeData}
                        staffRoles={staffRoles}
                    />
                }
            </main>
        </DashboardLayout>
    )
}

export default AdminStaff