import { getConceirgeWishlistDetail } from "Adapters/APIs/Concierge/Wishlist";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const useWishlistDetail = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    const { id } = useParams();

    // redux states
    const state = useSelector(state => state)
    const wishlist = state?.concierge?.wishlist_detail

    // states
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('submittedRequest')
    const [updateStaff, setUpdateStaff] = useState(false)

    // get Wishlist record
    const getWishlistDetailData = () => {
        setLoading(true)

        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        dispatch(getConceirgeWishlistDetail(access_token, id, success, fail,))
    }

    useEffect(() => {
        if (id) {
            getWishlistDetailData()
        } else {
            navigate(-1)
        }
    }, [id])

    return {
        wishlist, loading, activeTab, setActiveTab, updateStaff, setUpdateStaff, defaultRoute, navigate
    }
}

export default useWishlistDetail