import { currency } from 'Adapters/variables'
import DashboardLayout from 'Components/DashboardLayout'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import React from 'react'
import useFinancials from './helper'
import Back from 'Components/Elements/Back'
import FinancialsCards from 'Components/FinancialsCards'
import FinancialCardShimmer from 'Utility/Shimmers/FinancialCardShimmer'

const AdminFinancials = () => {
    const { endDate, setEndDate, startDate, setStartDate, loading, details, navigate } = useFinancials()
    return (
        <>
            <DashboardLayout active={'financials'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                    <div className="flex flex-col 2sm:flex-row items-start my-4 2sm:my-0 2sm:items-center gap-3 justify-between">
                        <Back title={'Financials'} />
                        <div className="flex w-full flex-col 2sm:flex-row gap-4 items-starty 2sm:items-center justify-end">
                            {loading
                                ? <div className="h-7 rounded bg-gray-200 w-20" />
                                : <div className="flex text-blueGrey">
                                    {!startDate && "Year "}
                                    {details?.period}
                                </div>
                            }
                            <DateRangePicker
                                className="!max-w-none"
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-6">
                        {loading
                            ? <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                <FinancialCardShimmer
                                    showHeading={true}
                                    hideIcon={true}
                                    customHeight={'h-60'}
                                />
                                <FinancialCardShimmer
                                    showHeading={true}
                                    hideIcon={true}
                                    customHeight={'h-60'}
                                />
                                <FinancialCardShimmer
                                    showHeading={true}
                                    hideIcon={true}
                                    customHeight={'h-60'}
                                />
                                <FinancialCardShimmer
                                    showHeading={true}
                                    hideIcon={true}
                                    customHeight={'h-60'}
                                />
                            </div>
                            : <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                <FinancialsCards
                                    title1={'Total Transfered'}
                                    title2={'Total Recievables'}
                                    title3={'Total Platform Commission'}
                                    heading={'Business Payments'}
                                    amount1={details?.total_booking_received}
                                    amount2={details?.total_booking_pending}
                                    amount3={details?.total_booking_earned}
                                    currency={currency}
                                    btnText={'View Details'}
                                    onAllClick={() => { navigate('/admin/commissions-payments?activeTab=ourEarnings') }}
                                    themeBtn={'view-edit-btn bg-transparent !text-[#1D8D70] font-semibold mt-3'}
                                    bgColor={'border !w-full'}
                                />
                                <FinancialsCards
                                    title1={'Total Transfered'}
                                    title2={'Total Payables'}
                                    title3={'Total Concierges Earning'}
                                    heading={'Concierges Payments'}
                                    amount1={details?.total_conceirges_paid}
                                    amount2={details?.total_conceirges_payable}
                                    amount3={details?.total_conceirges_earning}
                                    currency={currency}
                                    btnText={'View Details'}
                                    onAllClick={() => { navigate('/admin/commissions-payments?activeTab=conciergesPayment') }}
                                    themeBtn={'view-edit-btn bg-transparent !text-[#1D8D70] font-semibold mt-3'}
                                    bgColor={'border !w-full'}
                                />
                                <FinancialsCards
                                    title1={'Profile Boosting'}
                                    title2={'Promotion Boosting'}
                                    title3={'Total Boosting Earning'}
                                    heading={'Boosting Payments'}
                                    amount1={details?.total_profile_earned}
                                    amount2={details?.total_promotion_earned}
                                    amount3={details?.total_earned_by_purchase}
                                    currency={currency}
                                    btnText={'View Details'}
                                    themeBtn={'view-edit-btn bg-transparent !text-[#1D8D70] font-semibold mt-3'}
                                    onAllClick={() => { navigate('/admin/promotion-request') }}
                                    hideSeprater={true}
                                    bgColor={'border !w-full'}
                                />
                                <FinancialsCards
                                    title1={'Total Transfered'}
                                    title2={'Total Recievables'}
                                    title3={'Total Subscription Earning'}
                                    hideBtn={true}
                                    heading={'Subscription Payments'}
                                    amount1={details?.total_subscription_recieved}
                                    amount2={details?.total_subscription_pending}
                                    amount3={details?.total_subscription_earned}
                                    currency={currency}
                                    hideSeprater={true}
                                    bgColor={'border !w-full'}
                                />
                            </div>
                        }
                    </div>
                </main>
            </DashboardLayout>
        </>
    )
}

export default AdminFinancials