import DashboardLayout from 'Components/DashboardLayout'
import Dropdown from 'Components/Elements/Dropdown'
import React from 'react'
import useAdminRestaurants from './helper'
import { ACTIVE_INACTIVE_DROPDOWN } from 'Constants/Data/ActiveInactiveDropdown'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import RestaurantCard from '../MainDashbaord/Elements/RestaurantCard'
import AdminRestaurantCardShimmer from 'Utility/Shimmers/AdminRestaurantCardShimmer'
import LoginInput from 'Components/Elements/LoginInput'
import NotFound from 'Utility/NotFound'
import Svgs from 'Assets/svgs'
import Back from 'Components/Elements/Back'

const AdminRestaurants = () => {
  const { allRestaurants, navigate, active, setActive, searchText, setSearchText, pagination_data, loader, secondLoader, currentPage,
    setCurrentPage } = useAdminRestaurants()
  return (
    <>
      <DashboardLayout active={'Restaurant'}>
        <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
          <div className='flex sm:flex-row flex-col gap-2 justify-between sm:items-center py-4 px-2'>
            <Back
              title={'Business'}
              onClick={() => { navigate(-1) }} />
            <div className='flex xs:flex-row flex-col justify-end items-center gap-2 flex-1'>
              <LoginInput
                title={''}
                name={'searchText'}
                value={searchText}
                onChange={(e) => { setSearchText(e.target.value) }}
                placeholder={'Search business'}
                inputPadding={"px-4 py-[9px]"}
                inputClass={"flex-1"}
                parentClass={"xs:w-fit w-full"}
              />
              <div className='xs:w-fit w-full'>
                <Dropdown
                  title={''}
                  placeholder={"All Businesses"}
                  name={active}
                  onChange={(e) => { setActive(e.target.value); }}
                  value={active}
                  inputClass={"min-w-[180px]"}
                  option={[
                    { label: "All Businesses", value: "" },
                    ...ACTIVE_INACTIVE_DROPDOWN,
                    { label: "By Suspended", value: "suspended" },
                    { label: "By Due Payments", value: "due_payments" },
                    { label: "Sort By Joining Date", value: "sort_by_joining_date" },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="!m-0">
            {loader ?
              <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4 p-2">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                  <AdminRestaurantCardShimmer showImages={true} key={index} />
                ))}
              </div>
              :
              allRestaurants?.length > 0 ?
                <InfiniteScroll
                  dataLength={allRestaurants?.length ? allRestaurants?.length : 10} //This is important field to render the next data
                  next={() => {
                    setCurrentPage(currentPage + 1)
                    // getAdminRestaurants()
                  }}
                  hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                  loader={secondLoader
                    ? <div className="flex justify-center items-center my-4">
                      <PreloaderSm />
                    </div> : ""
                  }
                // height={'40rem'}
                >
                  <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4 p-2">
                    {allRestaurants?.map((itm, index) => {
                      return <RestaurantCard
                        showImages={true}
                        data={itm}
                        images={itm?.images}
                        index={index}
                        name={itm?.name}
                        phoneNumber={`${itm?.mobile_code}-${itm?.mobile_number}`}
                        address={itm?.address}
                        totalBookings={itm?.reservations}
                        totalEarned={itm?.total_earned}
                        totalDues={itm?.due_payments}
                        deleted={itm?.user?.is_deleted == 1 ? true : false}
                        active={itm?.is_active == 1 ? true : false}
                        viewEditProfileClick={() => { navigate(`/admin/restaurant/${itm?.id}`) }}
                        viewBookingClick={() => { navigate(`/admin/restaurant-bookings/${itm?.id}`) }}
                        hideViewEdit
                      />
                    })}
                  </div>
                </InfiniteScroll>
                : <NotFound />
            }
          </div>
        </main>
      </DashboardLayout>
    </>
  )
}

export default AdminRestaurants