import React from 'react';

const SlotCardShimmer = () => {
    return (
        <div className="rounded-[1.25rem] bg-darkGrey p-4 animate-pulse">
            <div className="flex justify-between items-center gap-2">
                <div className="flex items-center gap-2">
                    <div className="relative w-7 h-7 bg-gray-300 rounded-full" />
                    <div className="h-6 w-24 bg-gray-300 rounded"></div>
                </div>
                <div className="h-6 w-6 bg-gray-300 rounded-full"></div>
            </div>
            <hr className="my-3 border-lightGrey" />
            <div className="flex items-center justify-center w-full gap-4">
                {/* Weekday fees shimmer */}
                <div className="flex flex-col space-y-2 flex-1 items-center justify-center">
                    <div className="h-4 w-24 bg-gray-300 rounded"></div>
                    <div className="h-8 w-28 bg-gray-300 rounded"></div>
                </div>
                <hr className="w-[1px] h-24 border-r border-solid border-lightGrey" />
                {/* Weekend fees shimmer */}
                <div className="flex flex-col space-y-2 flex-1 items-center justify-center">
                    <div className="h-4 w-24 bg-gray-300 rounded"></div>
                    <div className="h-8 w-28 bg-gray-300 rounded"></div>
                </div>
            </div>
        </div>
    );
};

export default SlotCardShimmer;
