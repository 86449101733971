import Svgs from 'Assets/svgs'
import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const AdminTransactionTable = ({ data, onRowCick, activeTab }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[800px]">
                    <thead className="text-sm text-white border-b border-table bg-primary capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Invoice Date
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                {activeTab == "conciergesPayment" ? "Concierge" : "Business"} Name
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Bill Value
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Platform Commission Value
                            </th>
                            {activeTab != "conciergesPayment" ?
                                <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                    Platform Earning
                                </th>
                                : ""
                            }
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Collection Status
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Concierge Commission
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Payment Reciept Confirmation
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Approval Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="text-blueGrey cursor-pointer text-blueGrey border-b border-table bg-primary"
                                            onClick={() => {
                                                onRowCick && onRowCick(itm?.number)
                                            }}
                                        >
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.number}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {moment(itm?.updated_at).format('DD-MM-YYYY')}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.restaurant ? itm?.restaurant?.name : `${itm?.user?.first_name} ${itm?.user?.last_name}`}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.bill_value}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.platform_commission}
                                            </td>
                                            {activeTab != "conciergesPayment" ?
                                                <td class="px-6 py-3 whitespace-nowrap">
                                                    {itm?.amount}
                                                </td>
                                                : ""
                                            }
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.proof ? "Paid" : "Unpaid"}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.concierge_commission}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                <button className={`${itm?.is_received ? 'approve' : 'not-approved'} flex items-center gap-1 px-4 py-1.5 rounded-lg `}>
                                                    {
                                                        itm?.is_received
                                                            ? <>
                                                                <Svgs.Tick stroke={"white"} />
                                                                Confirmed
                                                            </>
                                                            : <>
                                                                Not Confirmed
                                                            </>
                                                    }
                                                </button>
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.approval_date ? itm?.approval_date : "----"}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {!data &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default AdminTransactionTable