import { updateOrAddAdminAnnouncement } from "Adapters/APIs/Announcement";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";


let initialData = {
    name: "",
    details: "",
    start_date: '',
    time: '',
    recipient: "concierge"
}
const useAdminCreateEditAnnouncement = ({ editData, close, successCallBack }) => {
    const dispatch = useDispatch()

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState(initialData)

    // handle onChange function
    const handleChange = (event) => {
        const { name, value } = event.target
        if (name == 'start_date') {
            setData({
                ...data,
                [name]: value,
                time: event?.target?.time
            })
        }
        if (name != 'start_date') {
            setData((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        setErrors((prev) => ({ ...prev, [name]: '' }));
    }


    // update or add announcement data
    const updateOrAddAnnouncementData = () => {
        const formattedTime = data?.time ? data.time.slice(0, 5) : '';
        let payload = {
            id: editData?.id,
            ...data,
            time: formattedTime
        }
        const success = () => {
            setLoader(false)
            successCallBack && successCallBack()
            handleClose()
        }
        const fail = () => {
            setLoader(false)
        }
        let requiredData = {
            ...data
        }
        delete requiredData.time
        delete requiredData.recipient
        let result = validation(requiredData);
        setErrors(result);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoader(true)
            dispatch(updateOrAddAdminAnnouncement(access_token, payload, success, fail))
        }
    }

    // handle close
    const handleClose = () => {
        close && close()
        setData(initialData)
    }

    useEffect(() => {
        if (editData) {
            setData({
                name: editData?.name,
                details: editData?.details,
                start_date: editData?.start_date,
                time: editData?.time,
                recipient: editData?.recipient
            })
        }
    }, [editData])

    return {
        data, loader, updateOrAddAnnouncementData, handleChange, setData, errors, handleClose
    }
}

export default useAdminCreateEditAnnouncement