import Svgs from 'Assets/svgs'
import ToggleWithText from 'Components/Elements/ToggleWithText'
import React from 'react'

const StaffCard = ({ data, code, name, active, onCardClick, email, created_at, setConfirmToogle }) => {
    return (
        <div className="bg-darkGrey restaurant-card-shadow rounded-xl p-4 pt-2 w-full cursor-pointer" onClick={onCardClick && onCardClick}>
            <div className='flex gap-2 items-center'>
                <Svgs.UserIcon2 width={'56'} height={'56'} />
                <div className="flex flex-1 flex-col space-y-2 mt-5">
                    <div className='flex gap-4 justify-between items-center'>
                        <div className='flex flex-col space-y-1'>
                            <p className='text-sm text-secondary'>ID: {code}</p>
                            <h1 className='font-semibold text-white'>{name}</h1>
                        </div>
                        <ToggleWithText
                            checked={active == 1 ? true : false}
                            onChange={() => {
                                setConfirmToogle(data)
                            }}
                        />
                    </div>
                    <div className='flex xs:items-center xs:flex-row flex-col justify-between gap-4'>
                        <div className="flex gap-2 items-center text-sm text-blueGrey">
                            <span><Svgs.MailIcon stroke={'var(--secondary-color)'}  /></span>
                            <span className="font-extralight">{email}</span>
                        </div>
                        <div className="flex items-center gap-2 text-blueGrey text-sm">
                            <span><Svgs.CalendarGreen fill={'var(--secondary-color)'}  /></span>
                            <span className='extralight'>{created_at}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StaffCard