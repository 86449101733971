import React, { useRef, useState } from 'react';
import Svgs from 'Assets/svgs';
import { error_message } from 'Constants/Functions/Variables';
import { useEffect } from 'react';

const FloatInput = ({
    onEnterSubmit,
    rightIcon,
    leftIcon,
    title,
    required,
    name,
    placeholder,
    value,
    onChange,
    error,
    showBorder = true,
    disabled,
    parentClass,
    inputPadding,
    min,
    max,
    isPercentage,
    isCapitalized,
    rightIconClassName,
    errorClass,
    inputClass,
    charactersMaxLength,
    iconStyle,
    readOnly,
    inputBg,
    customColor,
    bgIconTransparent,
    type }) => {
    const inputRef = useRef(null);

    const [sanitizedValue, setSanitizedValue] = useState('');
    const [inputHeight, setInputHeight] = useState(0);

    const parsedPlaceholder = isCapitalized ? placeholder : placeholder && placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();

    useEffect(() => {
        // Calculate input height after component mounts
        if (inputRef.current) {
            setInputHeight(inputRef.current.clientHeight);
        }
    }, []);

    useEffect(() => {
        let inputValue = value ? (typeof value == 'string') ? value?.replace(/[^0-9.]/g, '') : value : ''
        setSanitizedValue(inputValue)
    }, [value])

    const handleInputChange = (e) => {
        // Allow only digits, one dot, and ignore other characters
        let inputValue = e.target.value ? e.target.value?.replace(/[^0-9.]/g, '') : '';
        e.target.value = inputValue

        // validation for maximum characters length
        if (charactersMaxLength) {
            inputValue = e.target.value?.slice(0, charactersMaxLength)
            e.target.value = inputValue
        }

        // Allow only one dot in the value
        const parts = e.target.value?.split('.');
        const firstPart = parts.shift(); // Get the first part (before the first dot)
        inputValue = firstPart + (parts.length > 0 ? '.' + parts.join('') : '');
        e.target.value = inputValue

        // validation on percenatage 
        if (isPercentage) {
            if (+inputValue > 100) {
                inputValue = 100
                e.target.value = 100
            } else {
                e.target.value = inputValue
            }
        }

        setSanitizedValue(e.target.value)

        // Call the external onChange handler (if provided)
        if (typeof onChange === "function") {
            onChange(e);
        }
    };

    const HandleKeyPress = (e) => {
        // Allow navigation keys and control keys (copy, paste, select, etc.)
        const allowedKeys = [
            'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab',
            'Control', 'Meta', 'Shift', 'Home', 'End', 'Enter'
        ];

        const key = e.key;

        // Allow keyboard shortcuts (Ctrl+A, Ctrl+C, Ctrl+V, etc.)
        if ((e.ctrlKey || e.metaKey) && ['a', 'c', 'v', 'x'].includes(key.toLowerCase())) {
            return; // Allow these actions without preventing them
        }


        // Allow only numbers, a single decimal point, and control keys
        const isNumber = /^\d$/; // Match a single digit (0-9)
        const isDecimal = e.key === '.';

        // Check if the key is allowed (numbers, decimal point, or control keys)
        if (!allowedKeys.includes(e.key) && !isNumber.test(e.key) && !isDecimal) {
            // Prevent the default behavior for disallowed keys
            e.preventDefault();
        }

        // Disallow multiple decimal points
        if (isDecimal && e.target.value.includes('.')) {
            e.preventDefault();
        }

        // Execute submit action on Enter key
        if (e.key === 'Enter') {
            onEnterSubmit();
        }
    };

    return (
        <div className={`${parentClass ? parentClass : ""} relative flex flex-col gap-2 `}>

            {title && (
                <div className={`${title == '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            {rightIcon ? (
                <div className={`${error ? "inputError border-[1.5px] border-error" : showBorder ? "border-[1.5px] border-lightGrey" : ""} rounded-[10px] relative flex overflow-hidden transition`}>
                    <input
                        ref={inputRef}
                        onKeyDown={HandleKeyPress}
                        min={min}
                        max={max}
                        name={name}
                        disabled={disabled}
                        maxLength={charactersMaxLength}
                        type={type ? type : "text"}
                        value={sanitizedValue}
                        onChange={handleInputChange}
                        autoComplete="off"
                        placeholder={parsedPlaceholder}
                        className={`${error ? " border-[1.5px] border-error" : showBorder ? "border border-lightGrey" : ""} ${inputPadding ? inputPadding : "px-4 py-2"} ${(readOnly || disabled) && 'cursor-not-allowed  !bg-gray-800'} text-[0.875rem] min-h-10 rounded-[10px] w-full bg-darkGrey text-base ${inputClass}`}
                    />
                    <div className={`${rightIconClassName} bg-lightGrey absolute top-1/2 -translate-y-1/2 right-0 mr-0.5 rounded-r-[10px] overflow-hidden h-full text-sm input-right-icon`}>
                        {rightIcon}
                    </div>
                </div>
            ) : leftIcon ? (
                <div className={`relative flex overflow-hidden transition rounded-[10px] ${error ? "inputError border-[1.5px] border-error" : showBorder ? "border border-lightGrey" : ""} `}>
                    <div className={`text-blueGrey min-w-[40px] flex items-center rounded-l-[10px] justify-center absolute top-1/2 -translate-y-1/2 px-4 text-sm ${bgIconTransparent ? bgIconTransparent : 'bg-lightGrey border-r border-lightGrey'} ${iconStyle}`} style={{ height: inputHeight }}>
                        {leftIcon}
                    </div>
                    <input
                        ref={inputRef}
                        onKeyDown={HandleKeyPress}
                        min={min}
                        max={max}
                        name={name}
                        disabled={disabled}
                        maxLength={charactersMaxLength}
                        type={type ? type : "text"}
                        value={sanitizedValue}
                        onChange={handleInputChange}
                        autoComplete="off"
                        placeholder={parsedPlaceholder}
                        className={` ${inputPadding ? inputPadding : "pl-20 pr-4 py-2"} ${(readOnly || disabled) && 'cursor-not-allowed !bg-gray-800'} text-[0.875rem] min-h-10 rounded-[10px] w-full ${customColor ? customColor : "bg-darkGrey"} text-base ${inputClass}`}
                    />
                </div>
            ) : (
                <input
                    ref={inputRef}
                    onKeyDown={HandleKeyPress}
                    min={min}
                    max={max}
                    name={name}
                    disabled={disabled}
                    maxLength={charactersMaxLength}
                    type={type ? type : "text"}
                    value={sanitizedValue}
                    onChange={handleInputChange}
                    autoComplete="off"
                    placeholder={parsedPlaceholder}
                    className={`${error ? "inputError border-[1.5px] border-error" : showBorder ? "border-[1.5px] border-lightGrey" : ""} ${(readOnly || disabled) && 'cursor-not-allowed !bg-gray-800'} ${inputPadding ? inputPadding : "px-4 py-2"} min-h-10 rounded-[10px] w-full text-[0.875rem] text-base ${inputClass} ${inputBg ? inputBg : 'bg-darkGrey '}`}
                />
            )}
            {error && typeof error == "string" ? (
                <p className={`text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end ${errorClass}`}>
                    <Svgs.I fill="#eb3b3b" />
                    {error}
                </p>
            ) : typeof error == "boolean" && error == true ? (
                <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                    <Svgs.I fill="#eb3b3b" /> {error_message}
                </p>
            ) : (
                ""
            )}
        </div>
    );
};

FloatInput.defaultProps = {
    required: true,
    value: "",
    onEnterSubmit: () => { },
    showBorder: true
}

export default FloatInput;