import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Popup from 'Components/Elements/Popup'
import RadioBtn from 'Components/Elements/RadioBtn'
import ReactMonthPicker from 'Components/Elements/ReactMonthPicker'
import { InvoicePaymentStatus, InvoiceReportTimePeriod } from 'Constants/Data/Categories'
import React, { useEffect, useState } from 'react'

const InvoiceReportFilter = ({ open, close, onClearFilter, onApplyFilter, filterData, setFilterData, handleChangeFilter, isAdmin,
    activeTab, setActiveTab, dropdownOptions
}) => {
    // states
    const [date, setDate] = useState(filterData?.date)

    useEffect(() => {
        if (date) {
            setFilterData((prev) => ({
                ...prev,
                monthValue: date,
            }))
        }
    }, [date])


    // handle on clear filters
    const handleOnClearFilters = () => {
        onClearFilter && onClearFilter()
        setDate()
    }

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Filter by'}
        >
            <div className='flex flex-col space-y-4'>
                {isAdmin &&
                    <div className="flex items-center gap-4">
                        <div className={`border ${activeTab == 'concierges' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => { setActiveTab('concierges') }}>
                            <RadioBtn name={'activeTab'} text={"Concierges"} checked={activeTab == 'concierges'} id={'concierges'} />
                        </div>
                        <div className={`border ${activeTab == 'restaurant' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => { setActiveTab('restaurant') }}>
                            <RadioBtn name={'activeTab'} text={"Businesses"} checked={activeTab == 'restaurant'} id={'restaurant'} />
                        </div>
                    </div>
                }
                {(isAdmin && activeTab) &&
                    <div>
                        <Dropdown
                            title={activeTab == "concierges" ? 'Select Concierge' : 'Select Business'}
                            placeholder={activeTab == "concierges" ? 'Select Concierge' : 'Select Business'}
                            name={'dropdownValue'}
                            onChange={handleChangeFilter}
                            value={filterData?.dropdownValue}
                            inputClass={"min-w-[180px]"}
                            option={[
                                {
                                    label: `All ${activeTab == "concierges" ? 'Concierges' : "Businesses"}`, value: ""
                                },
                                ...dropdownOptions?.map(itm => {
                                    return {
                                        value: itm?.id,
                                        label: itm?.first_name ? itm?.first_name : itm?.name
                                    }
                                })]}
                        />
                    </div>
                }
                <Dropdown
                    title={'Select Invoice Status'}
                    placeholder={'Select Invoice Status'}
                    name={'type'}
                    option={InvoicePaymentStatus}
                    onChange={handleChangeFilter}
                    value={filterData?.type}
                />
                <div>
                    <Dropdown
                        title={'Select Time period'}
                        placeholder={'Select Time period'}
                        name={'filter'}
                        option={InvoiceReportTimePeriod}
                        onChange={handleChangeFilter}
                        value={filterData?.filter}
                    />
                </div>
                {filterData?.filter == 'date' &&
                    <div className='flex gap-2 w-full items-center justify-between'>
                        <ReactMonthPicker
                            title={'Select Date'}
                            name={'date'}
                            value={date}
                            onChange={(e) => { setDate(e.target.value) }}
                            placeholder={'Select Date'}
                            inputPadding={"px-4 py-[9px]"}
                            inputClass={"flex-1"}
                            className={"flex-1"}
                        />
                    </div>
                }
                <div className="flex items-center justify-between gap-2 mt-4">
                    <Button text={'Clear'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={handleOnClearFilters} />
                    <Button text={'Apply'} className={'w-full'} onClick={onApplyFilter && onApplyFilter} />
                </div>
            </div>
        </Popup>
    )
}

export default InvoiceReportFilter