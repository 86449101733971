import { SOCKET_BASE_URL } from "Adapters/variables";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { playSound } from "Utility/Howl";
import { getRestaurantId } from "Utility/indexedDBUtils";

// Initialize the socket connection
const socket = io(SOCKET_BASE_URL);

// Create context for the app
const AppContext = React.createContext();

const SocketAppProvider = ({ children }) => {
  const dispatch = useDispatch();

  // Fetch local storage data
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const user_data = getDataFromLocalStorage('user_data');
  const access_token = getDataFromLocalStorage('access_token');

  // Redux states
  const redux = useSelector((state) => state);


  // get default route data
  function getUpdatedDefaultRoute() {
    const defaultRoute = localStorage.getItem("defaultRoute");

    if (defaultRoute) {
      // Remove leading slashes and split by '/'
      const segments = defaultRoute.replace(/^\/+/, '').split('/');
      // Check if the second segment is available (after '/dashboard')
      return segments[1] || ''; // Get the segment immediately after '/dashboard'
    }

    // Return a default value if no valid route is found
    return '';
  }


  useEffect(() => {
    // Socket channel constants
    const SOCKET_CHANNEL = {
      TEST: "user-channel.1:TEST",
      CONNECT: "connect",
      NOTIFICATIONEVENT: `notification-channel.${user_data?.id}:NotificationEvent`,
    };

    // Request notification permission
    const requestNotificationPermission = () => {
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          } else {
            console.log("Notification permission denied.");
          }
        });
      }
    };

    // Function to show browser notification
    const showBrowserNotification = (heading, payload) => {
      if ('Notification' in window && Notification.permission === "granted") {
        const indexedDBRestaurantID = getRestaurantId()

        Cookies.set('getNotificationData', true);

        // Extract notification data
        const notificationData = payload;
        const notificationType = notificationData.type;
        const notificationBody = JSON.parse(notificationData?.body);
        const notificationRestaurantId = notificationBody?.notification?.restaurant_id;
        const completeData = notificationBody?.notification

        console.log(notificationType, ":notificationType", notificationBody, ":notificationBody")

        let clickActionUrl = '/';

        const updatedDefaultRoute = getUpdatedDefaultRoute();

        switch (notificationType) {
          case 'reservation_alert':
            playSound('reservationAlert');  // Play the success sound
            clickActionUrl = `/${updatedDefaultRoute}/booking/?reservation=${completeData?.notification_data}`;
            break;
          case 'reservation_update_alert':
          case 'reservation_cancelled':
          case 'reservation_modified':
          case 'reservation_modified_confirmed':
          case 'reservation_confirmed':
          case 'reservation_no_show':
          case 'restaurant_request_alert':
          case 'bill_alert':
            clickActionUrl = `/${updatedDefaultRoute}/booking/?reservation=${completeData?.notification_data}`;
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/booking`) && (!JSON.parse(sessionStorage.getItem('create_booking')) && updatedDefaultRoute != 'restaurant')) {
              window.location.href = `/${updatedDefaultRoute}/booking/?reservation=${completeData?.notification_data}`;
            }
            break;

          case 'commission_paid_alert':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/commission`)) {
              window.location.href = `/${updatedDefaultRoute}/commission`
            }
            clickActionUrl = `/${updatedDefaultRoute}/commission`;
            break;

          case 'commission_confirmation_alert':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/payment-history`)) {
              window.location.href = `/${updatedDefaultRoute}/payment-history`
            }
            clickActionUrl = `/${updatedDefaultRoute}/payment-history`;
            break;

          case 'boost_request_approved':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/boosting-history`)) {
              window.location.href = `/${updatedDefaultRoute}/boosting-history/?slot_id=${completeData?.notification_data}`
            }
            clickActionUrl = `/${updatedDefaultRoute}/boosting-history/?slot_id=${completeData?.notification_data}`;
            break;

          case 'membership_confirmation_alert':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/invite-friend`)) {
              window.location.href = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`
            }
            clickActionUrl = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`;
            break;

          case 'withdraw_request_approved':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/invite-friend`)) {
              window.location.href = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`
            }
            clickActionUrl = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`;
            break;

          case 'reward_alert':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/reward`)) {
              window.location.href = `/${updatedDefaultRoute}/reward`
            }
            clickActionUrl = `/${updatedDefaultRoute}/reward`;
            break;

          case 'announcement_alert':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/announcement`)) {
              window.location.href = `/${updatedDefaultRoute}/announcement`
            }
            clickActionUrl = `/${updatedDefaultRoute}/announcement`;
            break;

          case 'wishlist_update_alert':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist`) || window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`)) {
              window.location.href = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`
            }
            clickActionUrl = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`;
            break;

          case 'wishlist_assigned_alert':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist`) || window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`)) {
              window.location.href = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`
            }
            clickActionUrl = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`;
            break;

          case 'user_deactivate_alert':
            Cookies.set('logoutUser', true);
            break;

          default:
            clickActionUrl = '/';
        }

        if (!(updatedDefaultRoute === "admin" || notificationType === "user_deactivate_alert")) {
          // Create a notification
          const notification = new Notification(heading?.title, {
            body: heading?.body,
            icon: "https://conciergebooking.tijarah.ae/er-logo.svg", // Optional: Add a custom icon here
          });

          // Add a click event listener
          notification.onclick = (event) => {
            event.preventDefault(); // Prevent the browser from focusing the Notification's tab
            if (notificationRestaurantId && (indexedDBRestaurantID !== notificationRestaurantId)) {
              // Call function to show the popup
              Cookies.set('notificationClickUrl', clickActionUrl);
              Cookies.set('notificationRestaurantId', notificationRestaurantId);
            } else {
              // Safari-specific workaround
              setTimeout(() => {
                if (window.open) {
                  // Try opening a new window
                  const win = window.open(clickActionUrl, '_blank');
                  if (win) {
                    win.focus();
                  } else {
                    // If window.open is blocked, fallback to window location
                    window.location.href = clickActionUrl;
                  }
                } else {
                  // Fallback to changing the current window's location
                  window.location.href = clickActionUrl;
                }
              }, 100); // Small timeout to bypass Safari's restrictions
            }
          };
        }
      }
    };


    // Request permission for notifications when the component mounts
    requestNotificationPermission();

    // Handle socket connection event
    socket.on(SOCKET_CHANNEL.CONNECT, () => {
      if (!socket.connected) {
        console.log(socket.connected, "Socket not connected");
      } else {
        console.log(socket.connected, "Socket connected");
      }
    });

    // Test Channel for socket
    socket.on(SOCKET_CHANNEL.TEST, (data) => {
      console.log(data, "Test Channel");
    });

    if (user_data?.id) {
      // Handle Notifications
      socket.on(SOCKET_CHANNEL.NOTIFICATIONEVENT, (data) => {
        console.log("🚀 ~ socket.on ~ Notification event data:", data);

        // Generate a browser notification when a notification event is received
        showBrowserNotification(data?.message?.notification?.body?.notification, data?.message?.notification?.body?.data);
      });
    }

    // Clean up the event listeners on unmount
    return () => {
      socket.off(SOCKET_CHANNEL.CONNECT);
      socket.off(SOCKET_CHANNEL.NOTIFICATIONEVENT); // Unsubscribe from notification event
    };
  }, [user_data?.id]);

  return (
    <AppContext.Provider value={{ ...redux, socket }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to access context
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, SocketAppProvider };