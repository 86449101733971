import { updateUnapprovedRestaurant } from 'Adapters/APIs/Admin/dashboard';
import { getBusinessTypesData, updateEmailRequest, updateUser } from 'Adapters/APIs/Authentication';
import { updateBusinessProfile } from 'Adapters/APIs/Restaurant/Business';
import { generateDescription } from 'Adapters/APIs/Restaurant/Profile';
import { getAllCategories, getAllCuisine } from 'Adapters/APIs/restaurants';
import Svgs from 'Assets/svgs';
import { objectKeyConvertToArray } from 'Hooks/useObjectKeys';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import useValidations from 'Hooks/useValidations';
import Cookies from 'js-cookie';
import { useMemo, useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const useEditProfile = (setIsEdit, editData, successCallback) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();

    // Parse the query string
    const searchParams = new URLSearchParams(location.search);
    const activeTab = searchParams.get('activeTab');

    // Check the pathname and query parameter
    const isProfileTabActive = location.pathname === '/restaurant/profile' && activeTab === 'profile';

    const { errors, setErrors, validation, validateMoblieNumber, emailValidation, validateWebsiteUrlWithName } = useValidations()

    const { getDataFromLocalStorage, storeDataInLocalStorage, getDataFromSessionStorage, removeDataFromSessionStorage } = useSetDataInLocalStorage()
    const user = getDataFromLocalStorage('user_data')
    const access_token = getDataFromLocalStorage('access_token')
    const editBusinessData = getDataFromSessionStorage("editBusinessData")

    const state = useSelector(state => state)
    const cuisinse = state?.cuisines?.cuisines
    const categoriesData = state?.categories?.categories

    // states
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(false)
    const [updateEmail, setUpdateEmail] = useState(false)
    const [OTPLoading, setOTPLoading] = useState(false)
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)
    const [addCuisine, setAddCuisine] = useState(false)
    const [getCuisineLoader, setGetCuisineLoader] = useState(true)
    const [existingCuisine, setExistingCuisine] = useState()
    const [businessTypes, setBusinessTypes] = useState([])
    const [loader, setLoader] = useState(true)
    const [roleValue, setRoleValue] = useState()

    const otherOptions = useMemo(() =>
        [
            {
                method_type: "cash",
                label: "Cash/Cheque"
            },
            {
                method_type: "bank",
                label: "Bank"
            },
        ], [])

    const OTHER_PAYMENT_METHODS = [
        { name: 'cash', icon: <Svgs.CashIcon fill={'var(--secondary-color)'} /> },
        { name: 'bank', icon: <Svgs.CardIcon fill={'var(--secondary-color)'} /> },
    ]

    useEffect(() => {
        if (editData?.id) {
            setFormData({
                restaurant_id: editData?.id,
                restaurant_name: editData?.name,
                images: editData?.images,
                email: editData?.email ? editData?.email : editData?.user?.email,
                finance_email: editData?.finance_email && editData?.finance_email != 'undefined' ? editData?.finance_email : "",
                authorized_manager: editData?.authorized_manager ? editData?.authorized_manager : "",
                price_per_cover: editData?.price_per_cover ? editData?.price_per_cover : "",
                categories: editData?.categories?.map(itm => itm?.id),
                cuisine_id: editData?.cuisine?.id ? editData?.cuisine?.id : "",
                website_link: editData?.website_link && editData?.website_link != 'undefined' ? editData?.website_link : "",
                phone_number: `${editData?.phone_code ? editData?.phone_code : ""}-${editData?.phone_number ? editData?.phone_number : ""}`,
                mobile_number: `${editData?.mobile_code ? editData?.mobile_code : ""}-${editData?.mobile_number ? editData?.mobile_number : ""}`,
                wa_number: `${editData?.mobile_code && `${editData?.mobile_code}-`}${editData?.wa_number ? editData?.wa_number : ''}`,
                address: editData?.address,
                country: editData?.country,
                type: 'restaurant',
                state: editData?.state,
                city: editData?.city,
                description: editData?.description ? editData?.description : "",
                location_coordinates: editData?.location_coordinates,
                trade_license: editData?.trade_license,
                agreement: editData?.agreement,
                amount_collection: editData?.amount_collection,
                amount_collection_description: editData?.amount_collection_description,
                booking_cancellation_terms: editData?.booking_cancellation_terms,
                terms_and_conditions: editData?.terms_and_conditions,
                trn_number: editData?.trn_number && editData?.trn_number != 'undefined' ? editData?.trn_number : "",
                contact_person: editData?.contact_person ? editData?.contact_person : editData?.restaurant?.contact_person,
                instagram_link: editData?.instagram_link && editData?.instagram_link != 'undefined' ? editData?.instagram_link : ""
            })
        } else {
            setFormData({
                restaurant_id: user?.restaurant?.id,
                restaurant_name: user?.restaurant?.name,
                images: user?.restaurant?.images,
                email: user?.restaurant?.email,
                finance_email: user?.restaurant?.finance_email && user?.restaurant?.finance_email != 'undefined' ? user?.restaurant?.finance_email : "",
                authorized_manager: user?.restaurant?.authorized_manager,
                price_per_cover: user?.restaurant?.price_per_cover,
                categories: user?.restaurant?.categories?.map(itm => itm?.id),
                cuisine_id: user?.restaurant?.cuisine?.id,
                website_link: user?.restaurant?.website_link && user?.restaurant?.website_link != 'undefined' ? user?.restaurant?.website_link : "",
                phone_number: `${user?.restaurant?.phone_code ? user?.restaurant?.phone_code : ""}-${user?.restaurant?.phone_number ? user?.restaurant?.phone_number : ""}`,
                mobile_number: `${user?.restaurant?.mobile_code ? user?.restaurant?.mobile_code : ""}-${user?.restaurant?.mobile_number ? user?.restaurant?.mobile_number : ""}`,
                wa_number: `${user?.restaurant?.wa_code && `${user?.restaurant?.wa_code}-`}${user?.restaurant?.wa_number && user?.restaurant?.wa_number != 'undefined' ? user?.restaurant?.wa_number : ''}`,
                address: user?.restaurant?.address,
                country: user?.country,
                type: 1, // 0 Concirege && 1 Restaurent
                state: user?.state,
                city: user?.city,
                description: user?.restaurant?.description,
                terms_and_conditions: user?.restaurant?.terms_and_conditions,
                location_coordinates: user?.location_coordinates,
                trade_license: user?.restaurant?.trade_license,
                agreement: user?.restaurant?.agreement,
                amount_collection: user?.restaurant?.amount_collection,
                amount_collection_description: user?.restaurant?.amount_collection_description,
                booking_cancellation_terms: user?.restaurant?.booking_cancellation_terms,
                trn_number: user?.restaurant?.trn_number && user?.restaurant?.trn_number != 'undefined' ? user?.restaurant?.trn_number : "",
                contact_person: user?.restaurant?.contact_person,
                instagram_link: user?.restaurant?.instagram_link && user?.restaurant?.instagram_link != 'undefined' ? user?.restaurant?.instagram_link : ""
            })
        }
    }, [editData])

    useEffect(() => {
        getCategories()
        getBusinessTypes()
    }, [])

    useEffect(() => {
        if (formData?.categories?.length > 0) {
            getCuisineData()
        }
    }, [formData?.categories, formData?.categories?.length, formData?.cuisine])

    // setting cusines
    const cuisines = useMemo(() => {
        let allData = cuisinse?.map(itm => ({
            label: itm?.name,
            value: itm?.id
        })) || []; // Default to an empty array if cuisinse is undefined

        if (existingCuisine && existingCuisine?.value) {
            allData = [
                ...allData,
                {
                    label: existingCuisine.label,
                    value: existingCuisine.value
                }
            ];
        }

        if (formData?.categories?.length == 0) {
            allData = [];
        }

        // Remove duplicates based on the 'value' property
        const uniqueData = allData?.filter(
            (item, index, self) =>
                index === self?.findIndex((i) => i?.value === item?.value)
        );

        return uniqueData;
    }, [cuisinse, existingCuisine, formData?.categories]);

    // setting categories
    const categories = useMemo(() => {
        return categoriesData?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id,
                icon: itm?.icon,
                is_sub_category_required: itm?.is_sub_category_required
            }
        })
    }, [categoriesData])

    // setting isSubCategoryRequired check
    const isSubCategoryRequired = useMemo(() => {
        const selectedIds = formData?.categories || [];
        let filteredData = categories?.filter(category => selectedIds?.includes(category?.value));
        if (filteredData?.find(itm => itm?.is_sub_category_required == 1) && categories?.length > 0) {
            return true
        } else {
            return false
        }
    }, [categories, formData?.categories]);

    // setting selected role
    const selectedRole = useMemo(() => {
        let business_types = businessTypes?.length > 0 ? businessTypes : [
            {
                "id": 1,
                "name": "Business",
                "slug": "business",
                "type": "restaurant",
                "status": 1
            },
            {
                "id": 2,
                "name": "Concierge",
                "slug": "concierge",
                "type": "concierge",
                "status": 1
            }
        ]

        setRoleValue(business_types?.find(itm => itm?.type == "restaurant"))
        return business_types?.find(itm => itm?.type == "restaurant")?.type?.toLowerCase()
    }, [businessTypes])

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    // get cuisine
    const getCuisineData = (existingId, existingName) => {
        const success = () => {
            if (existingId) {
                setFormData({
                    ...formData,
                    'cuisine_id': existingId
                })
                setErrors((prev) => ({
                    ...prev,
                    'cuisine_id': '',
                }));
                setExistingCuisine({
                    label: existingName,
                    value: existingId
                })
            }
            setAddCuisine(false)
            setGetCuisineLoader(false)
        }
        const fail = () => {
            setGetCuisineLoader(false)
        }
        let payload = {
            category_id: `[${formData?.categories}]`,
            cuisine_id: formData?.cuisine_id
        }
        setGetCuisineLoader(true)
        dispatch(getAllCuisine(payload, success, fail))
    }

    // get business types
    const getBusinessTypes = () => {
        const success = (data) => {
            setLoader(false)
            if (data?.data?.business_types?.length > 0) {
                setBusinessTypes(data?.data?.business_types?.slice(0, 2))
            }
        }
        const fail = () => {
            setLoader(false)
        }
        setLoader(true)
        dispatch(getBusinessTypesData(success, fail))
    }

    // handle selected image remove here
    const onImageRemove = (id, ind) => {
        const filteredImages = formData?.images?.filter((itm, index) => {
            if (itm?.name) {
                return index !== ind;
            } else {
                return itm?.id !== id;
            }
        });
        let deletedIds = formData?.delete_images || []
        if (id)
            deletedIds?.push(id)

        setFormData({
            ...formData,
            delete_images: deletedIds,
            images: filteredImages,
            image_update: true
        })
    }

    // handle change here
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'images') {
            let images = formData?.images || []
            images?.push(e.target.files[0])
            setFormData({
                ...formData,
                images: images,
                image_update: true
            })
        } else if (name == 'website_link') {
            validateWebsiteUrlWithName(name, value)
            setFormData({
                ...formData,
                website_link: value,
            })
        } else if (name == 'instagram_link') {
            validateWebsiteUrlWithName(name, value)
            setFormData({
                ...formData,
                instagram_link: value,
            })
        } else if (name == 'agreement') {
            setFormData({
                ...formData,
                agreement: e.target.files[0],
            })
        } else if (name == 'trade_license') {
            setFormData({
                ...formData,
                trade_license: e.target.files[0],
            })

        } else if (name == 'phone_number') {
            validateMoblieNumber(name, value);
            setFormData({
                ...formData,
                phone_number: value,
                phone_code: value?.split('-')[0]
            })
        } else if (name == 'mobile_number') {
            validateMoblieNumber(name, value);
            setFormData({
                ...formData,
                mobile_number: value,
                mobile_code: value?.split('-')[0]
            })
        }
        else if (name == 'wa_number') {
            validateMoblieNumber(name, value);
            setFormData({
                ...formData,
                wa_number: value,
                wa_code: value?.split('-')[0]
            })
        } else if (name == 'email') {
            setFormData({
                ...formData,
                [name]: value
            })
            emailValidation(name, value);
        } else if (name == 'finance_email') {
            setFormData({
                ...formData,
                [name]: value
            })
            emailValidation(name, value);
        } else if (name == 'edit_access') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.checked
            })
        }
        else if (name == 'booking_cancellation_terms') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.checked
            })
        }
        else if (name == 'amount_collection') {
            if (value == 'bank') {
                setErrors((prev) => ({ ...prev, [name]: '', amount_collection_description: "" }));
                setFormData({
                    ...formData,
                    [name]: value
                })
            } else {
                setErrors((prev) => ({ ...prev, [name]: '' }));
                setFormData({
                    ...formData,
                    [name]: value,
                    amount_collection_description: ""
                })
            }
        } else if (name == 'amount_collection_description') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: value
            })
        }
        else {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    };

    // handle submit function
    const handleSubmit = () => {
        const { phone_number, mobile_number
        } = formData
        let requiredFields = {
            restaurant_name: formData?.restaurant_name,
            email: formData?.email,
            categories: formData?.categories?.length > 0 ? true : false,
            phone_number: formData?.phone_number,
            mobile_number: formData?.mobile_number,
            address: formData?.address,
            contact_person: formData?.contact_person,
            amount_collection: formData?.amount_collection,
            amount_collection_description: formData?.amount_collection == 'bank' ? true : formData?.amount_collection_description
        }
        if (isSubCategoryRequired) {
            requiredFields = {
                ...requiredFields,
                cuisine_id: formData?.cuisine_id
            }
        }
        if (formData?.phone_number?.split('-')[1] && !formData?.mobile_number?.split('-')[1]) {
            delete requiredFields?.mobile_number
        }
        if (formData?.mobile_number?.split('-')[1] && !formData?.phone_number?.split('-')[1]) {
            delete requiredFields?.phone_number
        }
        const result = validation(requiredFields, "", "", { phone_number: phone_number?.split('-')[1] ? true : mobile_number?.split('-')[1] ? true : false, mobile_number: mobile_number?.split('-')[1] ? true : phone_number?.split('-')[1] ? true : false, });
        const newErrors = { ...validation(requiredFields, "", "", { phone_number: phone_number?.split('-')[1] ? true : mobile_number?.split('-')[1] ? true : false, mobile_number: mobile_number?.split('-')[1] ? true : phone_number?.split('-')[1] ? true : false, }) };

        if (formData?.finance_email) {
            if (validateEmail(formData.finance_email.toLowerCase())) {
                newErrors.finance_email = ""; // Clear error if valid
            } else {
                newErrors.finance_email = "Please enter a valid email address."; // Set error if invalid
            }
        } else {
            newErrors.finance_email = ""; // Clear error if no email
        }

        setErrors(newErrors); // Set errors only once

        if (objectKeyConvertToArray(result)?.length === 0 && !newErrors.finance_email) {
            // Validation for same mobile and phone_number
            if (formData?.phone_number == formData?.mobile_number) {
                setErrors({
                    ...errors,
                    phone_number: "Mobile and phone can't be same",
                    mobile_number: "Mobile and phone can't be same"
                })
            } else {
                let payload = {
                    ...formData,
                    cuisine_id: formData?.cuisine_id && formData?.cuisine_id != undefined ? formData?.cuisine_id : "",
                    images: formData?.images?.filter(itm => itm?.name)?.length > 0 ? formData?.images?.filter(itm => {
                        if (itm?.name) {
                            return JSON.stringify(itm)
                        }
                    }) : '',
                    wa_code: formData?.wa_number?.split('-')[0]?.replace('+', ''),
                    wa_number: formData?.wa_number?.split('-')[1] ? formData?.wa_number?.split('-')[1] : "",
                    phone_code: formData?.phone_number?.split('-')[0]?.replace('+', ''),
                    phone_number: formData?.phone_number?.split('-')[1] ? formData?.phone_number?.split('-')[1] : "",
                    mobile_code: formData?.mobile_number?.split('-')[0]?.replace('+', ''),
                    mobile_number: formData?.mobile_number?.split('-')[1] ? formData?.mobile_number?.split('-')[1] : "",
                    trade_license: formData?.trade_license?.name ? formData?.trade_license : '',
                    agreement: formData?.agreement?.name ? formData?.agreement : '',
                    amount_collection: formData?.amount_collection,
                    amount_collection_description: formData?.amount_collection == 'cash' ? formData?.amount_collection_description : null,
                    booking_cancellation_terms: formData?.booking_cancellation_terms ? 1 : 0,
                    description: formData?.description ? formData?.description : "",
                    terms_and_conditions: formData?.terms_and_conditions ? formData?.terms_and_conditions : ""

                }
                const success = (data) => {
                    if (editData || editBusinessData?.id) {
                        if (successCallback) {
                            successCallback()
                        } else {
                            navigate(-1)
                        }
                    } else {
                        storeDataInLocalStorage('user_data', data)
                    }
                    Cookies.set('getUserProfileData', true)
                    setLoading(false)
                    setIsEdit && setIsEdit(false)
                    const element = document.querySelector(".edittopscroll");
                    if (element) {
                        document.querySelector(".edittopscroll").scrollIntoView();
                    }
                    removeDataFromSessionStorage("editBusinessData")
                }
                const fail = (error) => {
                    setLoading(false)
                    setErrors((prev) => ({
                        ...prev,
                        'email': error?.response?.data?.error?.email ? error?.response?.data?.error?.email[0] : false,
                        'mobile_number': error?.response?.data?.error?.mobile_number ? error?.response?.data?.error?.mobile_number[0] : false,
                        'phone_number': error?.response?.data?.error?.phone_number ? error?.response?.data?.error?.phone_number[0] : false
                    }));
                }
                if (!formData?.image_update || user?.restaurant?.images?.length == formData?.images?.length) {
                    delete payload.images
                    delete payload.image_update
                }
                setLoading(true)
                if (editData) {
                    payload = {
                        ...payload,
                        user_id: editData?.user?.id,
                    }
                    if (editBusinessData && editBusinessData?.id) {
                        payload = {
                            ...payload,
                            restaurant_id: editBusinessData?.id
                        }
                        dispatch(updateBusinessProfile(access_token, payload, success, fail))
                    } else {
                        dispatch(updateUnapprovedRestaurant(access_token, payload, success, fail))
                    }
                } else {
                    payload = {
                        ...payload,
                        restaurant_id: user?.restaurant?.id
                    }
                    // dispatch(updateUser(access_token, payload, success, fail))
                    dispatch(updateBusinessProfile(access_token, payload, success, fail))
                }
            }
        } else {
            const element = document.querySelector(".edittopscroll");
            if (element) {
                document.querySelector(".edittopscroll").scrollIntoView();
            }
        }
    };

    // request OTP for email update
    const requestOTP = (type, email, setStep) => {
        const sucess = () => {
            if (!email) {
                setStep && setStep(2)
            } else {
                setStep && setStep(4)
            }
            setOTPLoading(false)
        }
        const fail = () => { setOTPLoading(false) }

        if (type == 'new' && !email) {
            setErrors((prev) => ({ ...prev, 'newEmail': 'This field is required' }));
        }

        if (!errors?.newEmail) {
            setOTPLoading(true)
            dispatch(updateEmailRequest(access_token,
                {
                    type: type,
                    new_email: email ? email : '',
                    restaurant_id: user?.restaurant?.id
                },
                sucess, fail))
        }
    }

    // request OTP for email update
    const handleGenerateDescription = () => {
        if (!formData?.description) {
            const success = (value) => {
                setFormData((prevFormData) => ({ ...prevFormData, description: value }));
                setErrors((prev) => ({ ...prev, description: '' }));
            }
            const fail = () => { setOTPLoading(false) }
            dispatch(generateDescription(access_token, user?.restaurant?.id, success, fail))
        }
    }

    return {
        formData, navigate, errors, handleChange, loading, handleSubmit, updateEmail, setUpdateEmail, OTPLoading, setOTPLoading,
        requestOTP, emailValidation, cuisines, onImageRemove, user, otherOptions, OTHER_PAYMENT_METHODS, categories, addCuisine,
        setAddCuisine, getCuisineLoader, getCuisineData, handleGenerateDescription, setErrors, isProfileTabActive, roleValue,
        selectedRole, isSubCategoryRequired
    }
}

export default useEditProfile