import { updateOrAddBusinessCategory } from "Adapters/APIs/Admin/businessCategories";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

let initialData = {
    name: "",
    icon: "",
    description: "",
    is_sub_category_required: 1,
    status: 1
}
const useAdminCreateEditCategory = ({ editData, close, successCallBack }) => {
    const dispatch = useDispatch()

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState(initialData)

    // handle close popup
    const handleClose = () => {
        setErrors()
        setData(initialData)
        close && close()
    }


    // handle radio button click
    const handleClick = (value) => {
        setData((prev) => ({
            ...prev,
            is_sub_category_required: value
        }))
    }

    // handle onChange function
    const handleChange = (event) => {
        const { name, value } = event.target
        if (name == "icon") {
            setData((prev) => ({
                ...prev,
                [name]: event.target.files[0]
            }))
        } else {
            setData((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        setErrors((prev) => ({ ...prev, [name]: '' }));
    }


    // update or add staff data
    const updateOrAddStaffData = () => {
        let payload = {
            id: editData?.id,
            name: data?.name,
            icon: data?.icon,
            description: data?.description,
            is_sub_category_required: data?.is_sub_category_required,
            status: data?.status
        }
        const success = () => {
            setLoader(false)
            successCallBack && successCallBack()
            handleClose()
        }
        const fail = () => {
            setLoader(false)
        }
        let requiredData = {
            name: data?.name,
        }
        if (editData && (editData?.icon == data?.icon)) {
            delete payload.icon
        }
        setErrors(validation(requiredData));
        let result = validation(requiredData);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoader(true)
            dispatch(updateOrAddBusinessCategory(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        if (editData) {
            setData({
                name: editData?.name,
                icon: editData?.icon,
                description: editData?.description,
                is_sub_category_required: editData?.is_sub_category_required,
                status: editData?.status
            })
        }
    }, [editData])

    return {
        data, loader, updateOrAddStaffData, handleChange, setData, errors, handleClick, handleClose
    }
}

export default useAdminCreateEditCategory